<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">签名 APP 下载
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

<div class="center-container-cer">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      fixed
      prop="name"
      label="APP名字"
      align="center">
    </el-table-column>
     <el-table-column
     prop="version"
      label="APP 版本"
      align="center">
    </el-table-column>
    <el-table-column
     prop="state"
      label="APP 状态"
      align="center">
    </el-table-column>
    <el-table-column
     prop="download"
      label="下载地址"
      align="center">
       <template slot-scope="scope">
      <el-button @click="clickSelectSign(scope.row.signId,scope.row.name, scope.row.version)" type="success">下载 APP</el-button>
      </template>
    </el-table-column> 
  </el-table>
</div>
</div>
</template>

<style>

  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_down{
    top: 20px;
    position: relative;
  }
  
  .center-container-cer{
    background-color: white;
    width:70%;
    min-width: 340px;
    height:560px;
  }

</style>

<script>
import {getSelf} from '@/api/member'
import {getIpaSignList,getIpaDownload} from '@/api/download'
export default {
  data() {
      return {
        tableData: null,
        loading : true,
      }
    },

  methods: {
   async getMemberSelf() {
      var request = await getIpaSignList()
      this.loading = false;
      var newData = [];
      var requestData = request.data;

      for (var i=0; i<requestData.length; i++){
        
        console.log("requestData :" + JSON.stringify(requestData));


        for (var j=0; j<requestData[i].ipa.versions.length; j++){
          var ipaData = requestData[i].ipa;
          var versionsData = ipaData.versions[j];
          var newDic = {};
          newDic["name"] = ipaData.name;
          newDic["version"] = versionsData.version; 
          newDic["state"] = requestData[i].status;
          newDic["signId"] = requestData[i].id;
          newData.push(newDic);
        }
      } 
      
      this.tableData = newData;

    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },

   async clickSelectSign(signId, name, version){
      // var request = await getIpaDownload(signId);

      this.$router.push({
            path: `/installIpa?ipaName=` + name + "&ipaVersion=" + version,
      })
    
   },


  },

    
  mounted() {
    this.getMemberSelf();
    
  },

}
</script>
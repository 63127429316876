<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">支付记录
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

<div class="center-container-cer">

  <el-button class="buyButton" style="width:20%;" type="danger" @click="onDownloadHistroy">下载记录</el-button><br>
 记录只支持到 2022-07-20 之后数据
   <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
   <el-table-column
      prop="下标"
      type="index"
      width="50"
      align="right"
      >
    </el-table-column>
    <el-table-column
     prop="description"
      label="型号"
      align="center">
    </el-table-column>
    <el-table-column
     prop="createTime"
      label="创建时间"
      align="center">
    </el-table-column>
  </el-table>
</div>
</div>
</template>

<style>
  .container{
    flex-direction: row;
    padding: 5px;
  }
  .login-button{
    height: 75px;
    line-height: 75px;
  }
  .table_content_down{
    top: 20px;
    position: relative;
  }
  .center-container-cer{
    background-color: white;
    width:70%;
    min-width: 340px;
    height:560px;
  }

</style>

<script>
import {getRecord} from '@/api/member'
export default {
  data() {
      return {
        tableData: null,
        loading : true,
      }
    },
  methods: {
   async getMemberSelf() {
      var userData = await getRecord();
      var newData = [];

      console.log("zds ", userData);
      if (userData.data == null){
        this.loading = false;
        return;
      }
        var newData = new Array();
        for (var i=0; i<userData.data.length; i++){
          var data = userData.data[i];
          var newDic = {};
          newDic["description"] = data.description;
          newDic["createTime"] = this.formatDate(data.created_at);
          newData.push(newDic);
        }
      this.tableData = newData;
      this.loading = false;
    },

    formatDate(nS) {     
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
    },
    
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },

    onDownloadHistroy(){
        this.$router.push({
            path: `/downloadhistory`,
        }) 
    }
  },

    
  mounted() {
    this.getMemberSelf();
  },

}
</script>
<template>

<div class="background">
 <div class="flex algin">
    <div class="app-title">历史 - 下载购买记录
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>
<div class="center-containerDown_dumplist">
<div class="table_content_dumplist">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      label="序号"
      type="index"
      width="50">
    </el-table-column>
    <el-table-column
     prop="name"
      label="名称"
      align="center">
    </el-table-column>
     <el-table-column
     prop="version"
      label="版本"
      align="center">
    </el-table-column>
     <el-table-column
     prop="time"
      label="时间"
      align="center">
    </el-table-column>
      <el-table-column
      prop="download"
      label="下载"
      align="center">
      <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.ipaID,scope.row.version,scope.row.name)" type="success" icon="el-icon-s-custom" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</div>
</div>

</template>

<style>
  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_dumplist{
    position: relative;
    height: 450px;
  }
  
  .center-containerDown_dumplist{
    background-color: white;
    width:50%;
    min-width: 300px;
    padding-left: 50px;
  }

</style>

<script>

import {
  postEmail,
  postDumpOrder
} from '@/api/email'
import {downloadIPA} from '@/api/download'
import {checkCanDownload} from '@/api/download' 
import {download_history} from '@/api/download'
import {getSelf} from '@/api/member'

import {searchWithAppid} from '@/api/apple'
export default {
  data() {
      return {
        email:"",
        tableData: null,
        reportBtn:'none',
        searchName:'',
        newVersion:'',
        url:'',
        loading : true,
        arrayIPA : [],
        showNormal :true,
        showDoc: false,
        contryCode:'cn'
      }
    },

  methods: {
   async getMemberSelf() {
      var data = await getSelf()
    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },

    async invoicesLink(ipaID, version,name)  {
      var data = await checkCanDownload(ipaID, version);
      if (data){

        if (data["can_download"] == false){
          // 国内
          var appstoreData = await searchWithAppid(ipaID,'cn');
          this.contryCode ='cn';

          // 中国没有去美国
          if (!appstoreData.results.length){
             appstoreData = await searchWithAppid(ipaID,'us');
              this.contryCode ='us';
          }

          // 美国没有去香港
          if (!appstoreData.results.length){
             appstoreData = await searchWithAppid(ipaID,'hk');
              this.contryCode ='hk';
          }
          
          // 香港没有去日本
          if (!appstoreData.results.length){
              appstoreData = await searchWithAppid(ipaID,'jp');
              this.contryCode ='jp';
          }

          // 日本没有去韩国
          if (!appstoreData.results.length){
             appstoreData = await searchWithAppid(ipaID,'kr');
             this.contryCode ='kr';
          }

            var newVer = appstoreData.results[0].version;
            var isOld = false;
            if(newVer != version){
              isOld = true;
            }

            var data2 = {
              name: name,
              bundle_id:appstoreData.results[0].bundleId,
              version:version,
              app_store_link: "https://apps.apple.com/" + this.contryCode +"/app/" + encodeURIComponent(name) + "/id" + ipaID,
              ipa_id:ipaID,
              is_old:isOld
            }
            postDumpOrder(data2);
            alert('网站冷门 IPA 不会长期保留，已经为您尽快加急上传了，请耐心等待，如果1-3 小时没收到联系客服。');
        }else{
          var fileURL =  await downloadIPA(ipaID, version);
          if (fileURL.open_url){
            window.location.href = fileURL.open_url;
          }
        }
      }
    },
 postEmailFile(emails){
      var title = 'DumpApp - IPA 已经有最新版本，需要更新！'
      var data = {
        emails: emails,
        title: title,
        content: "应用名称: " + this.searchName + "<br>" + "应用版本: " + this.newVersion
      }
      postEmail(data);
    },
   async searchAllIpa(){
      var appList = await download_history(); 
      var filArrs = appList.data;
      var newData = new Array();
      for (var i=0; i<filArrs.length; i++){
       var fil = filArrs[i]; 
       if (fil == null){
         continue;
       }

        var ipaData = fil.ipa;

        if (ipaData == null){
          continue;
        }

        var newDic2 = {};
        newDic2["ipaID"] = ipaData.id;
        newDic2["name"] = ipaData.name;
        newDic2["version"] =filArrs[i].version;
        var tt = filArrs[i].update_at;
        newDic2["time"] =  this.formatDate(tt);
        newData.push(newDic2); 
      }
      this.tableData = newData;
      this.loading = false;
  },

     formatDate(timestamp) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
       var Y = date.getFullYear() + '.';
       var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth() + 1) : date.getMonth()+1) + '.';
       var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
       return Y + M + D;
    },
  
  },

  mounted() {
     this.searchAllIpa();
  },

}
</script>

<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">全站砸壳进度（所有用户）
    </div>
       <el-button id="app_back_btn" @click="onBack"  v-show="isShow"  class="app_back_btn" >返回首页</el-button>
  </div>
  <el-dialog
  title="选择删除原因"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <el-button @click="needCrack()" type="warning">需要破解</el-button>
  <el-button @click="notDump()" type="primary">自定义原因</el-button>
  <el-button @click="deleteIPA()" type="danger">强制删除</el-button>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
  </span>
</el-dialog>
<div class="center-containerDown_manager">
<div class="table_content_manager">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    
    <el-table-column
     prop="name"
      label="名称"
      align="center">
    </el-table-column>
    <el-table-column
     prop="version"
      label="版本号"
      align="center">
    </el-table-column>
    <el-table-column
     prop="createTime"
      label="申请时间"
      align="center">
    </el-table-column>
      <el-table-column
      prop="bundleid"
      label="处理状态"
      align="center">
     <template slot-scope="scope">
       <el-button @click="invoicesOrderLink(scope.row.order_id)" v-show="scope.row.isShow" type="danger">{{scope.row.state}}</el-button>
       <el-button @click="invoicesOrderLink(scope.row.order_id)" v-show="scope.row.isShow_success" type="success">{{scope.row.state}}</el-button>  
      </template>
    </el-table-column>
  </el-table>
</div>
</div>
</div>

</template>

<style>
  .table_content_manager{
    position: relative;
    height: 670px;
  }
  
  .center-containerDown_manager{
    background-color: white;
    margin-top: 20px;
    width:70%;
    min-width: 340px;
    height: 410px;
  }

  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }  
 
</style>

<script>
import {addCount} from '@/api/member'
import {deleteCount} from '@/api/member'
import {getUserDumpList} from '@/api/member'
import {deleteDumpList} from '@/api/member'
import {setFreeCount} from '@/api/member'
import {setOrderState} from '@/api/member'
import {
  postEmail,
} from '@/api/email'
export default {
  data() {
      return {
        email :'',
        userEmail : '',
        tableData: null,
        reportBtn:'none',
        searchName:'',
        serarchVersion:'',
        searchAppId :'',
        url:'',
        downloadCount:0,
        loading : true,
        dialogVisible: false,
        isShow:true, 
        intputValue:"无法砸壳"
      }
    },

  methods: {
    deleteCountMethod(){
       this.$prompt('请输入减少 D币的个数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          var data = {
            email: this.email,
            number: parseInt(value),
          }
          deleteCount(data);
        }).catch(() => {
        });
    },


    freeCount(){
     this.$prompt('请设置每日免费名额', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          var data = {
            daily_free_count : parseInt(value)
          }

          setFreeCount(data); 
        }).catch(() => {

        });
    },


    addCountMethod(){
     this.$prompt('请输入增加 D币的个数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
            var data = {
              email: this.email,
              number:  parseInt(value),
            }
            addCount(data);
        }).catch(() => {

        });
    },

    cleanDumpApp(){
       this.$router.push({
          path: `/dumplist`,
        })
    },
   
      addAccount(){
        this.$router.push({
          path: `/addaccount`,
        })
      },
    
      uploadFile(){
        this.$router.push({
          path: `/admin_upload`,
        }) 
      },

     invoicesLink(ipaID, version, name, userEmail) {
       this.searchAppId = ipaID;
       this.serarchVersion = version;
       this.searchName = name;
       this.userEmail = userEmail;
       this.dialogVisible = true;
      },

      invoicesOrderLink(order_id){
        alert("已加急，一般 1-3 小时会出来，如需确认详细状态，可联系微信客服。");
      },

      handleClose(){

      },

      needCrack(){
          this.urlClick(this.searchAppId, this.serarchVersion);
          this.dialogVisible = false;
          this.sendEmail(this.searchName, this.serarchVersion, "需要破解","此 IPA 无法出包有越狱检测，需要破解出包,请访问 www.dumpapp.com 添加客服微信，联系客服进行咨询，或进行退次");
      },

      notDump(){
        this.$prompt('请输入具体原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.urlClick(this.searchAppId, this.serarchVersion);
          this.dialogVisible = false;
          this.sendEmail(this.searchName, this.serarchVersion, value,",了解更多原因请访问 www.dumpapp.com 添加客服微信，联系客服。");
        }).catch(() => {
         
        });
      },

      deleteIPA(){
          this.urlClick(this.searchAppId, this.serarchVersion);
          this.dialogVisible = false;
      },

      invoicesStoreLink(storeUrl){
        window.open(storeUrl);
      },
      
      async urlClick(ipaID, version){
        await deleteDumpList(ipaID, version);
        this.dumplistData();
      },
    
    async sendEmail(appName, appVersion, firstTitle, contentTitle){
      var title = 'DumpApp - IPA ' + appName + " - " + firstTitle 
      var data = {
        emails: [this.userEmail],
        title: title,
        content:  "感谢您使用 DumpAPP " + "<br>" + "应用名称: " + appName + " 版本 ：" + appVersion + "<br>" + contentTitle 
      }
      postEmail(data);
    },
 
    async dumplistData() {
      var searchData = await getUserDumpList(); 
      var filArrs =  searchData.data;
      var newData = new Array();
      for (var i=0; i<filArrs.length; i++){
          var ipaData = filArrs[i];

          var newDic = {};
          newDic["name"] =  ipaData.ipa_name;
          newDic["version"] = ipaData.ipa_version;
          newDic["bundleid"] = ipaData.ipa_bundle_id;
          newDic["ipa_id"] = ipaData.ipa_id;
          newDic["appstoreUrl"] = ipaData.ipa_app_store_link;
          newDic["order_id"] = ipaData.id; 
          newDic["createTime"] = this.formatDate(ipaData.updated_at);

 

          if (ipaData.status == "unprocessed"){
            newDic["state"] = "排队等待中";
            
            newDic["isShow"] = true; 
            newDic["isShow_success"] = false;
          }else if (ipaData.status == "progressing"){
            newDic["state"] = "处理中"; 
            newDic["isShow"] = false;
            newDic["isShow_success"] = true;
          }else{
            newDic["state"] = "未知";
            newDic["isShow"] = true;
            newDic["isShow_success"] = false;
          }

 
          

          newData.push(newDic); 
      }

      this.tableData = newData;
      this.loading = false;
    },

  formatDate(nS) {     
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
    },
    
     open() {
        const h = this.$createElement;
        this.$msgbox({
          title: '消息',
          message: h('p', null, [
            h('span', null, '内容可以是 '),
            h('i', { style: 'color: teal' }, 'VNode')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 3000);
            } else {
              done();
            }
          }
        }).then(action => {
          this.$message({
            type: 'info',
            message: 'action: ' + action
          });
        });
      },

    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
  },

  mounted() {
    var os = this.getQueryString('os');
    if (os == 1){
      this.isShow = false;
    }

    this.dumplistData();
  },
  
}
</script>
<template>

    <div class="background">

    <el-button size="medium" type="primary" @click="PublishAppVersion()">发布新版本</el-button>

    <!-- 配置表单 -->
        <el-dialog title="配置信息" :visible.sync="isVisiblePublishDialog" width="900px">
        <el-form :model="publishAppVersionData">
            <el-form-item label="版本号">
                <el-input v-model="publishAppVersionData.version" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="标题">
                <el-input v-model="publishAppVersionData.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="描述">
                <el-input v-model="publishAppVersionData.describe" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="ipa 地址">
                <el-input v-model="publishAppVersionData.update_ipa_url" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否强制更新">
                <el-select v-model="publishAppVersionData.is_force_update" placeholder="强制更新">
                    <el-option v-for="(item, index) in isForceUpdateData" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
            <div slot="footer">
                <el-button @click="ClickPublishDialogButton(false)">取 消</el-button>
                <el-button type="primary" @click="ClickPublishDialogButton(true)">确 定</el-button>
            </div>
        </el-dialog>

    <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column prop="id" label="id"/>
            <el-table-column prop="version" label="版本"/>
            <el-table-column prop="title" label="标题"/>
            <el-table-column prop="describe" label="描述"/>
            <el-table-column prop="is_force_update" label="是否强制更新"/>
            <el-table-column prop="created_at" label="发布时间"/>
            <el-table-column prop="" label="安装页面" align="center" >
                <template #default="scope">
                    <el-button size="medium" @click="GoToInstallAppPage(scope.$index)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="" label="ipa 包地址" align="center" >
                <template #default="scope">
                    <el-button size="medium" @click="ViewIpaURL(scope.$index)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="" label="删除" align="center" >
                <template #default="scope">
                    <el-button size="medium" type="danger" @click="DeleteAppVersion(scope.$index)">删除</el-button>
                </template>
            </el-table-column>

        </el-table>

    </div>
</template>

<script>

import { Message } from 'element-ui'
import {GetAppVersionList, DeleteAppVersionApi, PostAppVersionApi} from '@/api/admin/app_version'
import { TimestampToTime } from '@/utils/time.js'

export default {
    name: 'AdminAppVersion',
    components: {
    },
    data () {
        const isForceUpdateData = [
            {
                "value": "1",
                "label": "是"
            },
            {
                "value": "2",
                "label": "否"
            },
        ]

        return {
            tableData: [],

            isVisiblePublishDialog: false, // 用来控制输入框是否展示
            publishAppVersionData: {
                "version": '',
                "title": "",
                "describe": "",
                "update_ipa_url": "",
                "is_force_update": "2",
            },
            isForceUpdateData: isForceUpdateData,
        }
    },

    mounted () {
        this.onLoad()
    },

    methods: {
        // 获取列表数据
        async onLoad() {
            const resp = await GetAppVersionList(0, 100)
            if (resp.error != null) {
                Message({message: "获取列表失败: " + data.error.message, type: 'error', duration: 2 * 1000})
            }
            resp.data.map((item)=>{
                item.created_at = TimestampToTime(item.created_at)
                item.is_force_update = item.is_force_update === true ? '是' : '否'
                return item
            });

            this.tableData = resp.data
        },

        // 打开打不表单面板
        async PublishAppVersion() {
            this.isVisiblePublishDialog = true
        },

        // 表单面板点击取消或确定
        async ClickPublishDialogButton(isOk) {
            this.isVisiblePublishDialog = false
            if (isOk === false) {
                return
            }

            const resp = await PostAppVersionApi(this.publishAppVersionData)
            if (resp.error != null) {
                Message({message: "发布失败: " + data.error.message, type: 'error', duration: 2 * 1000})
                return
            }

            await this.onLoad()
        },

        // 跳转到安装页面
        async GoToInstallAppPage(index) {
            window.open(this.tableData[index].download_url);
        },

        // 查看 ipa 下载地址
        async ViewIpaURL(index) {
            this.$confirm(this.tableData[index].update_ipa_url)
                .then(_ => {
                })
                .catch(_ => {});
        },

        // 删除 app 版本
        async DeleteAppVersion(index) {
            this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let resp = await DeleteAppVersionApi(this.tableData[index].id)
                console.log(resp)
                if (resp.error != null) {
                    Message({message: "删除失败: " + data.error.message, type: 'error', duration: 2 * 1000})
                    return
                }
                await this.onLoad()
            }).catch(() => {
            });
        }
    }
}
</script>

<style scoped lang="scss">

.background {
    position: relative;
    overflow-y: auto;
    display: block;
}

</style>

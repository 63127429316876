<template>
  <div class="container">
    <h3 class="title">活动公告</h3>
    <h5 class="description">
      好礼送不停，DumpApp为回馈新老客户，特推出"证书达标"奖励活动，凡在活动期间，同一账号在DumpApp平台购买证书数达标者均有D币奖励，具体活动规则如下：
    </h5>
    <ul class="rules">
      <li>每天24点统计前一天证书（限经济版、普通版、稳定版、豪华版）总数。</li>
      <li>
        <p class="rule_title">达标奖：</p>
        <p>
          达标一：10≤ 证书总数 ＜20，奖励<span class="highlight"> 20 </span>币
        </p>
        <p>
          达标二：证书总数 ≥ 20，奖励<span class="highlight"> 50 </span>币
        </p>
      </li>
      <li>
        <p class="rule_title">特别奖励：</p>
        <p>
          如果当天购买证书总数超过90本后，以后的每一本证书返<span
            class="highlight"
          >
            6 </span
          >币，且无上限，买的越多送的越多。
        </p>
      </li>
    </ul>
    <p class="note">PS：</p>
    <ul class="rules">
      <li>1、特别奖励和达标奖励可以同时获取，但达标奖励只能获取一种。</li>
      <li>2、一定是同一账号，且在活动期间当天总数达标。</li>
    </ul>
    <div class="disclaimer">此活动最终解释权归DumpApp官方所有</div>
  </div>
</template>

<script>
export default {
  name: "ActivityPage",
};
</script>

<style scoped>
.container {
  margin:20px;
  padding: 10px;
  font-family: Arial, sans-serif;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 18px;
  padding: 0px;
  font-weight: bold;
  text-align: center;
  margin: 0px;
  /* margin-top: 0px; */
}

.description {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  /* background-color: red; */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 400px;
}

.rules {
  /* padding-left: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px; */
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  list-style-type: disc;
  margin-left: 0px;
  text-align: left;
  font-size: 13px;
}

.note {
  font-style: italic;
  text-align: left;
  font-weight: 700;
  padding: 0px;
  margin-left: 10px;
}

.rule_title {
  text-align: left;
  font-size: 15;
  font-weight: bold;
  /* margin-top: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.highlight {
  color: red;
  font-weight: bold;
}

.disclaimer {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
  font-size: small;
  text-align: center;
  color: gray;
  text-align: right;
}
</style>

/*
 * @Author: your name
 * @Date: 2021-12-05 16:21:06
 * @LastEditTime: 2021-12-23 12:04:20
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/utils/util.js
 */

import {Message} from 'element-ui'

export function validEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function saveUserInfo(data) {
    sessionStorage.setItem("userInfo", JSON.stringify(data));
}

export function getUserInfo() {
    return JSON.parse(sessionStorage.getItem("userInfo"));
}

export function showMessage(msg){
    Message({
        message: msg,
        type: 'info',
        duration: 1 * 1000,
        offset:document.body.clientHeight * 0.5 - 100
    })
}
<template>

<div class="background">

  <div class="flex algin">
    <div class="app-title">Dump账号登录
    </div>
       <!-- <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button> -->
  </div>

<div class="center-container-login">
<el-form>
  <div class="container">
      <el-form-item label="">
      <el-input placeholder="请输入邮箱或手机号" v-model="email"></el-input>
      </el-form-item>
      <el-form-item label="">
      <el-input placeholder="请输入密码" show-password v-model="password"></el-input>
      </el-form-item>
      <div class="login-button">
        <el-button size="medium" @click="onRegister()">注册账号</el-button>
        <el-button type="danger" size="medium" @click="onLogin()">立即登录</el-button>

        <el-button type="warning" size="medium" @click="onGuestLogin()">游客登录</el-button>
      </div>
      <a href="find" >忘记密码</a>
  </div>
</el-form>
</div>
</div>

</template>

<style>

  .center-container-login{
    background-color: white;
    width:60%;
    min-width: 340px;
    height:300px;
  }

  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import {postLogin} from '@/api/login'

import {saveUserInfo,getUserInfo} from '@/utils/util'

import {guestLogin} from '@/api/login'

import {validEmail} from '@/utils/util'

import {Message} from 'element-ui'

export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    async loginWithData(email,password, isGuest){
      this.email = email;
      this.password =  password;
      if (isGuest){
        // this.setCookie("email", email, 365);
        // this.setCookie("password", password, 365);
      }
      this.onLogin();
    },

    async onGuestLogin(){
      var data = await guestLogin();
      if (data !=null && data.id != undefined){
        this.loginWithData(data.email, data.password, false);
        var accountText = "账号:" + data.email + " 密码:" + data.password;
        this.$copyText(accountText).then(
        (res) => {
          alert("已生成游客账号，并复制到剪切板 \n 账号:" + data.email + "\n 密码:" + data.password + "\n请尽快保存防止遗忘")
          },
          (err) => {
          }
        );
      }
    },
    

    clickqq () {
      alert('请加 qq 群: 763789550')
    },

    async onRegister() {
        this.$router.push({
          path: `/register`,
        })
    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    async onLogin(){
      if (this.email === '') {
        Message({
          message: "请输入邮箱或手机号！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      var numReg = /^[0-9]*$/
      var numRe = new RegExp(numReg)

      var isPhoneNumber = numRe.test(this.email);
      var isEmail = validEmail(this.email);
      if (!isPhoneNumber && !isEmail){
          Message({
                message: "请输入邮箱或手机号！",
                type: 'error',
                duration: 2 * 1000
          })
        return;
      }

      if (this.password === '') {
        Message({
          message: "请输入密码",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      var data1 = {
        account: this.email,
        password: this.password,
      }
      var res = await postLogin(data1)

      try {
        if (res.id != undefined) {
          this.$router.push({
            path: `/`,
          })
        }
      } catch (e) {
        // 错误处理代码片段
      }
    },
  },
  mounted() {
  },
  watch: {
  },
  name: 'Login'
}
</script>

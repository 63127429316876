import request from '@/utils/request'

/// 上传信息
export function postEmail (data) {
    return request({
      url: '/email',
      method: 'post',
      data
    })
}

/// 上传信息
export function postApp (data) {
  return request({
    url: '/admin/ipa',
    method: 'post',
    data
  })
}

/// 获取所有 APP 库中列表
export function getAllAppList () {
  return request({
    url: 'admin/ipa?limit=400',
    method: 'get'
  })
}

// 上传订单
export function postDumpOrder(data) {
  return request({
    url: '/ipa/dump_order',
    method: 'post',
    data
  })
}
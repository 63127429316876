<template>

<div class="background">
  <el-dialog
  title="恭喜注册成功"
  :visible.sync="showKefu"
  width="350px"
  :before-close="handleClose"
  center>
<img class="kefuwx" src="../assets/wx.jpg" />
<span> <br>QQ群：763789550<br>Telegram: https://t.me/+VGGU8RYVqDo1NTg1</span>
</el-dialog>
  <div class="flex algin">
    <div class="app-title">密码找回页面
    </div>
  </div>
<div class="center-container3">
<el-form>
  <div class="container">
       <el-form-item label="">
        <div class="captcha2">
            <el-input placeholder="请输入邮箱 (用于重置密码)" v-model="email"></el-input>
            <el-button  @click="getCode">{{ code }}</el-button>
        </div>
      <el-form-item label="">
      </el-form-item>
      <el-form-item label="">
          <el-input placeholder="请输入邮箱验证码" v-model="captcha"></el-input>
      </el-form-item>
      <el-form-item label="">
      <el-input placeholder="请输入新的密码" show-password v-model="password"></el-input>
      </el-form-item>
        </el-form-item>
      <div class="register-button">
        <el-button size="medium" @click="onRegister()">返回登录</el-button>
        <el-button size="medium" type="danger" @click="registerAccount()">提交信息
        </el-button>
      </div>
  </div>
</el-form>
</div>
</div>

</template>

<style>

  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .captcha {
    display:flex;
  }

  .captcha2 {
    display:flex;
  }


  .register-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import {postCaptcha,postPhoneCaptcha, postRegister,findEmailCode, findPassword} from '@/api/login'
import {validEmail} from '@/utils/util'
import {Message} from 'element-ui'

export default {
  data() {
    return {
      invite_code:'',
      email: '',
      phoneNumber:'',
      password: '',
      confirmPassword: '',
      captcha: '',
      captcha2:'',
      code: '获取 - 邮箱验证码',
      timer:null,
      timer2:null,
      showKefu:false,
    }
  },

  methods: {
    isEmailContain(email, email_input){
      let val = email.indexOf(email_input)==-1?false:true
      return val;
    },

    async getCode(){
      if (this.timer != null) {
          return
      }

      var email = this.email
      if (email === '') {
        Message({
          message: "请输入邮箱！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      if (validEmail(email) == false) {
          Message({
            message: "请输入有效的邮箱",
            type: 'error',
            duration: 2 * 1000
            })
            return
      }
      
      if (this.isEmailContain(email,"qq.com")|| this.isEmailContain(email,"163.com") || this.isEmailContain(email,"vip.qq.com") || this.isEmailContain(email,"126.com") || this.isEmailContain(email,"gmail.com") || this.isEmailContain(email,"icloud.com") || this.isEmailContain(email,"foxmail.com") || this.isEmailContain(email,"sina.com") || this.isEmailContain(email,"me.com") || this.isEmailContain(email,"aliyun.com")){
      
      
        findEmailCode(email)
       
        Message({
          message: "国外邮箱，或者冷门邮箱，可能会收不到验证码，可尝试更换注册邮箱，或联系客服",
          type: 'warning',
          duration: 4 * 1000
        })

        const TIME_COUNT = 180;
        this.code = TIME_COUNT;

        this.timer = setInterval(() => {
          if (this.code > 1 && this.code <= TIME_COUNT) {
            this.code--;
          } else {
            this.code= "获取 - 邮箱验证码"
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)

      }else{
         Message({
          message: "请输入符合要求的邮箱！",
          type: 'error',
          duration: 2 * 1000
        }) 
      }
    },

    async getCode2(){
      if (this.timer2 != null) {
          return
      }

      var number = this.phoneNumber
      if (number === '') {
        Message({
          message: "请输入手机号！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

        var data = {
          phone: number,
        }

       var data = await postPhoneCaptcha(data)

        if (data.error != null){
          return;
        }

        const TIME_COUNT = 180;
        this.code2 = TIME_COUNT;
        this.timer2 = setInterval(() => {
          if (this.code2 > 1 && this.code2 <= TIME_COUNT) {
            this.code2--;
          } else {
            this.code2= "获取 - 手机验证码"
            clearInterval(this.timer2);
            this.timer2 = null;
          }
        }, 1000)
    },

    async onLogin() {
        this.$router.push({
          path: `/login`,
        })
    },

   
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    handleClose(done) {
      this.$router.push({
          path: `/login`,
      })
    },

    async onRegister(){
      this.$router.push({
          path: `/login`,
      })
    },

    async registerAccount(){
          var res = await findPassword(this.email, this.password, this.captcha)
          try {
            if (res != undefined){
              alert("修改密码成功");
              this.onRegister();
            }
          } catch (e) {
            // 错误处理代码片段
          }
    },
       getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },
  },
  mounted() {
      this.invite_code = this.getQueryString('invite_code');

  },
  watch: {
  },
  name: 'Register'
}
</script>

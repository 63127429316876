import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
//cookie
import cookie from '@/utils/cookie';
import VueCookies from "vue-cookies";


import {Cell,CellGroup,RadioGroup, Dialog, Radio,Overlay,Field,Popup,Form} from 'vant';
import 'vant/lib/index.css';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(Overlay);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Form);


// Vue.use(vant.Card);
Vue.use(VueCookies);
Vue.prototype.$cookieStore = cookie;
Vue.use(ElementUI, {size: 'small', zIndex: 3000});
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


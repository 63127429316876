import request from '@/utils/request'

/// 登录
export function searchIpa(data) {
    return request({
      url: '/ipa/' + data.ipa_id + "?name="+ data.name + "&version=" + data.version + "&bundle_id=" + data.bundle_id + "&appstorelink=" + data.appstorelink,
      method: 'get'
    })
}

/// 登录
export function downloadIPA(appid,version) {
  return request({
    url: '/v3/ipa/' + appid  + '/download_url' + '?version=' + version,
    method: 'get'
  })
}

export function downloadCrackIPA(appid,version) {
  return request({
    url: '/v3/ipa/' + appid  + '/download_url' + '?version=' + version + '&ipa_type=crack',
    method: 'get'
  })
}

/// 登录
export function checkCanDownload(appid,version) {
  return request({
    url: '/v3/ipa/' + appid  + '/check_can_download' + '?version=' + version,
    method: 'get'
  })
}

export function checkCrackCanDownload(appid,version) {
  return request({
    url: '/v3/ipa/' + appid  + '/check_can_download' + '?version=' + version + '&ipa_type=crack',
    method: 'get'
  })
}

/// 排行榜
export function searchRank(time) {
  return request({
    url: '/ipa/ranking?start_at=' + time,
    method: 'get'
  })
}

/// 证书二维码
export function getQrCode() {
  return request({
    url: '/device/config/qr_code',
    method: 'get'
  })
}


  /// 生成证书
  export function certificate (data) {
    return request({
      url: '/certificate',
      method: 'post',
      data
    })
  }

  /// 检查证书
  export function checkP12 (cer_id) {
    return request({
      url: '/certificate/'+ cer_id  + '/reload',
      method: 'post',
    })
  }

  export function setP12Note (cer_id, note) {
    var data = {
      note: note,
    }
    return request({
      url: '/certificate/' + cer_id,
      method: 'put',
      data:data
    })
  }


  /// 生成证书 p12
  export function downloadP12 (cer_id, device_id) {
    return request({
      url: '/certificate/p12?device_id=' + device_id + "&cer_id=" + cer_id,
      responseType:'blob',
      method: 'get',
    })
  }

  /// 生成证书 mobile
  export function downloadMobile (cer_id, device_id) {
    return request({
      url: '/certificate/mobileprovision?device_id=' + device_id + "&cer_id=" + cer_id,
      responseType:'blob',
      method: 'get',
    })
  }
  
/// 登录
export function searchVersionWithAppid(country, appid) {
  return request({
    url: '/ipa/'+ country + '/' + appid,
    method: 'get'
  })
}


export function ipaSign(cer_id, ipa_ver_id) {
  var data = {
    certificate_id: cer_id,
    ipa_version_id: ipa_ver_id
  }

  return request({
    url: '/ipa/sign',
    method: 'post',
    data
  })
}

export function getIpaSignList() {
  return request({
    url: '/ipa/sign',
    method: 'get'
  })
}

export function getIpaDownload(ipa_sign_id) {
  return request({
    url: '/ipa/sign/' + ipa_sign_id + '/url',
    method: 'get'
  })
}

// 用户下载记录
export function download_history() { 
  return request({
    url: 'member/self/download_record?limit=100',
    method: 'get'
  }) 
}
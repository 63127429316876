<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">待砸壳列表
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>
  <el-dialog
  title="选择删除原因"
  :visible.sync="dialogVisible"
  width="50%"
  :before-close="handleClose">

  <el-input v-model="intputValue" style="margin-bottom: 10px;"></el-input>
  <el-button @click="needCrack()" type="warning">需要破解</el-button>
  <el-button @click="notDump()" type="primary">无法砸壳</el-button>
  <el-button @click="deleteIPA()" type="danger">强制删除</el-button>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
  </span>
</el-dialog>

<el-dialog
  title="提示"
  :visible.sync="dialognew2"
  width="50%"
  center>
  <el-input v-model="inputdbi" placeholder="请输入D币个数"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialognew2 = false">取 消</el-button>
    <el-button type="primary" @click="addDbi">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="提示"
  :visible.sync="dialognew1"
  width="50%"
  center>
  <el-input v-model="inputmail" placeholder="请输入用户邮箱"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialognew1 = false">取 消</el-button>
    <el-button type="primary" @click="dialognew1 = false">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="提示"
  :visible.sync="dialogcreate1"
  width="50%"
  center>
  <el-input v-model="inputmail" placeholder="请输入用户邮箱"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogcreate1 = false">取 消</el-button>
    <el-button type="primary" @click="createUserCode1()">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="提示"
  :visible.sync="dialogcreate2"
  width="50%"
  center>
  <el-input v-model="inputmail" placeholder="请输入用户邮箱"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogcreate2 = false">取 消</el-button>
    <el-button type="primary" @click="createUserCode2()">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="提示"
  :visible.sync="dialognew4"
  width="50%"
  center>
  <el-input v-model="inputudid" placeholder="请输入UDID"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialognew4 = false">取 消</el-button>
    <el-button type="primary" @click="addCerContent">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="提示"
  :visible.sync="dialognew3"
  width="50%"
  center>
  <el-input v-model="inputmail" placeholder="请输入用户邮箱"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialognew3 = false">取 消</el-button>
    <el-button type="primary" @click="dialognew3 = false">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="提示"
  :visible.sync="dialogCookie"
  width="50%"
  center>
  <el-input v-model="inputCookie" placeholder="请输入Cookie"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogCookie = false">取 消</el-button>
    <el-button type="primary" @click="setAppleCookie">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="D币控制"
  :visible.sync="dialogVisible2"
  width="50%"
  :before-close="handleClose">
  <el-button @click="addCountMethod()" type="warning">增加D币</el-button>
  <el-button @click="deleteCountMethod()" type="primary">减少D币</el-button>
  <el-button @click="dialogcreate1 = true" type="primary">兑换码(普通版)</el-button>

  <el-button @click="dialogcreate2 = true" type="primary">兑换码(稳定版)</el-button>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2 = false">取 消</el-button>
  </span>
</el-dialog>

<el-dialog
  title="综合管理"
  :visible.sync="dialogVisible3"
  width="50%"
  :before-close="handleClose">
  <el-button type="primary" @click="addAccount()">添加账号</el-button>
  <el-button type="primary"  style="margin-top:10px" @click="cleanDumpApp()">管理IPA</el-button>
  <el-button type="primary" @click="dialogVisible4 = true">授权管理</el-button>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible3 = false">取 消</el-button>
  </span>
</el-dialog>

<el-dialog
  title="综合管理"
  :visible.sync="dialogVisible4"
  width="50%"
  :before-close="handleClose">
  <el-button type="primary" @click="addAuth()">添加授权</el-button>
  <el-button type="primary" @click="addDelegate()">添加代理</el-button> 
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible4 = false">取 消</el-button>
  </span>
</el-dialog>

<div class="managerBtn"  style=" margin-bottom: 10px">

  <el-row>
  <el-input style="width: 90%; margin-bottom: 10px" v-model="dsc"></el-input><br>
  <el-button type="primary" @click="updateBulletin()">修改公告</el-button>
  <el-button type="primary" @click="dialogVisible2 = true">D币控制</el-button>
  <el-button type="primary" @click="dialogVisible3 = true">综合管理</el-button>
  <el-button type="primary" @click="clickAppVersion()">app 版本设置</el-button>
  <el-button @click="dialogCookie = true" type="warning">设置Cookie</el-button>

  <el-input  type="textarea" autosize placeholder="请输入UDID或者账号" style="width: 70%;top:10px; right:10px; margin-bottom: 10px" v-model="resultStr"></el-input>
  <el-button type="primary" @click="search()">{{title}}</el-button>
  </el-row>
</div>
<div class="center-containerDown_manager">
<div class="table_content_manager">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="530">
    
    <el-table-column
     prop="name"
      label="名称"
      align="center">
      <template slot-scope="scope">
          <a href="" @click="invoicesStoreLink(scope.row.appstoreUrl)"> {{scope.row.name}} 《{{scope.row.contry}}》</a>
      </template>
    </el-table-column>
    
    <el-table-column
     prop="version"
      label="版本号"
      align="center">

     <template slot-scope="scope">
      <div>
        {{ scope.row.version }} - 
      <!-- <div :style="{ color: 'red' }"> -->
        {{ scope.row.is_old }}
      <!-- </div>  -->
      </div>
          </template>
    </el-table-column>
      <el-table-column
      prop="bundleid"
      label="处理状态"
      align="center">
     <template slot-scope="scope">
       <el-button @click="invoicesOrderLink(scope.row.order_id)" v-show="scope.row.isShow" type="danger">{{scope.row.state}}</el-button>
       <el-button @click="invoicesOrderLink(scope.row.order_id)" v-show="scope.row.isShow_success" type="success">{{scope.row.state}}</el-button>  
       {{scope.row.operator}}
      </template>
    </el-table-column>
    <el-table-column
     prop="userEmail"
      label="用户邮箱"
      align="center">
 </el-table-column>
    <el-table-column
      prop="bundleid"
      label="删除"
      align="center">
     <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.ipa_id, scope.row.version,scope.row.name, scope.row.userEmail)" type="danger" icon="el-icon-delete" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</div>
</div>

</template>

<style>
.prompt{
  width: 50px;
}
  .table_content_manager{
    position: relative;
    height: 870px;
  }
  
  .center-containerDown_manager{
    background-color: white;
    margin-top: 20px;
    width:70%;
    min-width: 40px;
    height: 510px;
  }

  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }  
 
</style>

<script>

import {PostAppleCookie, PostAppleDevice} from '@/api/admin/app_version'
import { searchAccount } from '@/api/config'
import { searchCer } from '@/api/config'

import {createCDKey} from '@/api/member'
import {cerAdd} from '@/api/member'
import {setAuthDomain} from '@/api/member'
import {addCount} from '@/api/member'
import {deleteCount} from '@/api/member'
import {getDumpList} from '@/api/member'
import {deleteDumpList} from '@/api/member'
import {Message} from 'element-ui'
import {setFreeCount} from '@/api/member'
import {getAdminConfig} from '@/api/member'
import {setAdminConfig} from '@/api/member'
import {putSetAgent} from '@/api/login' 
import {setOrderState} from '@/api/member'
import {
  postEmail,
} from '@/api/email'
export default {
  data() {
      return {
        email :'',
        userEmail : '',
        inputCookie:'',
        tableData: null,
        reportBtn:'none',
        searchName:'',
        serarchVersion:'',
        searchAppId :'',
        url:'',
        downloadCount:0,
        loading : true,
        dialogVisible: false,
        dialogVisible2:false,
        dialogVisible3:false,
        dialogVisible4:false,
        dialognew3:false,
        dialognew4:false, 
        dsc:"",
        agentMail:"",
        inputmail:"",
        inputdbi:"9",
        dialognew1:false,
        dialognew2:false,
        dialogCookie:false,
        inputudid:"",
        dialogcreate1:false,
        dialogcreate2:false,
        cdkeycode:"兑换码生成框",
        resultStr:"",
        title:"搜索",
        intputValue:"越狱检测或系统不支持。"
      }
    },

  methods: {
    deleteCountMethod(){
      this.$prompt('请输入邮箱', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: '邮箱格式不正确'
        }).then(({ value }) => {
         this.email = value;
         this.$prompt('请输入减少 D币的个数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          var data = {
            email: this.email,
            number: parseInt(value),
          }
          deleteCount(data);
        }).catch(() => {
        });
        }).catch(() => {
          
        });
    },

    updateBulletin(){
      setAdminConfig(this.dsc);
    },

    clickAppVersion() {
        this.$router.push({
            path: `/admin/app_version`,
        })
    },


    clear(){
      this.resultStr = "";
    },
    async search() {
      if (this.title == "清空"){
        this.title = "搜索";
        this.clear();
        return;
      }
      this.resultStr = this.resultStr.replace(/\s/g,"")
      if (this.resultStr.indexOf("@") !== -1){
        var data = await searchAccount(this.resultStr);

        if (data !=null){
          var delegate = "否";

          if (data.role == "agent"){
            delegate = "是";
          }

          if (data.password.length){
            this.title = "清空";
          }
           
          this.resultStr ="注册: " + this.formatDate(data.created_at) +  "\n密码: " + data.password + "\nD币: " + data.pay_count +"个" + "\n代理: " + delegate +  "\ntoken: " + data.token + "\n";
        }
      }else{
        var deviceData =  await PostAppleDevice([this.resultStr]);
        alert(this.resultStr + "\n" + "设备型号：" + deviceData.data[0].devices[0].model);
        var response = await searchCer(this.resultStr);
        if (response !=null){
          var appendStr = "";
          // response.data.reverse();
          for (var i=0; i<response.data.length; i++){
            var data = response.data[i];

            // 创建时间
            var createTime = this.formatDate(data.created_at);
            
            // 备注
            var noteStr = data.note;
            if (noteStr == ""){
              noteStr = data.device.note;
            }

            // 是否有效
            var cerStatusStr = "检测中";
            if (data.p12_is_active){
              cerStatusStr = "有效";
            }else{
              cerStatusStr = "无效"; 
            }

            var levelStr =  "";
            console.log(data.level)
            switch (data.level) {
              case 1:
                levelStr = "普通版"
                break
              case 2:
                levelStr = "稳定版"
                break
              case 3:
                levelStr = "豪华版"
                break
              case 4:
                levelStr = "预约版"
                break
              case 5:
                levelStr = "经济版"
                break
              case 6:
                levelStr = "iPad 版"
                break
              case 999:
                levelStr = "独立版"
                break
            }

            appendStr = appendStr  + "购买时间: " + createTime + "\n" + "版本: " + levelStr + "   状态: " + cerStatusStr + "   备注: " + noteStr +  "\n" + "UDID: " + data.device.udid + "\n\n"
          }

          if (appendStr.length){
            this.title = "清空";
          }

          this.resultStr = appendStr;
        }
      }
    },

    formatDate(nS) {     
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
    },

    getCookie(name) {
	        let ret, m;
	        if (typeof name === 'string' && name !== '') {
	          if ((m = String(document.cookie).match(
	            new RegExp('(?:^| )' + name + '(?:(?:=([^;]*))|;|$)')))) {
	            ret = m[1] ? decodeURIComponent(m[1]) : ''
	          }
	        }
	        return ret
	    },

    freeCount(){
     this.$prompt('请设置每日免费名额', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          var data = {
            daily_free_count : parseInt(value)
          }

          setFreeCount(data); 
        }).catch(() => {

        });
    },


  async  createUserCode1(){
      var res = await createCDKey(1, this.inputmail);
      if (res != null){
        await this.alertCopy(res);
      }
    },

    async createUserCode2(){
      var res = await createCDKey(2, this.inputmail);
      if (res != null){
       await this.alertCopy(res);
      }
    },

    alertCopy(res){
      this.dialogVisible2 = false
      this.dialogcreate1 = false
      this.dialogcreate2 = false
      var resData = res.data;
      var cdkey = resData[0].out_id;

      this.resultStr = cdkey;

      this.$copyText(cdkey).then(
        (res) => {
          Message({
            message:"兑换码已生成，并复制到剪切板： " + cdkey,
                type: 'success',
                duration: 2 * 1000,
                offset:document.body.clientHeight * 0.5 - 100
              })
          },
          (err) => {
          }
        );
    },


    addDbi(){
      var data = {
        email: this.inputmail,
        number:  parseInt(this.inputdbi),
      }
      addCount(data);
      this.dialognew2 = false;
    },

    addCountMethod(){
      this.dialognew1 = true;
      this.dialognew2 = true;
    },

    cleanDumpApp(){
       this.$router.push({
          path: `/dumplist`,
        })
    },
   
      addAccount(){
        this.$router.push({
          path: `/addaccount`,
        })
      },
    
      uploadFile(){
        this.$router.push({
          path: `/admin_upload`,
        }) 
      },

     invoicesLink(ipaID, version, name, userEmail) {
       this.searchAppId = ipaID;
       this.serarchVersion = version;
       this.searchName = name;
       this.userEmail = userEmail;
       this.dialogVisible = true;
      },

      invoicesOrderLink(order_id){
        setOrderState(order_id, "progressing");
        setTimeout(function(){
         history.go(0);
        },1000);
      },

      handleClose(){
        this.dialogVisible = false;
        this.dialogVisible2 = false;
        this.dialogVisible3 = false;
      },
      
      needCrack(){
          this.urlClick(this.searchAppId, this.serarchVersion);
          this.dialogVisible = false;
          this.sendEmail(this.searchName, this.serarchVersion, "需要破解","此 IPA 无法出包有越狱检测，需要收费破解出包,请访问 www.dumpapp.com 添加客服微信，联系客服进行咨询，或进行退次");
      },

      addDelegate(){
        this.$prompt('请输入邮箱', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: '邮箱格式不正确'
        }).then(({ value }) => {
          putSetAgent(value);
        }).catch(() => {
           
        });
      },

       addAuth() {
        this.$prompt('请输入邮箱', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: '邮箱格式不正确'
        }).then(({ value }) => {
            this.email = value;
            this.$prompt('请输入授权域名', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async ({ value }) => {
          await setAuthDomain(this.email, value);
        }).catch(() => {
           
        });

        }).catch(() => {
           
        });
      },

      notDump(){
        this.dialogVisible = false;
        this.urlClick(this.searchAppId, this.serarchVersion);
        this.sendEmail(this.searchName, this.serarchVersion, "当前APP无法砸壳",this.intputValue + "请联系 https://www.dumpapp.com 客服（退币或更换其他APP，或咨询详情）");
      },
      cancelAllAdd(){
        this.dialognew1 = false;
        this.dialognew2 = false;
      },
      deleteIPA(){
          this.urlClick(this.searchAppId, this.serarchVersion);
          this.dialogVisible = false;
      },

      invoicesStoreLink(storeUrl){
        window.open(storeUrl);
      },
      
      async urlClick(ipaID, version){
        await deleteDumpList(ipaID, version);
        this.dumplistData();
      },
    
    async sendEmail(appName, appVersion, firstTitle, contentTitle){
      var title = 'DumpApp - IPA ' + appName + " - " + firstTitle 
      var data = {
        emails: [this.userEmail],
        title: title,
        content:  "感谢您使用 DumpAPP " + "<br>" + "应用名称: " + appName + " 版本 ：" + appVersion + "<br>" + contentTitle 
      }
      postEmail(data);
    },
 
    async dumplistData() {

      // 获取配置
      var adminConfig = await getAdminConfig();
      var dsc = adminConfig.announcement.description;
      this.dsc = dsc;

      // 读取订单
      var searchData = await getDumpList(); 
      var filArrs =  searchData.data;
      var newData = new Array();
      for (var i=0; i<filArrs.length; i++){
          var ipaData = filArrs[i];

          var newDic = {};
          newDic["name"] =  ipaData.ipa_name;
          newDic["version"] = ipaData.ipa_version;
          newDic["bundleid"] = ipaData.ipa_bundle_id;
          newDic["ipa_id"] = ipaData.ipa_id;
          newDic["appstoreUrl"] = ipaData.ipa_app_store_link;
          newDic["contry"] = ipaData.ipa_app_store_link.substr(23,2);
          newDic["is_old"] = ipaData.is_old ? "旧" : "新";
          newDic["order_id"] = ipaData.id; 

          var str = "";
          if (ipaData.operator_member != undefined){
            str = ipaData.operator_member.email;
          }
            
         if (ipaData.status == "unprocessed"){
            newDic["state"] = "排队等待处理";
            
            newDic["isShow"] = true; 
            newDic["isShow_success"] = false;
          }else if (ipaData.status == "progressing"){
            newDic["state"] = "处理中" 
            newDic["isShow"] = false;
            newDic["isShow_success"] = true;
            newDic["operator"] = str;
          }else{
            newDic["state"] = "未知";
            newDic["isShow"] = true;
            newDic["isShow_success"] = false;
          }

          if (ipaData.other_demander_member.length>=2){
            newDic["userEmail"] = ipaData.other_demander_member[ipaData.other_demander_member.length-1].email;
          }else{
            newDic["userEmail"] = ipaData.other_demander_member[0].email;
          }

          newData.push(newDic); 
      }

      this.tableData = newData;
      this.loading = false;
    },

     open() {
        const h = this.$createElement;
        this.$msgbox({
          title: '消息',
          message: h('p', null, [
            h('span', null, '内容可以是 '),
            h('i', { style: 'color: teal' }, 'VNode')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 3000);
            } else {
              done();
            }
          }
        }).then(action => {
          this.$message({
            type: 'info',
            message: 'action: ' + action
          });
        });
      },

    async addCer(){
      this.dialognew3 = true;
      this.dialognew4 = true;
    },

    async addCerContent(){
      await cerAdd(this.inputmail, this.inputudid);
      this.dialognew4 = false;
      this.inputmail = "";
      this.inputudid = ""
    },

    async setAppleCookie(){
      await PostAppleCookie(this.inputCookie);
      this.dialogCookie = false;
    },
    
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
  },

  mounted() {
    this.dumplistData();
  },
  
}
</script>
/*
 * @Author: your name
 * @Date: 2021-12-05 16:21:10
 * @LastEditTime: 2022-07-16 20:39:31
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/api/login.js
 */
import request from '@/utils/request'

export function guestLogin () {
  return request({
    url: '/guest/login',
    method: 'post'
  })
}

/// 登录
export function postLogin (data) {
    return request({
      url: '/login',
      method: 'post',
      data
    })
}


export function postBind (data) {
  return request({
    url: '/guest/bind',
    method: 'post',
    data
  })
}

/// 注册
export function postRegister (data) {
    return request({
      url: '/register',
      method: 'post',
      data
    })
}

/// setAgent
export function putSetAgent (email) {
  var data = {
    email:email,
    role: 'agent'
  }
  return request({
    url: '/admin/account',
    method: 'put',
    data
  })
}


export function postRegister2 (data) {
  return request({
    url: '/admin/account',
    method: 'post',
    data
  })
}


/// 发送验证码
export function postCaptcha (data) {
    return request({
      url: '/email/captcha',
      method: 'post',
      data
    })
}

/// 发送验证码
export function postPhoneCaptcha (data) {
  return request({
    url: '/phone/captcha_v2',
    method: 'post',
    data
  })
}

/// 退出登录
export function postLogout () {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 找回密码验证码
export function findEmailCode(email) { 
  var data = {
    email: email,
  }
  return request({
    url: '/reset/password/captcha',
    method: 'post',
    data
  })
}

export function findPassword(email, password,captcha) {
  var data = {
    email: email,
    password: password,
    captcha:captcha
  }
  return request({
    url: '/reset/password',
    method: 'post',
    data
  })
}





/*
 * @Author: your name
 * @Date: 2021-12-23 12:07:23
 * @LastEditTime: 2022-02-12 23:56:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/api/upload.js
 */
import COS from 'cos-js-sdk-v5'
import { fetchConfig } from '@/api/config'
import request from '@/utils/request'

export function upload(file, callback, processCallback, fileName) {
  fetchConfig().then(data => {


    const cos = new COS({
      getAuthorization: function (options, callback) {
        callback({
          TmpSecretId: data.Credentials.TmpSecretId,
          TmpSecretKey: data.Credentials.TmpSecretKey,
          XCosSecurityToken: data.Credentials.Token,
          ExpiredTime: data.ExpiredTime
        })
      }
    })

    return {
      cos: cos,
      bucket: data.bucket,
      region: data.region
    }

  }).then(data => {


    const cos = data.cos
    const bucket = 'image-1303977469' /// data.bucket
    const region = 'ap-beijing' /// data.region

    const key = fileName + ".ipa"
    cos.putObject({
      Bucket: bucket,
      Region: region,
      Key: key,
      Body: file,
      onProgress: function (progressData) {
        processCallback(progressData);
      },
    }, function (err, data) {
      callback(key, file)
    })
  })
}

export function getLingShuLianTempSecret() {
  return request({
    url: '/lingshulian/temp_secret',
    method: 'get',
  })
}


export function publishSignIpa(data) {
  return request({
    url: '/sign_ipa',
    method: 'post',
    data:data
  })
}

export function getPublishSignIpaList(offset) {
  return request({
    url: '/member/self/sign_ipa?limit=10&offset='+offset,
    method: 'get',
  })
}

export function deletePublishSignIpa(id) {
  return request({
    url: '/sign_ipa/' + id,
    method: 'delete',
  })
}


//   /**
//  * 选择文件上传
//  */
// $('#input-file').on('change', function (e) {
//   // 选择文件列表
//   let files = e.currentTarget.files
//   if (files.length === 0) {
//       return
//   }
//   if (files.length !== 1) {
//       message('选择文件过多')
//       return
//   }
//   // 判断文件大小
//   if (files[0].size >= 209715200) {
//       message('文件过大请重新选择')
//       return
//   }
//   // 上传文件
//   upload_file(files)
// })

// function upload_file(file) {
//   AWS.config.update({
//       credentials: {
//           accessKeyId: 'xxxxx',
//           secretAccessKey: 'xxxx',
//       },
//       endpoint: 'http://s3-us-east-1.ossfiles.com',
//       region: 'us-east-1',
//       apiVersions: {
//           s3: '2006-03-01',
//       },
//       signatureVersion: 'v4',
//   })
//   var s3 = new AWS.S3()
//   // 多线程上传文件
//   var params = {
//       Bucket: 'bucket',
//       Key: 'file_name',
//       Body: file,
//   }
//   var options = {
//       partSize: 6 * 1024 * 1024,
//       queueSize: 5,
//   }
//   s3.upload(params, options, function (err, data) {
//       console.log(err, data)
//   })
// }
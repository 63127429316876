<template>

<div class="background">

<div id="app-title" class="app-title">DumpApp - 上传管理后台</div>
    <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回管理</el-button>
<div class="test">

  <el-checkbox v-model="checked">是否发送邮件</el-checkbox>
<el-form>
  
<el-radio-group v-model="radio" @change="agreeChange">
  <el-radio :change="agreeChange" label="1">冷门 IPA</el-radio>
  <el-radio :change="agreeChange" label="2">热门 IPA</el-radio>
  <el-radio :change="agreeChange" label="3">旧版 IPA</el-radio>
  <el-radio :change="agreeChange" label="4">增强 IPA</el-radio>

</el-radio-group>
  <div class="container">
      <el-form-item v-if="showMail" label="邮箱地址">
      <el-input placeholder="请输入邮箱地址" v-model="emails"></el-input>
      </el-form-item>
      <el-form-item v-if="showDoc"  label="破解IPA教程">
      <el-input placeholder="请输入增强 IPA 教程" v-model="ipadoc"></el-input>
      </el-form-item>

      <el-upload
        class="upload-demo"
        action="https://localhost:8080/"
        :on-remove="handleRemove"
        :on-change="handleChangeStatus"
        multiple
        accept=".ipa"
        :file-list="fileList"
        :auto-upload="false">
        <el-button size="medium" type="primary">选择文件</el-button>
        </el-upload>
       <div class="upload-button">
        <el-button size="medium" @click="onSubmit()">开始上传文件</el-button>
       </div>
  </div>
</el-form>
</div>
</div>

</template>
<script data-ad-client="ca-pub-2251752301977907" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

<style>
  .background {
    width: 100%;
    height: 100%;
    background-color: #348097;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .app-title{
    height: 112px;
    line-height: 112px;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
  }

  .test {
    background-color: white;
    width:780px;
    height:370px;
  }

  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .upload-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import {
  postEmail,
  postApp
} from '@/api/email'

import { Message, Loading } from 'element-ui'

import {splitBundleId} from '@/utils/file'
import {splitVersion} from '@/utils/file'

import {splitIpaName} from '@/utils/file'

import {searchWithBundleID} from '@/api/apple'

import {
  upload
} from '@/api/upload'

export default {
  data () {
    return {
      fileList: [],
      files: [],
      radio:'1',
      emails:'',
      radio : '1',
      ipadoc :'',
      ipaId : '',
      showMail : false,
      showDoc: false,
      checked : true,
    }
  },
  
  methods: {

    agreeChange:function(val){

        // 发邮件
        if (val == '1'){
          this.showMail = false;
          this.showDoc = false;
          this.checked = true;
        }

        // 发邮件
        if (val == '2'){
          this.showMail = false;
          this.showDoc = false;

          this.checked = true;
        }

        // 不发邮件，显示邮箱
        if (val == '3'){
          this.showMail = true;

          this.showDoc = false;
          this.checked = false;
        }

        // 不发邮件，显示教程
        if (val == '4'){
          this.showDoc = true;

          this.showMail = false;
          this.checked = false;
        }
    },
    handleChangeStatus (file, fileList) {
        this.fileList = []
        this.fileList = fileList
    },

    handleRemove (file, fileList) {
        this.fileList = []
    },
    handlePreview (file) {
      // console.log(file);
    },
    handleExceed () {
      alert('最多只能上传一张图')
    },
    onBack(){
        this.$router.push({
          path: `/appmanager`,
        }) 
    },
    
    async getSearch(requestData) {
          // 拿到请求数据 json
          var data = await searchWithBundleID(requestData, 'cn'); 

          // 中国没有去美国
          if (!data.results.length){
             data = await searchWithBundleID(requestData,'us');
          }

          // 美国没有去香港
          if (!data.results.length){
             data = await searchWithBundleID(requestData,'hk');
          }
          
          // 香港没有去日本
          if (!data.results.length){
             data = await searchWithBundleID(requestData,'jp');
          }

          // 日本没有去韩国
          if (!data.results.length){
             data = await searchWithBundleID(requestData,'kr');
          }
      return data;
     },

    async onSubmit () {

      // 为空提示
      if (this.fileList.length == 0) {
        alert("请选择 ipa ");
        return
      }

      if ((this.radio == '3') && this.emails.length == 0){
         alert("旧版本 IPA，必须输入单个人邮箱");
        return
      }

       if ((this.radio == '4') && this.ipadoc.length == 0){
         alert("破解版本 IPA，必须输入教程地址");
        return
      }
      
      // 上传判断
      Message({
        message: "开始上传请不要动",
        type: 'success',
        duration: 2 * 1000
      })
      
      console.log("开始上传请不要动 ... ... ");

      // 变量初始化
      var apps = {}
      var resultData = {}
      var successIndex = 0
      var tThis = this
      var is_temporary = false;
      var uploadApps = []

      var ipa_type = "normal";
      
      if (this.radio == "1"){
        is_temporary = true;
      }

      if (this.radio == "4"){
        ipa_type = "crack";
      }

      var describe = ''; 
      // 遍历上传
      for (var i=0,len=tThis.fileList.length; i<len; i++) { 
          var file = tThis.fileList[i]

          // ipa 信息
          var ipaBundleId = splitBundleId(file.name);
          var ipaVersion = splitVersion(file.name);
          var response = await tThis.getSearch(ipaBundleId);
          if (!response){
            alert("获取 IPA 信息失败，请重新刷新上传");
            return;
          }

          var ipaName = response.results[0].trackName;
          if (this.radio == "4"){
             describe = splitIpaName(file.name);
          }

          var ipaAppid = response.results[0].trackId;
          var newid = ipaAppid.toString();
          this.ipaId = newid;

          var app = {
            bundle_id : ipaBundleId,
            ipa_id : newid,
            name : ipaName,
          }

           var versionToken = {
              version : ipaVersion,
              ipa_type: ipa_type,
              is_temporary: is_temporary,
              describe_url:this.ipadoc,
              describe: describe
           }

          app["versions"] = [versionToken]

          // 添加上传数据
          apps[ipaBundleId] = app;
      }

      tThis = this;

      // 遍历上传
      for (var i=0,len=tThis.fileList.length; i<len; i++) { 
          var file = tThis.fileList[i]

          console.log("遍历上传 ：" + splitIpaName(file.name));
          this.setTitle("上传中: " + splitIpaName(file.name));
          var fileName = splitIpaName(file.name) + "_" + splitVersion(file.name) +"_"+ this.getRndInteger(100, 999);

          // 上传文件
          upload(file.raw, (key, file) => {
              // 提示上传
              Message({
                message: "上传成功 ： " + ipaBundleId,
                type: 'success',
                duration: 1 * 1000
              })

              var ipaBundleId = splitBundleId(file.name);
              var ipaName = splitIpaName(file.name);
              var ipaVersion = splitVersion(file.name);
              apps[ipaBundleId]["versions"][0]["token"] = key;

              console.log("上传成功 : " +  apps[ipaBundleId]["name"])
              this.setTitle("上传成功 :" + apps[ipaBundleId]["name"] );
              uploadApps.push(apps[ipaBundleId]);
              successIndex += 1

              // 所有文件上传完成
              if (successIndex == tThis.fileList.length) {
                resultData["ipas"] = uploadApps
                resultData["is_send_email"] = this.checked
                tThis.successUpload(resultData);
                this.setTitle("上传完成所有! " );
                if (this.radio == '3'){
                  this.sendEmail(ipaName, ipaVersion, this.ipaId);
                }
              }
          }, (progressData) =>{
            const percent = Math.floor(progressData.percent * 10000) / 100;
            const speed = Math.floor(progressData.speed / 1024 / 1024 * 100) / 100;
            this.setTitle('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
          }, fileName)
      }
    },
     getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1) ) + min;
     },

    async setTitle(title){
      document.getElementById("app-title").innerHTML = title; 
    },

    async sendEmail(appName, appVersion, ipaAppid){
      var title = 'DumpApp - 旧版 IPA ' + appName + ' 已经发送，请查看'
      var data = {
        emails: [this.emails],
        title: title,
        content:  "感谢您使用 DumpAPP " + "<br>" + "应用名称: " + appName + " 版本 ：" + appVersion + " 已经发送，请访问 " + "https://www.dumpapp.com/download?appid="+ ipaAppid + "查看"
      }
      postEmail(data);
    },

    mounted() {
      this.showMail = false;
      this.showDoc = false;
      this.checked = true;
    },

    async successUpload(resultData){
       var data = await postApp(resultData)
       if (data){
          alert("上传成功 :");
       }else{
          alert("上传失败 :"); 
       }
    }
  },
  name: 'AdminUpload'
}
</script>

import request from '@/utils/request'

// 获取腾讯云配置信息
export function fetchConfig () {
    return request({
      url: '/cos',
      method: 'get'
    })
}

export function getConfig() {
  return request({
    url: '/config',
    method: 'get'
  })
}

export function getActivities() {
  return request({
    url: '/activities',
    method: 'get'
  })
}


export function collect(modelId) {
  var data = {
    id: modelId,
  }
  
  return request({
    url: '/activity/collect',
    method: 'post',
    data:data
  })
}




export function searchAccount(email) {
  return request({
    url: '/admin/account?account='+email,
    method: 'get'
  })
}

export function searchCer(udid) {
  return request({
    url: '/admin/certificate?udid=' + udid,
    method: 'get'
  })
}





<template>

<div class="background">
  <div class="flex algin">
    <div id="app-title" class="app-title">APP 下载页面
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
    </div>
<div class="center-containerDown_download">
    <el-button id="new_version" :style="{ display: reportBtn}" type="danger" size="medium" @click="newVersionClick">（点击此处获取最新版本）</el-button>
    <el-button id="new_version" :style="{ display: true}" type="success" size="medium" @click="historyVersionClick">获取历史版本</el-button>
<div class="table_content_download">
<el-table
    :data="tableData"
    style="width: 100%"
    max-height="400">
    <el-table-column
      fixed
      prop="name"
      label="APP名称"
      align="center">
    </el-table-column>
    <el-table-column
     prop="version"
      label="版本号"
      align="center">
    </el-table-column>
    <el-table-column
      align="center"
      prop="size"
       label="大小">
    </el-table-column>
    <el-table-column
      align="center"
      prop="url"
       label="下载">
      <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.version, scope.row.ipaID)" type="success" icon="el-icon-download" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>

</div>
</div>
</template>

<style>
  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_download{
    position: relative;
    height: 400px;
  }
  .center-containerDown_download{
    background-color: white;
    width:70%;
    min-width: 340px;
  }
</style>

<script>


import {checkCanDownload} from '@/api/download' 
import {searchIpa} from '@/api/download'
import {searchWithBundleID} from '@/api/apple'
import {searchWithAppid} from '@/api/apple'
import {getSelf} from '@/api/member'
import {downloadIPA} from '@/api/download'
import {
  postEmail,
  postApp,
  postDumpOrder
} from '@/api/email'

export default {
  data() {
      return {
        tableData: null,
        reportBtn:'none',
        searchName:'',
        newVersion:'',
        downloadCount:0,
        emailStr : "",
        contryCode:"",
        ipaVersions:[],
        ipaPrice:"9"
      }
    },

  methods: {
    clickqq () {
      alert('请加 qq 群: 763789550')
    },
      getfilesize(size) {
            if (!size)  return "";
            var num = 1024.00; //byte
            if (size < num)
                return size + "B";
            if (size < Math.pow(num, 2))
                return (size / num).toFixed(2) + "KB"; //kb
            if (size < Math.pow(num, 3))
                return (size / Math.pow(num, 2)).toFixed(2) + "MB"; //M
            if (size < Math.pow(num, 4))
                return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
            return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    },
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
     open() {
      const h = this.$createElement;
       var tThis = this;

        this.$msgbox({
          title: '提示',
          message: h('p', null, [
            h('i', { style: 'color: red' }, '获取旧 IPA 版本'), 
            h('span', null, '，属于 VIP '),
            h('span', null, '功能，升级 '),
            h('i', { style: 'color: red' }, 'VIP用户 '),
            h('span', null, '获取更多特权!')
          ]),
          showCancelButton: true,
          confirmButtonText: '了解 VIP 特权',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done();
              tThis.routerPay();
            } else {
              done();
            }
          }
        }).then(action => {
          this.$message({
            type: 'info',
            message: 'action: ' + action
          });
        });
      },

     invoicesLink(version, ipaID) {  
          version = version.replace(/\s+/g,"");
        if (version != this.newVersion){
          this.openNewMeg("当前检测到不是最新版本, 最新版是 ：" + this.newVersion + " 版本， 是否确定继续获取旧版本", version, ipaID);
        }else{
          this.urlClick(version, ipaID);
        }
      },

   async urlClick(version, ipaID){

      var data = await checkCanDownload(ipaID, version);
 
      if (data){
        var msg = data["message"];
        console.log("zds msg ", msg);
        if (msg != ''){
            var userData = await getSelf();
            this.ipaPrice = userData.ipa_info.price; 
            var downloadCount = userData.pay_count;
            if (downloadCount < 1){
              this.openPayMsg("当前拥有可D币个数为 0，请进行充值。"); 
            }else{
              this.openAlertMsg("",msg, version, ipaID);
            }
            return;
        }

        if (data["can_download"] == true){
            this.requestPay(version, ipaID);
        }

      }
    },

    async historyVersionClick(){
       this.$router.push({
              path: `/historypage?appid=` + this.getQueryString('appid') + "&contry=" + this.contryCode + "&name=" + this.searchName + "&versions=" + this.ipaVersions,
        })
    },
    
    async newVersionClick(){ 
      var userData = await getSelf();
      var downloadCount = userData.pay_count;
      if (downloadCount != 0){
        this.postEmailFile(["dumpapp@126.com"], this.emailStr);
        this.openMsg("最新版本 IPA 请求已发送，稍后网站更新后，会通过邮件通知您，（1-3小时还没有收到 IPA 包的，可添加网站微信联系客服咨询进度）(节假日服务器维护，ipa 出包比较慢)" );
      }else{
         this.openPayMsg("当前拥有可D币个数为 0，请进行充值。");
      }
    },
    
     postEmailFile(emails, address){

      var store_link =  "https://apps.apple.com/" + this.contryCode +"/app/" + encodeURIComponent(this.searchName) + "/id" + this.getQueryString('appid').toString();

      var title = 'DumpApp - IPA 已经有最新版本，需要更新！'
      var data = {
        emails: emails,
        title: title,
        content:  "国家: " + this.getCountryStr(this.contryCode)  + "<br>" + "应用名称: " + this.searchName + "<br>" + "应用版本: " + this.newVersion + "<br>" + " bundleID: " + this.bundle_id + "<br>" + "D币个数: " + this.downloadCount + "<br>" + "邮箱地址: " + address  + "<br>" + "App Store: " + store_link 
      }

      // postEmail(data);

      var data2 = {
        name: this.searchName,
        bundle_id:this.bundle_id,
        version:this.newVersion,
        app_store_link: store_link,
        ipa_id: this.getQueryString('appid')
      }
      postDumpOrder(data2);
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },

     openMsg(data) {
        var confirmStr = "确认";
         this.$confirm('<strong>' + '<font color="#FF0000">' + data + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          dangerouslyUseHTMLString : true
        }).then(() => {
        }).catch(() => {        
        });
      },

     openAlertMsg(data, vip_str,version, ipaID) {
        var confirmStr = "确认";
         this.$confirm(data  +  '<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
              this.requestPay(version, ipaID);
        }).catch(() => {        
        });
      },

      openPayMsg( vip_str) {
        var confirmStr = "确认";
          confirmStr =  '进行充值';
         this.$confirm( '<strong>'  + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
         this.routerPay(); 
        }).catch(() => {        
        });
      },

      openNewMeg(data, version, ipaID) {
        var confirmStr = "继续"; 
         this.$confirm('<strong>' + '<font color="#FF0000">' + data +  '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
          this.urlClick(version, ipaID); 
        }).catch(() => {
        });
      },

    async requestPay(version, ipaID){
          var fileURL =  await downloadIPA(ipaID, version);
          if (fileURL.open_url){
            window.location.href = fileURL.open_url;
          }else{
             this.openPayMsg("当前拥有可D币个数不足 " + this.ipaPrice + " 个，请进行充值。");
          }
    },

    // 搜索 apup
   async requestSearchApp (appid) {

          var userData = await getSelf();
          if (userData != null){
              this.emailStr = userData.email;
              this.downloadCount = userData.pay_count;
          }

          // 国内
          var appstoreData = await searchWithAppid(appid,'cn');
          this.contryCode ='cn';

          // 中国没有去美国
          if (!appstoreData.results.length){
             appstoreData = await searchWithAppid(appid,'us');
              this.contryCode ='us';
          }

          // 美国没有去香港
          if (!appstoreData.results.length){
             appstoreData = await searchWithAppid(appid,'hk');
              this.contryCode ='hk';
          }
          
          // 香港没有去日本
          if (!appstoreData.results.length){
              appstoreData = await searchWithAppid(appid,'jp');
              this.contryCode ='jp';
          }

          // 日本没有去韩国
          if (!appstoreData.results.length){
             appstoreData = await searchWithAppid(appid,'kr');
              this.contryCode ='kr';
          }

        // 名字 和 appid
         var requestData = {
            ipa_id :appid,
            name : appstoreData.results[0].trackName,
            version: appstoreData.results[0].version,
            bundle_id: appstoreData.results[0].bundleId,
            ipa_size:appstoreData.results[0].fileSizeBytes
         }
       
        var filArr = this.$route.query.ipainfo;

        if (filArr == undefined){
          filArr = await this.getSearch(requestData);
        }
    
        console.log("filarr2 : ", JSON.stringify(filArr));
        // 重组用于显示的数据
        var newData = new Array();
        var tempData1 = filArr;

          if (filArr["send_email"] == true){
                this.$router.push({
                  path: "/searchAppstore?name=" + requestData.name + "&code=" + this.contryCode,
                })
              return;
           }
           console.log("requestData.size : ", requestData.ipa_size);
          for (var j=0; j<tempData1.versions.length; j++){
            var tempData2 = tempData1.versions[j]; 
            var newDic = {};
            newDic["ipaID"] = tempData1.id;
            newDic["name"] = tempData1.name;
            newDic["version"] = tempData2.version;
            if(j == 0){
              newDic["size"] = this.getfilesize(parseInt(requestData.ipa_size));
            }else{
              newDic["size"] = "未知"
            }

            this.ipaVersions.push(tempData2.version);
            if(!tempData2.version){
              newDic["version"] = "未知版本"
            }
            newDic["url"] = tempData2.url;
            newData.push(newDic);
          }

      this.tableData = newData;

      var searchBundleID = filArr.bundle_id;
      this.searchName = filArr.name;

      document.getElementById("app-title").innerHTML= this.searchName + " - 下载";
      var data = await searchWithBundleID(searchBundleID, this.contryCode);
      
      var newVer = data.results[0].version;
      newVer = newVer.replace(/\s+/g,"");

      this.newVersion = newVer;


      console.log("newViesion : ", this.newVersion);
      this.bundle_id = searchBundleID;
      newVer = newVer.replace(".","");
      var isFind = false;
       for (var p=0; p<filArr.versions.length; p++){
          var searchVer = filArr.versions[p].version;
          searchVer = searchVer.replace(".","");
          newVer = newVer.replace(/\s+/g,"");

          searchVer = searchVer.replace(/\s+/g,"");

          if (newVer == searchVer){
            isFind = true;
            break;
          }
       }

      if (!isFind){
        this.reportBtn = ''
      }

    },

     getCountryStr(codeStr){
        if (codeStr == "cn"){
          return "中国区";
        }
        if (codeStr == "us"){
          return "美国区"; 
        }
        if (codeStr == "hk"){
          return "香港区"; 
        }
        if (codeStr == "jp"){
          return "日本区"; 
        }
        if (codeStr == "kr"){
          return "韩国区"; 
        }
        return "中国区";
    },
  
    
 compareVersion(v1, v2) {
  v1 = v1.split('.')
  v2 = v2.split('.')
  const len = Math.max(v1.length, v2.length)

  while (v1.length < len) {
    v1.push('0')
  }

  while (v2.length < len) {
    v2.push('0')
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i])
    const num2 = parseInt(v2[i])

    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }

  return 0
},

    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },
    async getSearch(requestData) {
      // 拿到请求数据 json
      var data = await searchIpa(requestData);
      return data;
     },
  },

  mounted() {
    this.requestSearchApp(this.getQueryString('appid'));
  },
}
</script>

<!--
 * @Author: zhangdasen zhangdasen@conew.com
 * @Date: 2022-04-14 00:02:01
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @LastEditTime: 2022-08-23 12:19:24
 * @FilePath: /smash_web/src/views/payApp.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

  <div class="background">
      <div class="flex algin">
        <div class="app-title">Dump APP 兑换码购买
        </div>
      </div>
    <div class="center-container—pay-code"> 
    
      <a><br>{{price1}}<br></a>
      <a>{{price2}}<br></a>
      <a>{{price3}}</a>
      <el-row>
      <div class="bg-purple-code">
      <div class="content-code">
        
        <el-radio v-model="radio" :label="3" >{{this.title1}}
          <div class="payValue-code">￥{{this.showPrice1}}.00</div>
        </el-radio>
        <el-radio v-model="radio" :label="2" >{{this.title2}}
          <div class="payValue-code">￥{{this.showPrice2}}.00</div>
        </el-radio>
        <el-radio v-model="radio" :label="1" >{{this.title3}}
          <div class="payValue-code">￥{{this.showPrice3}}.00</div>
        </el-radio>
      </div>
      </div>
        </el-row>
        <el-form>

    <el-row>
       <input class="payBtn" type="text"  v-model="input" placeholder="请输入联系方式（QQ，邮箱，手机号）">
    </el-row>
    </el-form>
   
    <el-row>
      <el-input-number size="medium" class="numInputApp" v-model="num" @change="handleChange" :min="1" :step="1"></el-input-number>
    </el-row>

 <el-button size="medium" class="payAppbtn" type="success" @click="successBuy()">点击购买</el-button>
  
    
    </div>
  </div>
</template>

<style>
.payAppbtn{
  top: 40px;
  width:200px;
  height: 40px;
  position: relative;
}

.numInputApp{
  top: 20px;
  width:35%;
   min-width:150px;
  height: 60px;
  position: relative;
}

.bg-purple-code {
    height:100px;
    background: #dceff7;
    /* min-width: 210px; */
    margin-left: 20px;
    margin-right: 20px;
    /* width: 90%; */
  }
.center-container—pay-code{
    background-color: white;
    width:65%;
    height:500px;
    min-width: 340px;
  }

.inputNumber{
   width:100px;
   min-width:150px;
   align-content: center;
}


.payValue-code{
  margin-top: 20px;
  left: 8px;
  font-size: 14px;
  color: red;
  position: relative;
}

.content-code{
  display:flex;
  justify-content:center;
  align-items:center;
}

.payBtn{
  width: 300px;
  /* padding-left: 80px; */
  /* padding-right: 80px; */
  /* min-width: 110px; */
}

</style>

<script>

import{getAppPrice} from '@/api/member'
import {installApp} from '@/api/install'
import {Loading, Message} from 'element-ui'
export default {
  data() {
      return {
        showPrice1:"",
        showPrice2:"",
        showPrice3:"",
        price1:"",
        price2:"",
        price3:"",
        price:"44",
        num:1,
        extentStr:"",
        extentCoin:"",
        centerDialogVisible: true,
        tableData: null,
        radio: 3,
        input:"",
        oriderId:"",
        appPriceData:{},
        title1:"",
        title2:"",
        title3:""
      }
    },

  methods: {
  getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },

     openBuyToast(){
       
     },

     handleChange(value) {
       this.showPrice = this.price * value;
      },
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    async getAllPrice(){
       var data = await getAppPrice();
       this.title1 = data.data[0].title;
       this.title2 = data.data[1].title;
       this.title3 = data.data[2].title;

       this.showPrice1 = data.data[0].price;
       this.showPrice2 = data.data[1].price;
       this.showPrice3 = data.data[2].price;

       this.price1 = data.data[0].title + " " + data.data[0].description + "------ 价格：" + this.showPrice1;
       this.price2 = data.data[1].title + " " + data.data[1].description + "------ 价格："+ this.showPrice2;
       this.price3 = data.data[2].title + " " + data.data[2].description + "------ 价格："+ this.showPrice3;

    },

    successBuy(){
      this.getInfo();
    },

     async getInfo() {
        if (this.input === '') {
          Message({
            message: "请输入联系方式！",
            type: 'error',
          duration: 1.5 * 1000,
          offset:document.body.clientHeight * 0.5 - 100
          })
          return
        }

       var data = await installApp(this.num, this.input,this.radio);
        if (data != null){
          this.$alert('请勿关闭网页！请确保成功支付后请等待网页自动跳转，获取兑换码', '提醒', {
          confirmButtonText: '已知道',
          callback: action => {
            window.location.href = data.open_url;
          }
        });
        }else{
            Message({
            message: "网络错误，请重试！",
            type: 'error',
          duration: 1.5 * 1000,
          offset:document.body.clientHeight * 0.5 - 100
          })
        }
        
     },
  },
    mounted() {
      this.getAllPrice();
    }
  }
</script>

import request from '@/utils/request'

/// 登录
export function getSelf () {
    return request({
      url: '/member/self',
      method: 'get',
    })
}

export function getSelfDevice () {
  return request({
    url: '/member/self/devices_v2?limit=500',
    method: 'get',
  })
}

export function getCerDevice(offset, keyword, levels) {

  const map = new Map([  
    
    ['limit','20'],
    ['offset',offset]
  ]); 
  
  if (keyword && keyword.trim().length > 0) {
    map.set('keyword', keyword)
  }

  if (levels.length > 0) {
    map.set('levels',JSON.stringify(levels))
  }

  const queryString = Array.from(map)  
  .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)  
  .join('&'); 

  console.log("queryString",queryString);

  return request({
    url: '/member/self/certificates_v2?' + queryString,
    method: 'get',
  })
}

export function setPublishCount(order_id,data){
  return request({
    url: 'sign_ipa/' + order_id ,
    method: 'put',
    data:data
  })
}

export function setAppleAccountStatus(appleId, note, status, reserve_count){

  const intNum = Number(reserve_count);

  var data = {
    note: note,
    status:status,
    ios_reserve_count:intNum
  }

  return request({
    url: '/apple_account/' + appleId ,
    method: 'put',
    data:data
  })
}

export function addUdid(data) {
  return request({
    url: '/device/udid',
    method: 'post',
    data:data
  })
}

export function upgradeRole() {
  return request({
    url: '/member/self/upgrade_role',
    method: 'post',
  })
}

export function getPriceLink(num) {
  return request({
    url: 'v3/member/order?number=' + num,
    method: 'get'
  })  
}

export function getInvite() {
  return request({
    url: '/member/self/rebate_record',
    method: 'get'
  })  
}

export function addCount(data) {
  return request({
    url: '/admin/member/order',
    method: 'post',
    data:data
  })
}

export function deleteCount(data) {
  return request({
    url: '/admin/member/order',
    method: 'delete',
    data:data
  })
}

export function setVipInfo(data) {
  return request({
    url: '/admin/member/vip',
    method: 'post',
    data:data
  })
}

// 批量删除 老的 IPA
export function deleteOldIPA(data) {
  return request({
    url: '/admin/batch_ipa',
    method: 'delete',
    data:data
  })
}

// 设置是否忙碌
export function setAdminBusySatus(data) {
  return request({
    url: '/admin/config',
    method: 'post',
    data:data
  })
}

// 获取是否忙碌
export function getAdminBusySatus(data) {
  return request({
    url: '/admin/config',
    method: 'get',
    data:data
  })
}

// 某个时间范围内用户充值列表 (注册时间)
export function getUserPayList(data) {
  return request({
    url: '/admin/member',
    method: 'get',
    data:data
  })
}

// 某个时间范围内用户获取 ipa 的排名情况
export function getUserSearchList(data) {
  return request({
    url: '/admin/search/record',
    method: 'get',
    data:data
  })
}

export function deleteAPP(ipa_id, isNormal) {
  var data = {
    ipa_id: ipa_id,
    ipa_type: isNormal ? "normal" : "crack",
    is_retain_latest_version:false
  }

  return request({
    url: '/admin/ipa',
    method: 'delete',
    data:data
  })
  
}

export function deleteDumpList(ipa_id, version) {
  var data = {
    ipa_id: ipa_id,
    ipa_type:"normal",
    ipa_version: version,
    is_retain_latest_version:false
  }

  return request({
    url: '/admin/ipa/dump_order',
    method: 'delete',
    data:data
  })
}


export function userFeedback(data) {
  return request({
    url: '/feedback',
    method: 'post',
    data:data
  })
}

// 需要砸壳的列表
export function getDumpList() {
  return request({
    url: '/admin/ipa/dump_order?offset=0&limit=100',
    method: 'get'
  })
}

export function getUserDumpList() {
  return request({
    url: '/ipa/dump_order/list?offset=0&limit=100',
    method: 'get'
  })
}

export function getIpaTypeList(ipa_type) { 
  return request({
    url: '/ipa/' + ipa_type + '/list',
    method: 'get'
  })
}

export function applyFree(data) {
  return request({
    url: '/daily_free',
    method: 'post',
    data:data
  })
}

export function dailyFreeList() {
  return request({
    url: '/daily_free',
    method: 'get'
  })
}

export function refreshToken() {
  return request({
    url: '/reset/token',
    method: 'post'
  })
}


export function freeAction() {
  return request({
    url: '/member/action',
    method: 'get'
  })
}

export function setFreeCount(data) {
  return request({
    url: '/admin/config',
    method: 'post',
    data:data
  })
}

export function setOrderState(dump_order_id, status) {
  var data = {
    status: status,
  }

  return request({
    url: 'admin/ipa/dump_order/' + dump_order_id ,
    method: 'put',
    data:data
  })
}

export function updateEmail(email) {
  var data = {
    email: email,
  }

  return request({
    url: '/reset/email',
    method: 'post',
    data:data
  })
}

export function getPrice() {
  return request({
    url: '/certificate/price',
    method: 'get'
  })
}
export function getAppPrice() {
  return request({
    url: '/install_app/cdkey/price',
    method: 'get'
  })
}

export function setAdminConfig(announcement) {
  var data = {
    announcement:announcement
  }
  return request({
    url: '/admin/config',
    method: 'post',
    data:data
  })
}

export function setAuthDomain(email, domain) {
  var data = {
    email:email,
    domain:domain
  }
  return request({
    url: 'admin/auth/website',
    method: 'post',
    data:data
  })
}

export function cerAdd(email, udid) {
  var data = {
    email:email,
    udid:udid
  }
  return request({
    url: 'admin/certificate/replenish',
    method: 'post',
    data:data
  })
}

export function getAdminConfig() {
  return request({
    url: '/admin/config',
    method: 'get'
  })
}

export function getRecord() {
  return request({
    url: '/member/self/coin/records?limit=100',
    method: 'get'
  })
}

export function getSignedApp(id, isPlist) {
  if (isPlist) {
    return request({
      url: '/sign_ipa/' + id + "?include=plist_url",
      method: 'get'
    })
  } else { 
    return request({
      url: '/sign_ipa/' + id,
      method: 'get'
    })
  }
}

export function setBlackList(ipa_id, reason) {
  var data = {
    ipa_id: ipa_id.toString(),
    reason:reason.toString() 
  }

  return request({
    url: 'admin/ipa_black',
    method: 'post',
    data:data
  })
}


export function setBlackHome(ipa_id, reason) {
  var data = {
    ipa_id: ipa_id.toString(),
    reason:reason.toString(),
    mark_type:"filter_home"
  }

  return request({
    url: 'admin/ipa_mark',
    method: 'post',
    data:data
  })
}

export function buyBook() {
  return request({
    url: '/v3/member/order/test?amount=100',
    method: 'get'
  })  
}

export function getAppVersion() {
  return request({
    url: '/app_versions',
    method: 'get'
  })  
}

export function addCoinWithIds(out_ids) {
  var data = {
    out_ids: out_ids
  }
  return request({
    url: 'v3/member/order/cdkey',
    method: 'post',
    data:data
  })
}
export function addPublicCoin(count) {
  var data = {
    count: count
  }
  return request({
    url: 'dispense',
    method: 'post',
    data:data
  })
}

export function createCDKey(type, email){
  var data = {
    number: 1,
    cer_level:type,
    price:35,
    contact:email 
  }

  return request({
    url: '/admin/cdkey',
    method: 'post',
    data:data
  })
}

/// 添加 p8 账号
export function addAppleID(iss,key,p8,remark) {
  var data = {
    issuer_id:iss,
    key_id: key,
    private_key:p8,
    note:remark
}
  return request({
    url: '/apple_account',
    method: 'post',
    data:data
  })
}

export function getAppAccount() {
  return request({
    url: '/apple_accounts?offset=0&limit=200',
    method: 'get'
  })  
}

export function downloadAppleP12(accountId) {
  return request({
    url: '/apple_account/' + accountId + '/p12',
    method: 'get'
  })  
}

export function syncAccountUdid(acId) {
  return request({
    url: '/apple_account/' + acId + '/sync_device',
    method: 'post'
  })  
}


export function deleteAccountUdid(acId) {
  return request({
    url: '/apple_account/' + acId,
    method: 'delete'
  })  
}

<template>

<div class="background">

  <div class="flex algin">
    <div class="app-title">iOS逆向工程 - 书籍文章
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

<div class="center-container-book">
<el-form>
  <div class="container">
    <img class="book" src="../assets/book.png" alt="" />
    <el-button type="danger" style="width:200px" @click="unlock" >付费查看</el-button>
  </div>
</el-form>
</div>
</div>

</template>

<style>

.book{
  height:100%;
  width:50%;
}

.center-container-book{
  background-color: white;
  width:70%;
  min-width: 340px;
  height:600px;
}

  .login-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import {getPriceLink} from '@/api/member'
import {postLogin} from '@/api/login'

import {validEmail} from '@/utils/util'

import {Message} from 'element-ui'

import {buyBook} from '@/api/member'
export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
  

    async unlock(){
     var data = await buyBook();
      
      // 判断是否登录成功
      if (data != null){
         window.location.href = data.open_url;
      }else{
          Message({
          message: "网络错误，请重试！",
          type: 'error',
          duration: 2 * 1000
        })
      }

    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

  },

  mounted() {

  },
  watch: {
  },
  name: 'Login'
}
</script>

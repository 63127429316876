/** * */

<template>
  <div class="background">
    <div class="toptitle">
      <div class="left">
        <div>微信主题列表</div>
      </div>
      <div class="center">
        <div>余额 {{ userCoin }} 币</div>
      </div>
      <div class="right">
        <el-button type="warning" @click="jumpPublish">上传主题包</el-button>
        <el-button type="primary" @click="jumpCodeList">兑换码列表</el-button>
      </div>
    </div>

    <el-dialog
      title="生成兑换码"
      :visible.sync="showCreateCode"
      width="50%"
      center
    >
      <el-form :model="codeForm" :rules="codeRules" ref="codeForm">
        <el-form-item class="edit_item_title" label="生成个数" prop="count">
          <el-input v-model="codeForm.count" @keypress="onKeyPress"></el-input>
        </el-form-item>
        <el-form-item
          class="edit_item_title"
          label="兑换码前缀"
          prop="description"
        >
          <el-input v-model="codeForm.prefix"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="edit_bottom_btn">
            <el-button @click="showCreateCode = false">取 消</el-button>
            <el-button type="primary" @click="getCodeComplete">确 定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="编辑主题信息" :visible.sync="showEdit" width="50%" center>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item class="edit_item_title" label="主题名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          class="edit_item_title"
          label="备注内容"
          prop="description"
        >
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="edit_bottom_btn">
            <el-button @click="showEdit = false">取 消</el-button>
            <el-button type="primary" @click="editComplete">确 定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <div class="table">
      <el-table
        :data="tableData"
        height="100%"
        row-key="id"
        :row-class-name="rowClassName"
        :tree-props="{
          children: 'versions',
          hasChildren: true,
        }"
      >
        <el-table-column
          type="index"
          label="序号"
          width="80"
          align="center"
          :index="indexMethod"
        />
        <el-table-column prop="name" label="主题名称" align="center" />
        <el-table-column prop="description" label="备注" align="center" />
        <el-table-column prop="createTime" label="时间" align="center" />
        <el-table-column
          prop="created_at"
          label="操作"
          align="center"
          width="400px"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.isChild == false"
              type="success"
              @click="getCode(scope.row)"
            >
              生成兑换码
            </el-button>
            <el-button type="info" @click="editTheme(scope.row)">
              编辑
            </el-button>
            <el-button
              v-if="scope.row.isChild == false"
              type="primary"
              @click="updateThemeVersion(scope.row)"
            >
              上传新版本
            </el-button>
            <el-button type="danger" @click="deleteAction(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pageDiv">
      <el-pagination
        small
        background
        layout="prev, pager, next"
        :total="total"
        class="mt-4"
        :hide-on-single-page="true"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import ThemeApi from "../../api/wxtheme";
import { Loading, Message } from "element-ui";
import { getSelf } from "@/api/member";

export default {
  name: "wxThemePage",
  components: {},
  data() {
    return {
      total: 0,
      currentPage: 1,
      tableData: [],
      loading: null,
      showEdit: false,
      showCreateCode: false,
      userCoin: 0,
      rules: {
        name: [{ required: true, message: "请输入主题名称", trigger: "blur" }],
      },
      form: {
        name: null,
        description: "",
        id: 0,
      },
      codeRules: {
        count: [{ required: true, message: "请输入创建个数", trigger: "blur" }],
      },
      codeForm: {
        theme_id: null,
        count: 0,
        prefix: "",
      },
      editItem: null,
    };
  },
  methods: {
    async getMemberSelf() {
      // 拿到请求数据 json
      var data = await getSelf();
      console.log("getSelf", data);
      if (data != null) {
        this.userCoin = data.pay_count;
      }
    },
    onKeyPress(event) {
      console.log("输入", event);
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    jumpPublish() {
      this.$router.push({
        path: `/theme/upload`,
      });
    },
    jumpCodeList() {
      this.$router.push({
        path: `/theme/cdkey`,
      });
    },
    rowClassName({ row, rowIndex }) {
      return "custom";
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.requestData();
    },

    async requestData() {
      var res = await ThemeApi.getThemeList((this.currentPage - 1) * 10);
      this.tableData = res.data;
      this.tableData.map((item) => {
        item.createTime = this.timestampToTime(item.created_at);
        item.isChild = false;
        item.versions.map((sub) => {
          sub.createTime = this.timestampToTime(sub.created_at);
          sub.isChild = true;
          sub.theme_id = item.id;
        });
      });
      this.total = res.paging.totals;

      console.log("获取的列表", res);
    },

    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //编辑
    editTheme(value) {
      this.form.name = value.name;
      this.form.description = value.description;
      this.form.id = value.id;
      this.editItem = value;
      this.showEdit = true;

      // this.dialognew2 = true;
      // this.publishIpaMode = value
    },
    editComplete() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.requestEdit();
          this.showEdit = false;
        }
      });
    },
    //生成兑换码
    getCode(value) {
      this.codeForm = {
        theme_id: value.id,
        count: null,
        prefix: "",
      };
      this.showCreateCode = true;
    },
    // 确定生成兑换码
    getCodeComplete() {
      this.$refs.codeForm.validate((valid) => {
        if (valid) {
          this.requestCreateCode();
          this.showCreateCode = false;
        }
      });
    },

    updateThemeVersion(value) {
      this.$router.push({
        path: `/theme/upload`,
        query: {
          id: value.id,
          name: value.name,
          description: value.description,
        },
      });
    },

    async deleteAction(value) {
      var res = value.isChild ? await ThemeApi.deleteThemeVersion(value.id) : await ThemeApi.deleteTheme(value.id);
        if (res == "ok") {
          this.requestData();
        }
    },

    async requestEdit() {
      const body = {
        name: this.form.name,
        description: this.form.description,
        url_token: this.form.id,
      };

      var res = this.editItem.isChild ? await ThemeApi.editThemeVersion(this.form.id, body) : await ThemeApi.editTheme(this.form.id, body);
      if (res != undefined) {
        Message({
          message: "编辑成功!",
          type: "success",
          duration: 1000,
          offset: document.body.clientHeight * 0.5 - 100,
        });
        this.editItem.name = this.form.name;
        this.editItem.description = this.form.description;
      }
    },

    async requestCreateCode() {
      const body = {
        theme_id: this.codeForm.theme_id,
        count: parseInt(this.codeForm.count),
        prefix: this.codeForm.prefix,
      };

      var res = await ThemeApi.createCode(body);
      if (res != undefined) {
        Message({
          message: "创建兑换码成功!",
          type: "success",
          duration: 1000,
          offset: document.body.clientHeight * 0.5 - 100,
        });
        this.jumpCodeList();
      }
    },

    indexMethod(index) {
      return (this.currentPage - 1) * 10 + index + 1;
      // console.log("====",index);
      // return this.$parent.$children.data[index].versions ? '' : index + 1;
      // return ""
    },
  },

  mounted() {
    this.requestData();
    this.getMemberSelf();
  },
};
</script>

<style scoped>
.toptitle {
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  background-color: #65db79;
}
.left {
  color: white;
  text-align: left;
  margin-left: 5%;
  width: 50%;
  font-size: 20px;
  font-weight: 800;
}

.right {
  color: white;
  text-align: end;
  width: 50%;
  margin-right: 5%;
  font-size: 17px;
  font-weight: 600;
}

.background {
  background-color: white;
  position: relative;
  justify-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  display: flex;
}

.edit_item {
  justify-items: center;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.edit_item_title {
  font-weight: 500;
  color: black;
  width: 100%;
}

.table {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 0;
  right: 0;
}
.pageDiv {
  position: absolute;
  display: inline-flex;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 20px;
  align-items: center;
  justify-content: flex-end;
}

.edit_bottom_btn {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.center {
  color: #eab948;
  text-align: center;
  width: 40%;
  font-size: 18px;
  font-weight: 600;
}
</style>

/*
 * @Author: your name
 * @Date: 2022-01-24 20:49:39
 * @LastEditTime: 2022-08-31 18:49:06
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/api/apple.js
 */
import request from '@/utils/apple_request'

/// 登录
export function searchWithAppid(appid, country) {
    return request({
      url: country + '/lookup?id='+ appid,
      method: 'post'
    })
}

export function searchWithBundleID(bundleId, country) {
  return request({
    url: country + '/lookup?bundleId='+ bundleId,
    method: 'post'
  })
}

export function searchWithName(name, country, ipad) {
  var entity = "software"
  if (ipad == '2') {
    entity = 'iPadSoftware' 
  }

  return request({
    url: country + '/search?term='+ name + "&media=software&limit=60&entity=" + entity,
    method: 'post'
  })
}


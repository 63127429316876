<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">选择证书进行签名
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

<div class="center-container-cer">
    <el-button class="buyButton" style="width:30%;" type="success" @click="onBuyCer">购买证书</el-button>
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      fixed
      prop="udid"
      label="UDID"
      align="center">
    </el-table-column>
    <el-table-column
     prop="class"
      label="型号"
      align="center">
    </el-table-column>
    <el-table-column
     prop="createTime"
      label="创建时间"
      align="center">
    </el-table-column>
     <el-table-column
     prop="cerStatus"
      label="证书状态"
      align="center">
    </el-table-column>
    <el-table-column
     prop="download"
      label="选择证书"
      align="center">
       <template slot-scope="scope">
      <el-button @click="clickSelectSign(scope.row.cerId)" type="success">使用证书</el-button>
      </template>
    </el-table-column> 
  </el-table>
</div>
</div>
</template>

<style>

  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_down{
    top: 20px;
    position: relative;
  }
  
  .center-container-cer{
    background-color: white;
    width:70%;
    min-width: 340px;
    height:560px;
  }

</style>

<script>
import {getSelf} from '@/api/member'
import {downloadP12} from '@/api/download'
import {downloadMobile} from '@/api/download'

export default {
  data() {
      return {
        tableData: null,
        loading : true,
      }
    },
  methods: {
   async getMemberSelf() {
      var userData = await getSelf()
      // 判断是否登录成功
      if (userData != null){
        
        var newData = [];

      if (userData.devices == null){
        this.loading = false;
        return;
      }

        var newData = new Array();
        for (var i=0; i<userData.devices.length; i++){
          var data = userData.devices[i];
          if (data.certificates != null && data.certificates.length != 0){
            for (var j=0; j<data.certificates.length; j++){
                var newDic = {};
                var cerStatusStr = "检测中";
                var cerData = data.certificates[j];
                newDic["udid"] = data.udid;
                newDic["class"] = this.convertDeviceName(data.product);
                newDic["createTime"] = this.formatDate(cerData.created_at);
                newDic["deviceId"] = data.id;
                newDic["cerId"] = cerData.id;
                if (cerData.p12_is_active){
                  cerStatusStr = "有效";
                }else{
                  cerStatusStr = "无效"; 
                }

                newDic["cerStatus"] = cerStatusStr; 
                newDic["download"] = cerData.id;  
                newData.push(newDic);
            }
          }

        }

      this.tableData = newData;
      this.loading = false;
      }
    },

    onBuyCer(){
      this.$router.push({
            path: '/cerBuy'
      })
    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    formatDate(nS) {     
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
    },
    
    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },

   async invoicesLink(cerId, deviceId){
     this.downloadP12File(cerId, deviceId);
     this.downloadMobileFile(cerId, deviceId);
   },

   async clickSelectSign(cerId){
      var signId = this.getQueryString('signId');
      this.$router.push({
              path: `/signipalist?signId=` + signId + "&cerId=" + cerId,
      })
   },

  async  downloadP12File(cerId, deviceId){
      let res = await downloadP12(cerId, deviceId);
      let blob = new Blob([res])
      let url = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download',"developer.p12")
      document.body.appendChild(link)
      link.click()
  },

   async downloadMobileFile(cerId, deviceId){
      let res = await downloadMobile(cerId, deviceId);
      let blob = new Blob([res])
      let url = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download',"developer.mobileprovision")
      document.body.appendChild(link)
      link.click()
    },


    convertDeviceName(devName){
        if (devName == "iPhone5,1")  return "iPhone 5";
        if (devName == "iPhone5,2")  return "iPhone 5";
        if (devName == "iPhone6,1")  return "iPhone 5s";
        if (devName == "iPhone6,2")  return "iPhone 5s";
        if (devName == "iPhone7,2")  return "iPhone 6";
        if (devName == "iPhone7,1")  return "iPhone 6 Plus";
        if (devName == "iPhone8,1")  return "iPhone 6s";
        if (devName == "iPhone8,2")  return "iPhone 6s Plus";
        if (devName == "iPhone8,4")  return "iPhone SE";
        if (devName == "iPhone9,1")  return "iPhone 7";
        if (devName == "iPhone9,3")  return "iPhone 7";
        if (devName == "iPhone9,2")  return "iPhone 7 Plus";
        if (devName == "iPhone9,4")  return "iPhone 7 Plus";
        if (devName == "iPhone10,1")  return "iPhone 8";
        if (devName == "iPhone10,4")  return "iPhone 8";
        if (devName == "iPhone10,2")  return "iPhone 8 Plus";
        if (devName == "iPhone10,5")  return "iPhone 8 Plus";
        if (devName == "iPhone10,3")  return "iPhone X";
        if (devName == "iPhone10,6")  return "iPhone X";
        if (devName == "iPhone11,2")  return "iPhone XS";
        if (devName == "iPhone11,4")  return "iPhone XS Max";
        if (devName == "iPhone11,6")  return "iPhone XS Max";
        if (devName == "iPhone11,8")  return "iPhone XR";
        if (devName == "iPhone12,1")  return "iPhone 11";
        if (devName == "iPhone12,3")  return "iPhone 11 Pro";
        if (devName == "iPhone12,5")  return "iPhone 11 Pro Max";
        if (devName == "iPhone12,8")  return "iPhone SE";
        if (devName == "iPhone13,1")  return "iPhone 12 Mini";
        if (devName == "iPhone13,2")  return "iPhone 12";
        if (devName == "iPhone13,3")  return "iPhone 12 Pro";
        if (devName == "iPhone13,4")  return "iPhone 12 Pro Max";
        if (devName == "iPhone14,5")  return "iPhone 13";
        if (devName == "iPhone14,2")  return "iPhone 13 Pro";
        if (devName == "iPhone14,3")  return "iPhone 13 Pro Max";
        if (devName == "iPhone14,4")  return "iPhone 13 Mini";

        if (devName == "iPhone14,6")  return "iPhone SE3";
        if (devName == "iPhone14,7")  return "iPhone 14";
        if (devName == "iPhone14,8")  return "iPhone 14 Plus";

        if (devName == "iPhone15,2")  return "iPhone 14 Pro";
        if (devName == "iPhone15,3")  return "iPhone 14 Pro Max";
    },

    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },

  },


    
  mounted() {
    this.getMemberSelf();
  },

}
</script>
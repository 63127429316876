<template>

<div class="background">
  <div class="flex algin">
    <div id="app-title" class="app-title">App Store 搜索页面</div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>
  <div id="top-notice" class="top-notice">
1. 网站每天共<a style="color:red "> 5个 </a>免费砸壳名额,每个账号每天拥有 <a style="color:red"> 1次 </a>免费获取砸壳名额机会<br><br> 2. 每日免费砸壳开放时间 中午 12:00 ，<a style="color:red"> 一般 10 分钟内</a> 会被申请完毕 <br><br><a style="color:red">3. 免费名额，一般为当天晚上23：00，如需快速更优质的服务，请用 D 币进行获取 APP。<br></a><br>
  </div>
<div class="table_content_result">

   <el-button type="primary" size="large" v-show="isFree" @click="openPan">申请APP网盘地址</el-button>
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      fixed
      prop="icon"
      label="图标"
      align="center">
     <template slot-scope="scope">
       <el-image
      style="width: 40px; height: 40px; border-radius: 50%;"
      :src="scope.row.url"
      ></el-image>
     </template>
    </el-table-column>
    <el-table-column
     prop="name"
      label="名称"
      align="center">
    </el-table-column>

    <el-table-column
     prop="size"
      label="大小"
      align="center">
    </el-table-column>
    <el-table-column
      prop="trackId"
      label="申请获取"
      align="center">
     <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.trackId, scope.row.name, scope.row.price, scope.row.bundle_id, scope.row.version, scope.row.size, scope.row.minimumOsVersion)" type="success" icon="el-icon-s-custom" circle></el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="downloadIcon"
      label="下载图标"
      align="center">
     <template slot-scope="scope">
       <el-button @click="downloadIcon(scope.row.artworkUrl, scope.row.name, scope.row.artworkUrl100)" type="primary" icon="el-icon-download" circle></el-button>
      </template>
    </el-table-column>
   <el-table-column
      v-if="showblock" 
      prop="blackApp"
      label="拉黑应用"
      align="center">
     <template slot-scope="scope">
       <el-button @click="blackApp(scope.row.trackId,scope.row.name)" type="primary" icon="el-icon-s-custom" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>

<el-dialog class="toast_qrcode"
  title="检测到曾经下载过APP某版本，请选择"
  :visible.sync="dialogVisible"
  width="400px">
  <el-button type="primary" @click="getipa1"> 继续申请新版本</el-button>
    <el-button type="danger" @click="getipa2"> 获取购买过的版本</el-button>
</el-dialog>

</div>



</template>

<style>
  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_result{
    position: relative;
    width: 90%;
    min-width: 340px;
  }
    .top-notice{
    width: 70%;
    color: black;
    background-color: white;
    margin-bottom: 30px;
    min-width: 280px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .22), 0 0 6px rgba(0, 0, 0, .24);
  }


</style>

<script>
import {Message} from 'element-ui'
import {searchIpa} from '@/api/download'
import {searchWithName} from '@/api/apple'
import {getSelf} from '@/api/member'
import {setFreeCount,setBlackList, applyFree,setBlackHome} from '@/api/member'

import {
  postEmail,
  postApp,
  postDumpOrder
} from '@/api/email'

export default {
  data() {
      return {
        userEmail : '',
        tableData: null,
        reportBtn:'none',
        searchName:'',
        newVersion:'',
        searchBundleID:'',
        url:'',
        downloadCount:0,
        loading : true,
        dialogVisible : false,
        filArr: null,
        show_name : "",
        show_bundleid:"",
        show_version:"",
        show_storeLink : "",
        show_trackID :"",
        showblock:false,
        isFree : false
      }
    },

  methods: {
   async getMemberSelf() {
      var data = await getSelf()
      // 判断是否登录成功
      if (data != null){
        this.downloadCount = data.pay_count;
        this.userEmail = data.email;
        if (data.is_admin){
           // this.showblock = true;
        }
      }
    },

    clickqq () {
      alert('请加 qq 群: 763789550')
    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

     openMsg(vip_str) {
        alert(vip_str);
     },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },

     getCountryStr(codeStr){
        if (codeStr == "cn"){
          return "中国区";
        }
        if (codeStr == "us"){
          return "美国区"; 
        }
        if (codeStr == "hk"){
          return "香港区"; 
        }
        if (codeStr == "jp"){
          return "日本区"; 
        }
        if (codeStr == "kr"){
          return "韩国区"; 
        }
        return "中国区";
    },
    
   openPan(){
      window.open('https://pan.dumpapp.com/%E6%AF%8F%E6%97%A5%E5%85%8D%E8%B4%B9%20-%20APP');
   },

   async blackApp(trackID, name){
      this.$prompt('请输入拉黑 APP 的理由', '操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
      }).then(({ value }) => {
        setBlackHome(trackID,  value);
      }).catch(() => {

      });
   },

   async downloadIcon(downloadIconUrl, name,downloadIconUrl100){


          this.$copyText(downloadIconUrl100).then(
          (res) => {
          Message({
            message:"图标链接，已复制到剪切板",
                type: 'success',
                duration: 1 * 1000,
                offset:document.body.clientHeight * 0.5 - 100
              })
          },
          (err) => {
          }
          )
    const response = await fetch(downloadIconUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.png`); // 或任何其他扩展名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // 释放URL对象
   },

   

   async invoicesLink(trackID, name, price, bundleID, version, size,minimumOsVersion){
            this.show_name = name;
            this.show_bundleid = bundleID;
            this.show_version = version;
            this.show_trackID = trackID;
     
             if (price != 0){
                Message({
                  message: "DumpAPP - 网站暂不支持 App Store 收费软件！",
                  type: 'error',
                  duration: 2 * 1000
                }) 
                return;
              }

              if (minimumOsVersion == "17.0" || minimumOsVersion == "16.0"){
                Message({
                  message: "此APP限制安装必须 " + minimumOsVersion + " 系统，暂时不支持砸壳",
                  type: 'error',
                  duration: 2 * 1000
                }) 
                return;
              }

            var appStoreLink = "https://apps.apple.com/"+ this.getQueryString('code') +"/app/" + encodeURIComponent(name) + "/id" + trackID.toString();

           this.show_storeLink = appStoreLink;
           var requestData = {
              ipa_id :trackID.toString(),
              name : name.toString(),
              version: version,
              bundle_id:bundleID,
              appstorelink: appStoreLink
           }

             var freeData = {
              ipa_id :trackID.toString(),
              ipa_version: version,
           }

          // 免费 APP 的处理方式
           if (this.getQueryString('isFree') == 1){
            var tempDate = new Date();
            var day = tempDate.getDay();
            if(day == 6 || day == 0){
                alert("免费砸壳，每周1 - 每周5 期间开放");
                return;
              }

              var search = size.indexOf("G") != -1
              if(search){
                alert("免费砸壳名额，只能获取 1G 以下容量大小的 APP 包。");
                return;
              }

              var d = new Date();
              var hour = d.getHours();

              console.log("hour :", hour);
              // 如果符合时间
              if (hour >= 12 && hour < 24){
                // 请求服务器
                var requestData = await applyFree(freeData);
                
                // 发送邮件
                if (requestData == ''){
                    var title = 'DumpApp -  每日免费IPA ' + name.toString()
                    var data = {
                      emails: ["zhangdasen@dumpapp.com"],
                      title: title,
                      content:  "每日免费 DumpAPP " + "<br>" + "应用名称: " + name.toString() + " 版本 ：" + version + "<br>" + "appStoreLink :" + appStoreLink + "<br>" + "用户邮箱：" + this.userEmail 
                    }
                    postEmail(data);

                    // 提示弹框
                    alert("您申请的免费砸壳 APP ：" + name.toString() + " 已发送请求，请当天晚上最晚23:00 去 pan.dumpapp.com 每日免费去下载");
                    alert("免费名额每日有限，获取 IPA 时间较慢一般为当天晚上23:00。如需快速更优质的服务，请使用 站内 D 币进行获取 APP。");
                    return;
                }

                if (requestData.error.code == '70002'){
                  alert("尊敬的客户，今日免费名额已发放完，请明日12:00之后及时申请（如需不想等待，可进行充值后下载）, 目前可去 pan.dumpapp.com 去下载公共申请的。");
                  return;
                }
              }else{
                alert("每日免费砸壳，开放时间 中午 12:00  - 晚上24:00 ");
              }
             return;
           }

           // 免费 APP 处理结束。

            var filArr = await this.getSearch(requestData);
            this.filArr = filArr;

           // 曾经购买过
           if (filArr["is_download_other_version"] == true){
             this.dialogVisible = true;
             return;
           }

            if (filArr["black_info"]!= undefined && filArr["black_info"].is_black == true){
              var reason = filArr["black_info"].reasons[filArr["black_info"].reasons.length - 1].reason;
              reason = reason.replace(/\\n/g,"<br/>");
              console.log("reason :", reason);
              this.$alert(reason, '友情提示', {
                confirmButtonText: "知道了",
                 dangerouslyUseHTMLString: true
              }).then(() => {
                this.continueDownloadApp(filArr, trackID, name, bundleID, version,appStoreLink);
              });
            return;
            }
            this.continueDownloadApp(filArr, trackID, name, bundleID, version,appStoreLink);
    },

    continueDownloadApp(filArr, trackID, name, bundleID, version, appStoreLink){

           // 冷门 IPA 需要发邮件
           if (filArr["send_email"] == true){
              this.postIpaRequestMail(["dumpapp@126.com"], name, bundleID, version, this.getCountryStr(this.getQueryString('code')), appStoreLink, trackID);
              this.openMsg('您的请求已提交，我们稍后会生成IPA，然后通过邮箱通知您! （1-3小时还没有收到 IPA 包的，可添加网站微信联系客服咨询进度）(节假日服务器维护，ipa 出包比较慢)');
              return;
           }

          // 跳转
          if (filArr["bundle_id"].length != 0){
              this.$router.push({
                  path: `/download?appid=` + trackID,
                  query:{"ipainfo" : filArr}
              })
              return;
           }
           
    },


    async getipa1(){
           // 冷门 IPA 需要发邮件
           if (this.filArr["send_email"] == true){
              this.postIpaRequestMail(["dumpapp@126.com"], this.show_name, this.show_bundleid, this.show_version, this.getCountryStr(this.getQueryString('code')), this.show_storeLink, this.show_trackID);
              this.dialogVisible = false;
              this.openMsg('您的请求已提交，我们稍后会生成IPA，然后通过邮箱通知您! （1-3小时还没有收到 IPA 包的，可添加网站微信联系客服咨询进度）(节假日服务器维护，ipa 出包比较慢)');
              return;
           }

    },

    async getipa2(){
      this.$router.push({
              path: `/downloadhistory`,
      }) 
    },

    async getSearch(requestData) {
      // 拿到请求数据 json
      var data = await searchIpa(requestData);
      return data;
     },

     postIpaRequestMail(emails, name, bundleID, version, contryStr, appStoreLink, trackID){
      var title = 'DumpApp - 应用名称: ' + name
      var data = {
        emails: emails,
        title: title,
        content: "<br>" + "国家: " + contryStr +   "<br>"  + "应用名称: " + name + "<br>" + "应用版本: " + version + "<br>" + "bundleID: " + bundleID + "<br>" + "D币个数: " + this.downloadCount +  "<br>" + "用户邮箱: " + this.userEmail  +  "<br>" + "App Store: " + appStoreLink  
      }

      // postEmail(data);

      var data2 = {
        name: name,
        bundle_id: bundleID,
        version: version,
        app_store_link: "https://apps.apple.com/" + this.getQueryString('code') +"/app/" + encodeURIComponent(name) + "/id" + trackID.toString(),
        ipa_id: trackID.toString()
      }

      postDumpOrder(data2);
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },
    openPayMsg(vip_str) {
        var confirmStr = "确认";
        confirmStr =  '充值次数';
         this.$confirm( '<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          dangerouslyUseHTMLString : true
        }).then(() => {
         this.routerPay(); 
        }).catch(() => {        
        });
      },
    // 搜索 apup
   async requestSearchApp (name, countryCode) {
    
     if(countryCode == undefined){
       countryCode = "cn"
     }

     var appstoreData = await searchWithName(name, countryCode, this.getQueryString('ipad'));
   
     if(!appstoreData.resultCount){
        this.loading = false;
        return;
     }

     if (!appstoreData.results.length){
        this.loading = false;
        return;
     }

      var filArrs =  appstoreData.results;

      var newData = new Array();
      for (var i=0; i<filArrs.length; i++){
        var ipaData = filArrs[i];

        var imgURL = ipaData.artworkUrl60;
        var ipaName = ipaData.trackName;
        var trackId = ipaData.trackId;
        var artworkUrl = ipaData.artworkUrl512


        
        var price = ipaData.price;
        var bundleID = ipaData.bundleId;
        var version = ipaData.version;

        var newDic = {};
        newDic["minimumOsVersion"] = ipaData.minimumOsVersion;
        newDic["url"] = imgURL;
        newDic["name"] = ipaName;
        newDic["trackId"] = trackId;
        newDic["price"] = price;
        newDic["bundle_id"] = bundleID;
        newDic["version"] = version;
        newDic["artworkUrl"] = artworkUrl;
        newDic["artworkUrl100"] = ipaData.artworkUrl100;
        newDic["size"] = this.getfilesize(parseInt(ipaData.fileSizeBytes));
        newData.push(newDic); 
      }

      this.tableData = newData;
      this.loading = false;
    }, 
  getfilesize(size) {
        if (!size)  return "";
        var num = 1024.00; //byte
        if (size < num)
            return size + " B";
        if (size < Math.pow(num, 2))
            return (size / num).toFixed(2) + " KB"; //kb
        if (size < Math.pow(num, 3))
            return (size / Math.pow(num, 2)).toFixed(2) + " MB"; //M
        if (size < Math.pow(num, 4))
            return (size / Math.pow(num, 3)).toFixed(2) + " G"; //G
        return (size / Math.pow(num, 4)).toFixed(2) + " T"; //T
},
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },
  },

  mounted() {
    document.getElementById("app-title").innerHTML="App Store " + this.getCountryStr(this.getQueryString('code'));
    this.requestSearchApp(this.getQueryString('name'), this.getQueryString('code'));
    sessionStorage.clear();
    this.getMemberSelf();

      if (this.getQueryString('isFree') == 1){
        this.isFree = true;
        document.getElementById("top-notice").style.display="black";
      }else{
        document.getElementById("top-notice").style.display="none";
      }

  },

}
</script>

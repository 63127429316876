<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">DumpApp - 管理员账号注册
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>
<div class="center-container3">
<el-form>
  <div class="container">
      <el-form-item label="">
      <el-input placeholder="请输入邮箱 (用于接收通知)" v-model="email"></el-input>
      </el-form-item>
      <el-form-item label="">
      <el-input placeholder="请输入密码" show-password v-model="password"></el-input>
      </el-form-item>
      <el-form-item label="">
      <el-input placeholder="请输入手机号" v-model="phoneNumber"></el-input>
      </el-form-item>
      <div class="register-button">
        <el-button size="medium" type="danger" @click="onRegister()">立即注册
        </el-button>
      </div>
  </div>
</el-form>
</div>
</div>

</template>

<style>

  .center-container3 {
    background-color: white;
    width:70%;
    min-width: 350px;
    height:430px;
  }

  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .captcha {
    display:flex;
  }

  .captcha2 {
    display:flex;
  }


  .register-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import {postRegister2} from '@/api/login'

import {validEmail} from '@/utils/util'

import {Message} from 'element-ui'

export default {
  data() {
    return {
      invite_code:'',
      email: '',
      phoneNumber:'',
      password: '',
      confirmPassword: '',
      captcha: '',
      captcha2:'',
      code: '获取 - 邮箱验证码',
      code2:'获取 - 手机验证码',
      timer:null,
      timer2:null,
    }
  },

  methods: {
    clickqq () {
      alert('请加 qq 群: 763789550')
    },


    async getCode(){
      if (this.timer != null) {
          return
      }
      var email = this.email
      if (email === '') {
        Message({
          message: "请输入邮箱！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }
      if (validEmail(email) == false) {
        Message({
          message: "请输入有效的邮箱",
          type: 'error',
          duration: 2 * 1000
          })
          return
        }
        var data = {
          email: email,
        }
        postCaptcha(data)
       
         Message({
          message: "国外邮箱，或者冷门邮箱，可能会收不到验证码，可尝试更换注册邮箱，或者联系客服手动创建账号",
          type: 'warning',
          duration: 2 * 1000
        })

        const TIME_COUNT = 180;
        this.code = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.code > 1 && this.code <= TIME_COUNT) {
            this.code--;
          } else {
            this.code= "获取 - 邮箱验证码"
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
    },

    async getCode2(){
      if (this.timer2 != null) {
          return
      }

      var number = this.phoneNumber
      if (number === '') {
        Message({
          message: "请输入手机号！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

        var data = {
          phone: number,
        }

        postPhoneCaptcha(data)
       
        const TIME_COUNT = 180;
        this.code2 = TIME_COUNT;
        this.timer2 = setInterval(() => {
          if (this.code2 > 1 && this.code2 <= TIME_COUNT) {
            this.code2--;
          } else {
            this.code2= "获取 - 手机验证码"
            clearInterval(this.timer2);
            this.timer2 = null;
          }
        }, 1000)
    },

    async onLogin() {
        this.$router.push({
          path: `/login`,
        })
    },

   
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
 

    async onRegister(){
      if (this.email === '') {
        Message({
          message: "请输入邮箱！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }
      if (validEmail(this.email) == false) {
        Message({
          message: "请输入有效的邮箱",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }
      if (this.password === '') {
        Message({
          message: "请输入密码",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }
      if (this.phoneNumber === '') {
        Message({
          message: "请输入手机号",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      var data = {
        email: this.email,
        phone : this.phoneNumber,
        password: this.password,
      }

      var res = await postRegister2(data)
      try {
        if (res.id != undefined){
          this.$router.push({
            path: `/login`,
          })
        }
      } catch (e) {
        // 错误处理代码片段
      }
    },
       getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },
  },
  mounted() {
      this.invite_code = this.getQueryString('invite_code');
  },
  watch: {
  },
  name: 'Register'
}
</script>

<template>
  <div class="app-container">
    <el-row type="flex" justify="center" class="header-row">
      <el-col :span="4">
        <el-button @click="onBack" class="back-btn">返回首页</el-button>
      </el-col>
      <el-col :span="16" class="title-container">
        <h1 class="app-title">DumpApp - 查询设备型号</h1>
      </el-col>
      <el-col :span="4"> <!-- 占位，保持对称 --> </el-col>
    </el-row>
  
    <el-row type="flex" justify="center" class="content-row">
      <el-col :span="22" :sm="20" :md="12" :lg="10" :xl="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>请输入UDID</span>
          </div>
          <el-input type="textarea" autosize placeholder="请输入UDID,每行一个" style="height: 110px;" v-model="resultStr"></el-input>
         
        </el-card>
      </el-col>

      <el-button type="primary" @click="search" style="margin-left: 10px;" class="search-btn">搜索</el-button>
    </el-row>
  </div>
  </template>
<style scoped>
.app-container {
  margin: 20px;
  min-width: 300px; /* 根据需要调整 */
}

.header-row {
  margin-bottom: 10px;
}

.back-btn {
  float: left;
}

.title-container {
  display: flex;
  justify-content: center;
}

.app-title {
  font-size: 24px;
  text-align: center;
  color: #2c3e50; /* 设置标题颜色为深蓝色，或选择其他颜色以确保可见性 */
}

.box-card {
  margin-top: 10px;
}

.search-btn {
  margin-top: 0px;
  display: block;
}

@media (max-width: 768px) {
  .header-row {
    flex-direction: column;
    text-align: center;
    margin-bottom: 5px;
  }

  .back-btn {
    margin-bottom: 10px;
    order: 1;
  }

  .title-container {
    order: 2;
  }

  .box-card {
    margin-top: 5px;
  }
}
</style>




<script>

import {PostAppleDevice} from '@/api/admin/app_version'
export default {
  data() {
      return {
        title:"搜索",
        resultStr:""
      }
    },

  methods: {

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
    async search() {

      // 分割
      var resultArray = this.resultStr.trim().split('\n').filter(item => item);

      var isSuccess = true;
      for (let i = 0; i < resultArray.length; i++) {
        var udid = resultArray[i];
        if (udid.length  != 40 && udid.length != 25){
          alert("udid 格式不对，请检查格式 : ", udid);
          isSuccess = false;
          break;
        }
      }

      if (!isSuccess){
        return;
      }

      var deviceData =  await PostAppleDevice(resultArray);
      this.resultStr = "";
      for (let i = 0; i < deviceData.data.length; i++) {
        var device = deviceData.data[i].devices[0].model;
        if (device == undefined || device == ""){
          device = deviceData.data[i].devices[0].deviceClass;
        }
        var udid = deviceData.data[i].devices[0].deviceNumber;
        this.resultStr = this.resultStr + udid + " - " + device + "\n";
      }
     
    },
  },
}
</script>
<template>

<div class="background">
  <el-dialog
  title="恭喜成功"
  :visible.sync="showKefu"
  width="350px"
  :before-close="handleClose"
  center>
<img class="kefuwx" src="../assets/wx.jpg" />
<span> <br>QQ群：763789550<br>Telegram: https://t.me/+VGGU8RYVqDo1NTg1</span>
</el-dialog>
  <div class="flex algin">
    <div class="app-title">{{ isGuest  ? '绑定账号免费送5币' : '账号注册' }}
  </div>
  </div>
<div class="center-container3">
<el-form>
      <label style="color:red"><br>目前仅支持 qq，163，126，gmail，icloud，foxmail，sina，me，aliyun 后缀邮箱<br>（无法注册，可联系客服进行解决）</label>
  <div class="container">
      <el-form-item label="">
      <el-input placeholder="请输入邮箱 (用于接收通知，输错将会导致无法收到通知)" v-model="email"></el-input>
      </el-form-item>

      <el-form-item label="">
      <el-input placeholder="请输入密码" show-password v-model="password"></el-input>
      </el-form-item>
      <el-form-item label="">
      </el-form-item>
      <el-form-item label="">
        <div class="captcha2">
            <el-input placeholder="请输入手机号" v-model="phoneNumber"></el-input>
            <el-button  @click="getCode2">{{ code2 }}</el-button>
        </div>

      <el-form-item label="">
      </el-form-item>
      <el-form-item label="">
          <el-input placeholder="手机号验证码" v-model="captcha2"></el-input>
      </el-form-item>
      <div class="register-button">
        <el-button size="medium" @click="onLogin()">{{ isGuest  ? '返回首页' : '返回登录' }}</el-button>
        <el-button size="medium" type="danger" @click="onRegister()">{{ isGuest ? '立即绑定' : '立即注册' }}</el-button>
      </div>

        </el-form-item>
     
  </div>
</el-form>
</div>
</div>

</template>

<style>

  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .captcha {
    display:flex;
  }

  .captcha2 {
    display:flex;
  }


  .register-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import {postCaptcha,postPhoneCaptcha, postRegister, postBind} from '@/api/login'
import NodeRSA from 'node-rsa';

import {validEmail} from '@/utils/util'

import {Alert, Message} from 'element-ui'

import { JSEncrypt } from 'jsencrypt'

export default {
  data() {
    return {
      invite_code:'',
      email: '',
      phoneNumber:'',
      password: '',
      confirmPassword: '',
      captcha: '',
      captcha2:'',
      code: '获取 - 邮箱验证码',
      code2:'获取 - 手机验证码',
      timer:null,
      timer2:null,
      showKefu:false,
      isGuest:false
    }
  },

  methods: {
    clickqq () {
      alert('请加 qq 群: 763789550')
    },

    isEmailContain(email, email_input){
      let val = email.indexOf(email_input)==-1?false:true
      return val;
    },

    async getCode(){
      if (this.timer != null) {
          return
      }
      var email = this.email
      if (email === '') {
        Message({
          message: "请输入邮箱！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      if (validEmail(email) == false) {
          Message({
            message: "请输入有效的邮箱",
            type: 'error',
            duration: 2 * 1000
            })
            return
      }
      
      if (this.isEmailContain(email,"qq.com")|| this.isEmailContain(email,"163.com") || this.isEmailContain(email,"vip.qq.com") || this.isEmailContain(email,"126.com") || this.isEmailContain(email,"gmail.com") || this.isEmailContain(email,"icloud.com") || this.isEmailContain(email,"foxmail.com") || this.isEmailContain(email,"sina.com") || this.isEmailContain(email,"me.com") || this.isEmailContain(email,"aliyun.com")){
           var data = {
        email: email,
      }
        postCaptcha(data)
       
         Message({
          message: "国外邮箱，或者冷门邮箱，可能会收不到验证码，可尝试更换注册邮箱，或联系客服",
          type: 'warning',
          duration: 4 * 1000
        })

        const TIME_COUNT = 180;
        this.code = TIME_COUNT;

        this.timer = setInterval(() => {
          if (this.code > 1 && this.code <= TIME_COUNT) {
            this.code--;
          } else {
            this.code= "获取 - 邮箱验证码"
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)

      }else{
         Message({
          message: "请输入符合要求的邮箱！",
          type: 'error',
          duration: 2 * 1000
        }) 
      }
    },

    encryptPwd(pwd){
      var publicKeyV2 = `
      -----BEGIN PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDfw1/P15GQzGGYvNwVmXIGGxea
      8Pb2wJcF7ZW7tmFdLSjOItn9kvUsbQgS5yxx+f2sAv1ocxbPTsFdRc6yUTJdeQol
      DOkEzNP0B8XKm+Lxy4giwwR5LJQTANkqe4w/d9u129bRhTu/SUzSUIr65zZ/s6TU
      GQD6QzKY1Y8xS+FoQQIDAQAB
      -----END PUBLIC KEY-----
      `
      let encryptor = new JSEncrypt()
      encryptor.setPublicKey(publicKeyV2)
      return encryptor.encrypt(pwd)
  },

    async getCode2(){
      if (this.timer2 != null) {
          return
      }

        if (this.phoneNumber === '') {
          Message({
            message: "请输入手机号！",
            type: 'error',
            duration: 2 * 1000
          })
          return
        }
        var number = this.encryptPwd(this.phoneNumber);

        console.log(number);


        var data = {
          phone: number,
        }

       var data = await postPhoneCaptcha(data)

        if (data.error != null){
          return;
        }

        const TIME_COUNT = 180;
        this.code2 = TIME_COUNT;
        this.timer2 = setInterval(() => {
          if (this.code2 > 1 && this.code2 <= TIME_COUNT) {
            this.code2--;
          } else {
            this.code2= "获取 - 手机验证码"
            clearInterval(this.timer2);
            this.timer2 = null;
          }
        }, 1000)
    },

    async onLogin() {
       if(this.isGuest){
        this.$router.push({
          path: `/`,
        })
       }else{
        this.$router.push({
          path: `/login`,
        })
       }
    },

   
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    handleClose(done) {
      this.$router.push({
          path: `/login`,
      })
    },


    async onRegister(){
      if (this.email === '') {
        Message({
          message: "请输入邮箱！",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }
      if (validEmail(this.email) == false) {
        Message({
          message: "请输入有效的邮箱",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      if (this.password === '') {
        Message({
          message: "请输入密码",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      if (this.phoneNumber === '') {
        Message({
          message: "请输入手机号",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }

      if (this.captcha2 === '') {
        Message({
          message: "请输入手机号验证码",
          type: 'error',
          duration: 2 * 1000
        })
        return
      }
        this.$confirm('您输入的接收通知邮箱为：' + this.email + " 您输入的密码为：" + this.password + " 是否确认信息无误继续注册", '提示', {
          confirmButtonText: '继续注册',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.registerAccount();
        }).catch(() => {

        });

    },

    async registerAccount(){
        var data = {
            email: this.email,
            phone : this.phoneNumber,
            password: this.password,
            email_captcha: this.captcha,
            phone_captcha: this.captcha2,
            invite_code:this.invite_code, 
          }
          var res;
          if(this.isGuest){
            res = await postBind(data)
          }else{
            res = await postRegister(data)
          }
          
          try {
            if (res.id != undefined){
              this.handleClose();
              
            }
          } catch (e) {
            // 错误处理代码片段
          }
    },
      showAlertKefu(){
        this.showKefu = true;
      },
       getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },
  },
  mounted() {
      this.invite_code = this.getQueryString('invite_code');
      this.isGuest = this.getQueryString('guest');
  },
  watch: {
  },
  name: 'Register'
}
</script>

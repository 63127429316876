/*
 * @Author: zhangdasen zhangdasen@conew.com
 * @Date: 2022-04-14 00:02:01
 */
import request from '@/utils/request'

/// 登录
export function installApp(number, contact_way,cdkey_price_id) {
    return request({
      url: '/install_app/order?number=' + number + "&contact_way=" +contact_way + "&cdkey_price_id=" + cdkey_price_id,
      method: 'get'
    })
}

export function getInstallCode(orderId) {
  return request({
    url: 'install_app/order/' + orderId,
    method: 'get'
  })
}


export function searchInstallCode(udid) {
  return request({
    url: 'install_app/cdkey/udid/' + udid,
    method: 'get'
  })
}

export function searchInstallCode2(contact) {
  return request({
    url: 'install_app/cdkey/contact/' + contact,
    method: 'get'
  })
}

export function deleteCoin(fileId) {
  var data = {
    "id":fileId,
    "expense_type":"sign_ipa"
  }
  return request({
    url: '/dispense/expense',
    method: 'post',
    data:data
  })

}



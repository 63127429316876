<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">APP 搜索排行
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

<div class="center-containerDown-rank">
<div class="table_content_down_rank">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      prop="下标"
      type="index"
      width="50">
    </el-table-column>

    <el-table-column
     prop="name"
      label="名称"
      align="center">
    </el-table-column>
    <el-table-column
      prop="trackId"
      label="获取 IPA"
      align="center">
     <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.trackId, scope.row.name, scope.row.bundle_id, scope.row.version)" type="success" icon="el-icon-s-custom" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</div>
</div>

</template>

<style>
  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }  
 
  .table_content_down_rank{
    position: relative;
    height: 670px;
  }
  
  .center-containerDown-rank{
    background-color: white;
    width:800px;
    height: 650px;
    padding-left: 50px;
  }

</style>

<script>
import {searchRank} from '@/api/download'

import {searchIpa} from '@/api/download'

export default {
  data() {
      return {
        userEmail : '',
        tableData: null,
        reportBtn:'none',
        searchName:'',
        newVersion:'',
        url:'',
        downloadCount:0,
        loading : true,
      }
    },

  methods: {
    async serarchRankCall() {


      var time = new Date(new Date().toLocaleDateString()).getTime() - 7 * 24 * 3600 * 1000
     var searchData = await searchRank(time / 1000); 


      var filArrs =  searchData.data;
      var newData = new Array();
      for (var i=0; i<filArrs.length; i++){
          var ipaData = filArrs[i];

          var newDic = {};
          newDic["name"] =  ipaData.name;
          newDic["trackId"] = ipaData.ipa_id;
          newDic["bundle_id"] = "bundleID";
          newData.push(newDic); 
      }

      this.tableData = newData;
      this.loading = false;
    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
 async getSearch(requestData) {
      // 拿到请求数据 json
      var data = await searchIpa(requestData);
      return data;
     },
   async invoicesLink(trackID, name, bundleID, version){ 
          var requestData = {
              ipa_id :trackID.toString(),
              name : name.toString(),              
              version: version,
              bundle_id:bundleID
         }
          var filArr = await this.getSearch(requestData);
     
           // D币个数不够
           if (filArr["error"] != undefined){
             this.openPayMsg("当前拥有可D币个数不足 9 个，请进行充值。");
             return;
           }
           
           // 冷门 IPA 需要发邮件
           if (filArr["send_email"] == true){
              this.postIpaRequestMail(["dumpapp@126.com"], name, bundleID, version);
              this.openMsg('您的请求已提交，我们稍后会生成IPA，然后通过邮箱通知您! （1-3小时还没有收到 IPA 包的，可添加网站微信联系客服咨询进度）(节假日服务器维护，ipa 出包比较慢)');
              return;
           }

          // 跳转
          if (filArr["bundle_id"].length != 0){
              this.$router.push({
                  path: `/download?appid=` + trackID,
              })
           }
   }
  },

  mounted() {
    this.serarchRankCall()
  },

}
</script>

<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">增强 IPA - 专区
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>

<div class="center-containerDown_dumplist">
<div class="table_content_down_dumplist">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">

    <el-table-column
     prop="name"
      label="名称"
      align="center">
    </el-table-column>

    <el-table-column
     prop="version"
      label="版本"
      align="center">
    </el-table-column>
    <el-table-column
      prop="doc"
      label="介绍"
      align="center">
     <template slot-scope="scope">
       <el-button @click="invoicesLinkDoc(scope.row.doc)" type="warning" >功能介绍</el-button>
      </template>
    </el-table-column>

    <el-table-column
     prop="trackId"
      label="下载 IPA"
      align="center">
      <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.trackId, scope.row.version)" type="success" icon="el-icon-download" circle></el-button>
      </template>
    </el-table-column>


  </el-table>
</div>
</div>
</div>

</template>

<style>
  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }  
 
  .table_content_down2{
    position: relative;
    height: 670px;
  }
  

</style>

<script>
import {getIpaTypeList} from '@/api/member'
import {searchIpa} from '@/api/download'

import {getSelf} from '@/api/member'
import {downloadCrackIPA} from '@/api/download'
import {checkCrackCanDownload} from '@/api/download' 
export default {
  data() {
      return {
        userEmail : '',
        tableData: null,
        reportBtn:'none',
        searchName:'',
        newVersion:'',
        url:'',
        downloadCount:0,
        loading : true,
      }
    },

  methods: {
    async serarchCrackIpa() {
        var searchData = await getIpaTypeList('crack');

        var filArrs =  searchData.data;
        var newData = new Array();

        for (var i=0; i<filArrs.length; i++){
          var ipaData = filArrs[i];
          for (var j=0; j<ipaData.versions.length; j++){
            var versionData = ipaData.versions[j];
            var newDic = {};
            newDic["name"] =  versionData.describe;
            newDic["trackId"] = ipaData.id;
            newDic["doc"] = versionData.describe_url;
            newDic["version"] = versionData.version;
            newData.push(newDic); 
          }
        }

        this.tableData = newData;
        this.loading = false;
    },

    

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },

    async getSearch(requestData) {
      // 拿到请求数据 json
      var data = await searchIpa(requestData);
      return data;
     },


      openPayMsg(vip_str) {
        var confirmStr = "确认";
        confirmStr =  '进行充值';
         this.$confirm( '<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          dangerouslyUseHTMLString : true
        }).then(() => {
         this.routerPay(); 
        }).catch(() => {        
        });
      },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },

    openAlertMsg(data, vip_str,version, ipaID) {
        var confirmStr = "确认";
         this.$confirm(data  +  '<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
              this.requestPay(version, ipaID);
        }).catch(() => {        
        });
      },

    async requestPay(version, ipaID){
          var fileURL =  await downloadCrackIPA(ipaID, version);
          if (fileURL.open_url){
            window.location.href = fileURL.open_url;
          }else{
             this.openPayMsg("当前拥有可D币个数不足 9 个，请进行充值。");
          }
    },

   async invoicesLinkDoc(doc){
      window.open(doc);
   },

    async invoicesLink(ipaID, version){ 
      var data = await checkCrackCanDownload(ipaID, version); 
      if (data){
        if (data["can_download"] == true){
            this.requestPay(version, ipaID); 
        }else{
            var userData = await getSelf();
            var downloadCount = userData.pay_count;
            if (downloadCount < 1){
              this.openPayMsg("当前拥有可D币个数不足 9 个，请进行充值。"); 
            }else{
              this.openAlertMsg("","花费 9 D币个数，进行下载 (有任何异常问题，可联系微信返还D币个数)", version, ipaID);
            }
        }
      }
    }
  },

  mounted() {
    this.serarchCrackIpa()
  },

}
</script>

import request from '@/utils/request'

// 获取123云盘配置信息
function getPan123Token () {
    return request({
      url: '/pan_123/temp_secret?suffix=rbt',
      method: 'get'
    })
}

function getThemeList (offset) {
  return request({
    url: '/wx_theme/themes?limit=10&offset='+offset,
    method: 'get'
  })
}

function postTheme (data) {
  return request({
    url: '/wx_theme/theme',
    method: 'post',
    data:data
  })
}

function editTheme (themeId,data) {
  return request({
    url: '/wx_theme/theme/' + themeId ,
    method: 'put',
    data:data
  })
}

function editThemeVersion (themeId,data) {
  return request({
    url: '/wx_theme/theme/version/' + themeId ,
    method: 'put',
    data:data
  })
}

function deleteThemeVersion(themeId) {
  var data = {
    ids:[themeId]
  }
  return request({
    url: '/wx_theme/theme/version',
    method: 'delete',
    data:data
  })
}

function deleteTheme(themeId) {
  var data = {
    ids:[themeId]
  }
  return request({
    url: '/wx_theme/theme',
    method: 'delete',
    data:data
  })
}

function getCodeList (offset) {
  return request({
    url: '/wx_theme/theme/cdkeys?limit=20&offset='+offset,
    method: 'get'
  })
}

function deleteCode(id) {
  var data = {
    ids:[id]
  }
  return request({
    url: '/wx_theme/theme/cdkey',
    method: 'delete',
    data:data
  })
}

function createCode(data) {
  return request({
    url: '/wx_theme/theme/cdkey',
    method: 'post',
    data:data
  })
}

function useCdkeyApi(data) {
  return request({
    url: '/wx_theme/theme/cdkey/expense',
    method: 'post',
    data:data
  })
}

// 合并成一个对象  
const ThemeAPI = {  
  getPan123Token,  
  getThemeList,  
  postTheme,
  editTheme,
  editThemeVersion,
  deleteTheme,
  deleteThemeVersion,
  getCodeList,
  deleteCode,
  createCode,
  useCdkeyApi
};

export default ThemeAPI;  
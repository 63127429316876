/*
 * @Author: your name
 * @Date: 2021-12-05 16:21:10
 * @LastEditTime: 2022-08-19 17:39:03
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/utils/apple_request.js
 */
import axios from 'axios'
import {Loading, Message} from 'element-ui'
import Vue from "vue";
// create an axios instance
const service = axios.create({
    baseURL: 'https://itunes.apple.com',
    timeout: 10000,
    withCredentials:false,
    crossDomain:true,
})

let loading
let isLoginUrl

// request interceptor
service.interceptors.request.use(
    config => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers['Access'] = '11111111111111111111111';
        loading = Loading.service({
            body: true
        })
        return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  // response interceptor
  service.interceptors.response.use(
    response => {
      loading.close()
      if (response.status === 200 && isLoginUrl) {
        window.location.href = `${window.location.origin}`
      }


      if (typeof response.data === 'string') {
          console.log(response.headers)
        Message({
          message: response.data,
          type: 'success',
          duration: 1.5 * 1000,
          offset:document.body.clientHeight * 0.5 - 100
        })
      }
      return response.data
    },
    error => {
      loading.close()
      let message
      
      if (error.response) {

        switch (error.response.status) {
          case 403:
            Message({
              message: '请登录账号！',
              type: 'error',
              duration: 1.5 * 1000,
              offset:document.body.clientHeight * 0.5 - 100
            })
            return;
        }

        if (typeof error.response.data === 'string') {
          message = error.response.data
        } else if (typeof error.response.data === 'object') {
          message = error.response.data.message || error.response.data.error.message
        }
      } else {
        message = error.message
      }
      message = message || '服务器端错误'
      Message({
        message: message,
        type: 'error',
        duration: 1.5 * 1000,
        offset:document.body.clientHeight * 0.5 - 100
      })
      return Promise.reject(error)
    }
  )

export default service

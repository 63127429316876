<!--
 * @Author: zhangdasen zhangdasen@conew.com
 * @Date: 2022-05-08 22:50:57
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @LastEditTime: 2022-08-10 21:15:35
 * @FilePath: /smash_web/src/views/searchCode.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">查询兑换码
    </div>
  </div>

       <el-button id="app_back_btn" @click="onBack" class="app_back_btn">返回兑换页</el-button>
<div class="center-container-cer">
<el-form>
  <div class="container">
      <input id="showText" class="showText" name="" placeholder="请输入设备UDID或当时的联系方式" v-model="udid"/>
      <div class="login-button">
        <el-button type="danger" size="medium" @click="onGetUdid()">查询兑换码
        </el-button>
        <el-button type="success" size="medium"
        v-show="isShow"
        v-clipboard:copy="firstCode"
        v-clipboard:success="onSaveUdid" 
        v-clipboard:error="onError">复制兑换码
        </el-button>
      </div>

      <template>
  <el-table
   v-loading="loading"
    :data="tableData"
    stripe
    style="width: 100%">
    <el-table-column
      prop="code"
      label="兑换码"
      width="180">
    </el-table-column>

  <el-table-column
  prop="createTime"
   label="创建时间"
   align="center">
 </el-table-column>

  </el-table>
</template>
  </div>
</el-form>
</div>
</div>
</template>

<style>
  .select-cer{
    width:60%;
  }
  .showText{
    font-size: 18px;
    width: 100%;
    padding:0;
    height:40px;
  }
  
  .login-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import {searchInstallCode} from '@/api/install'
import { Alert } from 'element-ui'
import {searchInstallCode2} from '@/api/install'

export default {
  data() {
    return {
      udid: '',
      tableData: null,
      loading:false,
      firstCode:"",
      isShow:false
    }
  },

  methods: {


    onError(e) {
        this.$message({
          type: 'error',
          message: '复制失败！'
        }); 
      },

    async onSaveUdid(){
        this.$message({
          type: 'success',
          message: '复制成功！'
        }); 
    },


    formatDate(nS) {     
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
    },


    async onGetUdid(){
        if (this.udid.length  == 40 || this.udid.length == 25){
          this.loading = true;
          var userData = await searchInstallCode(this.udid);
          this.loading = false;
          if (userData != null){
            var searchArray = userData.cd_keys; 
            var newData = new Array();
            for (var i=0; i<searchArray.length; i++){
              this.isShow = true;
                  var ipaData = searchArray[i];
                  var newDic = {};
                  newDic["code"] = ipaData.out_id;
                  newDic["createTime"] = this.formatDate(ipaData.created_at);
                  newData.push(newDic);
                  this.firstCode = ipaData.out_id;
            }
            this.tableData = newData;
          }else{
            alert("网络请求失败，联系客服或重试"); 
          }
        }else{
          this.loading = true;
          var userData = await searchInstallCode2(this.udid);
          this.loading = false;
          if (userData != null){
            var searchArray = userData.cd_keys; 
            var newData = new Array();
            for (var i=0; i<searchArray.length; i++){
                  this.isShow = true;
                  var ipaData = searchArray[i];
                  var newDic = {};
                  newDic["code"] = ipaData.out_id;
                
                  newDic["createTime"] = this.formatDate(ipaData.created_at);
                  newData.push(newDic);
                  this.firstCode = ipaData.out_id;
            }
            this.tableData = newData;
          }else{
            alert("网络请求失败，联系客服或重试"); 
          }
        }
    },

    onBack(){
      window.location.href = "https://install.dumpapp.com/home";
    },
  },

  mounted() {
    
  },

}
</script>

/**
 * 设置cookie
 * cname cookie的名称
 * cvalue cookie的值
 * exdays cookie的过期时间
 */
function setCookie(cname, cvalue, exdays) {
    let date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + date.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
//获取cookie
function getCookie(cname) {
    if (document.cookie.length>0){
        let start=document.cookie.indexOf(cname + "=")
        if (start!=-1){
            start=start + cname.length+1
            let end=document.cookie.indexOf(";",start)
            if (end==-1) end=document.cookie.length
            return unescape(document.cookie.substring(start,end))
        }
    }
    return ""
}
//删除cookie
function delCookie(cname) {
    setCookie(cname, "", -1)
}
export default {
    setCookie,
    getCookie,
    delCookie
}

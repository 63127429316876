<template>

<div class="background">

  <div class="flex algin">

    <div id="app-title" class="app-title">iOS 开发证书购买
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回上级</el-button>
  </div>

<div class="center-container-cer">
<el-form>
  <div class="cerTitle">{{buy_cer_notice}}</div>
  <a><br><br>{{showPrice1}}<br></a>
  <a>{{showPrice2}}<br></a>
  <a>{{showPrice3}}</a>
  <div class="container-cer">
    <el-button type="warning" size="medium" style="margin-top:10px" @click="successDelegate()">更多优惠
    </el-button>

    <el-button type="danger" size="medium" style="margin-top:10px" @click="checkCer()">检测证书
    </el-button>
    <el-row>
      <div class="bg-purple-cer">
      <div class="content-cer">
        <el-radio v-model="radio" :label="2" >{{this.title2}}
          <div class="payValue-code">￥{{this.price2}}.00</div>
        </el-radio>
        <el-radio v-model="radio" :label="1" >{{this.title3}}
          <div class="payValue-code">￥{{this.price3}}.00</div>
        </el-radio>
      </div>
      </div>
        </el-row>
        <el-input style="width:60%;margin-top:40px" v-model="input_udid" placeholder="请输入UDID"></el-input>
        <el-input style="width:60%;margin-top:10px" v-model="note" placeholder="请输入备注（可留空）"></el-input>
      
        <div class="login-button">
        <el-button type="warning" size="medium" @click="reloadPage2()">获取 UDID
        </el-button>
        <el-button type="danger" size="medium" @click="onBuy()">购买证书
        </el-button>
          <el-button type="primary" size="medium" @click="onShowBuy()">查看证书
        </el-button>
      </div>
  </div>
</el-form>
</div>
<el-dialog class="toast_qrcode"
  title="请扫码二维码，使用 默认浏览器 打开网址，获取 UDID."
  :visible.sync="dialogVisible2"
  width="350px">
  <div id="qrcode" class="qrcode" ref="qrcodeContainer"></div> 
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2=false">取 消</el-button>
    <el-button type="primary" @click="reloadPage">已获取，返回填写</el-button>
  </span>
</el-dialog>

<el-dialog class="toast_qrcode"
  title="请手动输入证书设备的 UDID"
  :visible.sync="dialogVisible1"
  width="350px">
  <el-input
  type="textarea"
  :rows="2"
  placeholder="请输入设备UDID （多个UDID，每行一个）"
  v-model="textarea">
</el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible1 = false">取 消</el-button>
    <el-button type="primary" @click="reloadPage3">确认添加</el-button>
  </span>
</el-dialog>


<el-dialog class="toast_qrcode"
  title="请选择添加 UDID 方式"
  :visible.sync="dialogVisible"
  width="350px">
  <el-button type="primary" @click="reloadPage1"> 手动添加 UDID</el-button>
    <el-button type="primary" @click="reloadPage2"> 扫码添加 UDID</el-button>
</el-dialog>

</div>
</template>

<style>
  .select-cer1{
    width:40%;
    margin-top: 30px;
  }

  .select-cer2{
    width:30%;
  }

  .cerTitle{
    top: 20px; 
    widows: 10%;
    position: relative;
    color: green;
  }

  
  .container-cer{
    /* display: flex; */
    /* flex-direction: row; */
    /* padding: 45px; */
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  } 
  .bg-purple-cer {
    height:100px;
    background: #dceff7;
    /* min-width: 210px; */
    margin-left: 20px;
    margin-right: 20px;
    /* width: 90%; */
  }


.content-cer{
  display:flex;
  justify-content:center;
  align-items:center;
}
</style>

<script>

import {getSelf} from '@/api/member'
import {Loading, Message} from 'element-ui'
import QRCode from 'qrcodejs2'
import {getSelfDevice} from '@/api/member'
import {getQrCode} from '@/api/download'
import {certificate} from '@/api/download'
import {getPrice} from '@/api/member'
import {addUdid} from '@/api/member'

export default {
  data() {
    return {
      textarea:"",
      name: "qrCode",
      email: '',
      password: '',
       dialogVisible: false,
       dialogVisible1: false,
       dialogVisible2: false,
       fits: ['fill'],
       url: '',
       options: [],
       value: '',
       loading :null,
       loading_edit: true,
       userData : null,
       mobUrl:null,
       priceData:[],
       price1:"",
       price2:"",
       price3:"",
       title1:"",
       title2:"",
       title3:"",
       showPrice1:"",
       showPrice2:"",
       showPrice3:"",
       centerDialogVisible:true,
       options2: [],
       value2: '',
       radio:2,
       input_udid:'',
       is_buying : false,
       note:"",
       buy_cer_notice:""
    }
  },

  methods: {
    reloadPage(){
      window.location.reload();
    },

    reloadPage1(){
      this.dialogVisible = false;
      this.dialogVisible1 = true;
    },

    checkCer(){
      window.open('https://check.dumpapp.com');
    },

    reloadPage2(){
      var tThis = this;
      setTimeout(function() {
          tThis.creatQrCode();
      }, 200);

      this.dialogVisible = false;
      if (this.isMobile()){
        this.onGetUdid();
        return;
      }else{
        this.dialogVisible2 = true;
      }
    },

    async reloadPage3(){
      var udids = this.textarea.replace(/ /g,'');
      var snsArr=udids.split(/[(\r\n)\r\n]+/);
      snsArr.forEach((item,index)=>{
            if(!item){
                snsArr.splice(index,1);
            }
      })

      var continueRun = true;
      for (var i=0; i<snsArr.length; i++){
        var udid = snsArr[i];
        var requestData = {
            udid: udid,
        }

        if (udid.length  == 40 || udid.length == 25){
          var data = await addUdid(requestData);
        }else{
          alert("udid 格式不对，请检查格式 : ", udid);
          continueRun = false;
          break;
        }
      }

      if(continueRun){
        this.dialogVisible1 = false;
        this.refreshData();
      }

    },

    clickqq () {
      alert('请加 qq 群: 763789550')
    },

    async onRegister() {
        this.$router.push({
          path: `/register`,
        })
    },

    successDelegate(){
      window.open("https://dumpappdoc.feishu.cn/docx/PHbadYcJToYvLBxfshMcs0qznqd")
    },

    async onBack() {
        this.$router.push({
          path: `/cerBuy`,
        })
    },
    creatQrCode() {
      new QRCode(this.$refs.qrcodeContainer, {  
          text: 'https://www.dumpapp.com/view_udid?code=' + this.code,  
          width: 200,  
          height: 200,  
          colorDark: '#000000',  
          colorLight: '#ffffff',  
          correctLevel: QRCode.CorrectLevel.H  
      });
    },

    isMobile() {
      // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // alert(isMobile);

      if ("ontouchstart" in document.documentElement) {
        return true;
      } else {
        return false;
      }

      
      return isMobile;
    },

    async onBuy(){
          var udid = this.input_udid;
          udid = udid.replace(/\s+/g,"");
          if (!udid.length){
            this.openMsg("UDID 为空，请先点击 - 添加UDID");
            return;
          }

        if (udid.length  != 40 && udid.length != 25){
          alert("udid 格式不对，请检查格式 : " + udid);
          return;
        }

         this.$confirm("是否是为 " + udid + " 设备进行购买证书", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var downloadCount = this.userData.pay_count;
          var index = this.priceData[parseInt(this.radio) - 1].price;
          var coin = parseInt(index);
          if (downloadCount < coin){
              this.openPayMsg("当前拥有可D币个数不足 (" + coin + "）个，请进行充值（充值活动优惠中）"); 
          }else{
              this.openAlertMsg("","花费 （" + coin + "） D币个数，进行购买证书 （充值活动优惠中）");
          }
        }).catch(() => {
             
        });
    },

    convertDeviceName(devName){
        if (devName == "iPhone5,1")  return "iPhone 5";
        if (devName == "iPhone5,2")  return "iPhone 5";
        if (devName == "iPhone6,1")  return "iPhone 5s";
        if (devName == "iPhone6,2")  return "iPhone 5s";
        if (devName == "iPhone7,2")  return "iPhone 6";
        if (devName == "iPhone7,1")  return "iPhone 6 Plus";
        if (devName == "iPhone8,1")  return "iPhone 6s";
        if (devName == "iPhone8,2")  return "iPhone 6s Plus";
        if (devName == "iPhone8,4")  return "iPhone SE";
        if (devName == "iPhone9,1")  return "iPhone 7";
        if (devName == "iPhone9,3")  return "iPhone 7";
        if (devName == "iPhone9,2")  return "iPhone 7 Plus";
        if (devName == "iPhone9,4")  return "iPhone 7 Plus";
        if (devName == "iPhone10,1")  return "iPhone 8";
        if (devName == "iPhone10,4")  return "iPhone 8";
        if (devName == "iPhone10,2")  return "iPhone 8 Plus";
        if (devName == "iPhone10,5")  return "iPhone 8 Plus";
        if (devName == "iPhone10,3")  return "iPhone X";
        if (devName == "iPhone10,6")  return "iPhone X";
        if (devName == "iPhone11,2")  return "iPhone XS";
        if (devName == "iPhone11,4")  return "iPhone XS Max";
        if (devName == "iPhone11,6")  return "iPhone XS Max";
        if (devName == "iPhone11,8")  return "iPhone XR";
        if (devName == "iPhone12,1")  return "iPhone 11";
        if (devName == "iPhone12,3")  return "iPhone 11 Pro";
        if (devName == "iPhone12,5")  return "iPhone 11 Pro Max";
        if (devName == "iPhone12,8")  return "iPhone SE";
        if (devName == "iPhone13,1")  return "iPhone 12 Mini";
        if (devName == "iPhone13,2")  return "iPhone 12";
        if (devName == "iPhone13,3")  return "iPhone 12 Pro";
        if (devName == "iPhone13,4")  return "iPhone 12 Pro Max";
        if (devName == "iPhone14,5")  return "iPhone 13";
        if (devName == "iPhone14,2")  return "iPhone 13 Pro";
        if (devName == "iPhone14,3")  return "iPhone 13 Pro Max";
        if (devName == "iPhone14,4")  return "iPhone 13 Mini";


        if (devName == "iPhone14,6")  return "iPhone SE3";
        if (devName == "iPhone14,7")  return "iPhone 14";
        if (devName == "iPhone14,8")  return "iPhone 14 Plus";

        if (devName == "iPhone15,2")  return "iPhone 14 Pro";
        if (devName == "iPhone15,3")  return "iPhone 14 Pro Max";
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },
    onShowBuy(){
       this.$router.push({
            path: `/cerPage`,
      })
    },  
      openPayMsg(vip_str) {
        var confirmStr = "确认";
        confirmStr =  '进行充值';
         this.$confirm( '<strong>'  + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
         this.routerPay(); 
        }).catch(() => {        
        });
      },
  
     openAlertMsg(data, vip_str) {
        var confirmStr = "确认"; 
         this.$confirm(data  +  '<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
          this.buyCer();
        }).catch(() => {        
        });
      },

     openMsg(vip_str) {
        var confirmStr = "确认";
         this.$confirm('<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          dangerouslyUseHTMLString : true
        }).then(() => {
        }).catch(() => {        
        });
      },


     async buyCer(){
        var udid = this.input_udid;
        udid = udid.replace(/\s+/g,"");
        if (!udid.length){
            this.openMsg("请先添加 UDID")
          return;
        }

          // 检测是否购买中
          if(this.is_buying){
            Message({
              message: "正在购买中，请等待。。。。",
              type: 'success',
              duration: 1.5 * 1000,
              offset:document.body.clientHeight * 0.5 - 100
            })
            return;
          }
         
          this.is_buying = true;
          this.showLoading();
          this.is_buying = false;

          var data = {
            udid: udid,
            note:this.note,
            type: this.radio,
            is_support_m1:true
          }

          var result = await certificate(data);
          this.loading.close();

          // error 不为空都是错误，为空都是正确。
          if(result.error != null){
            if (result.error.code == 30003){
              this.openMsg("证书购买失败，联系客服确认问题。");
            } 
            if (result.error.code == 30002){
              this.openPayMsg("当前拥有D币个数不足，请进行充值（充值活动优惠中）"); 
            }  
          }else{
             this.$router.push({
                  path: `/cerPage`,
              })
          }
      },

    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },

    handleClose(done){
      this.dialogVisible = false;
      this.refreshData();
    },

    async requestQrCode() {
      var data = await getQrCode();
      this.mobUrl = data.url; 
      var arr = this.mobUrl.split("?code=");
      this.code = arr[1];
    },

    async showQrCode(){

      const codeHtml = document.getElementById("qrcode");
      if  (codeHtml != null){
        codeHtml.innerHTML = "";
      }

      this.dialogVisible = true;
    },
    
    async onGetUdid(){
        if (navigator.userAgent.indexOf("MicroMessenger") !== -1) {
            alert("请点击右上角按钮，在弹出的菜单中，选择 在Safari(默认浏览器)中打开!");
            return false;
        }

      window.location.href = this.mobUrl;
       setTimeout(function() {
          window.location.href = 'https://www.dumpapp.com/TZ.mobileprovision' 
        }, 2300);
    },


    async setPrice(){
        var responseInfo = await getPrice();
        this.priceData = responseInfo.data;
      var newData = [];
      for (var i=0; i<this.priceData.length; i++){
        var newDic = {};
        var data = this.priceData[i];
        newDic["value2"] = (i + 1).toString();
        newDic["label"] = "《" + data.title + "》 - " + data.description + " ------ 价格：" + data.price;
        newData.push(newDic);

        if(i == 0){
          this.price1 = data.price;
          this.showPrice1 =  newDic["label"]; 
          this.title1 = data.title; 
        }
        if (i == 1){
          this.price2 = data.price;
          this.title2 = data.title;
          
          this.showPrice2 =  newDic["label"];  
        }
        if (i == 2){
          this.price3 = data.price;
          this.title3 = data.title; 
          this.showPrice3 =  newDic["label"]; 
        }
      }
      this.value2 = '1';
      this.options2 = newData;
    },

    async getMemberSelf(){
      // 拿到请求数据 json
      var userData = await getSelfDevice();

      if (userData == undefined){
         alert("请先进行登录");
         this.$router.push({
          path: `/login`,
         })
        return;
      }

      this.userData = userData;

      var newData = [];

      this.loading_edit = false
      if (userData.data == null){
        return;
      }

      for (var i=0; i<userData.data.length; i++){
        var newDic = {};
        var data = userData.data[i];
        newDic["value"] = i;

        var product = this.convertDeviceName(data.product);
        if (product == undefined){
          product = "";
          newDic["label"] = data.udid + product;
        }else{
          newDic["label"] = data.udid + " --- " + product;
        }

        newDic["udid"] = data.udid;
        newData.push(newDic);
      }

      this.options = newData;
      if (userData.data.length){
        this.value = 0;
      }
      var data = await getSelf()

      this.buy_cer_notice = data.pay_campaign.buy_cer_notice;

      if (data.role == "agent"){
        document.getElementById("app-title").innerHTML="此页面证书支持M1电脑（不支持推送）"
      }
    },

    refreshData(){
      this.requestQrCode();
      this.getMemberSelf();
    }



  },

  mounted() {
    this.refreshData();
    this.centerDialogVisible = true;
    this.setPrice();

  },

  watch: {
  },
  name: 'Login'
}
</script>

<template>
  <div class="background">
    <div class="flex align">
      <div id="app-title" class="app-title">DumpAPP - 活动奖励页面</div>
      <el-button id="app_back_btn" @click="onBack" class="app_back_btn">返回首页</el-button>
    </div>

    <div class="center-containerDown_dumplist">
      <div>
    <iframe src="https://www.dumpapp.com/activity" width="100%" height="300px"></iframe>
  </div>
  <hr />
      <div class="table_content_dumplist">
        <el-table v-loading="loading" :data="tableData" style="width: 100%" max-height="330px">
          <el-table-column prop="date" label="时间" align="center"></el-table-column>
          <el-table-column prop="description" label="内容" align="center"></el-table-column>
          <el-table-column prop="coinadd" label="领取状态" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.is_condition_met"
                :disabled="scope.row.is_reward_collected"
                :style="scope.row.is_reward_collected ? 'background-color: #d3d3d3; opacity: 0.6; color: #000;' : 'background-color: #409EFF; opacity: 1.0; color: #fff;'"
                @click="collectReward(scope.row.id)"
              >
                {{ scope.row.is_reward_collected ? '已领取' : '领取' }}
              </el-button>

              <el-button
                v-if="!scope.row.is_condition_met"
                :disabled="true"
                :style="true ? 'background-color: #d3d3d3; opacity: 0.6; color: #000;' : 'background-color: #409EFF; opacity: 1.0; color: #fff;'"
              >
                {{'已失效' }}
              </el-button>

            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

  
  <style>
   .container {
  /* display: flex; */
  flex-direction: row;
  padding: 5px;
}

.login-button {
  height: 75px;
  line-height: 75px;
}

.table_content_dumplist {
  position: relative;
  height: 350px;
}

.center-containerDown_dumplist {
  background-color: white;
  width: 90%;
  min-width: 400px;
}
  
  </style>
  
  <script>
  
import { getActivities, collect} from '@/api/config'
  export default {
    data() {
        return {
          tableData:[],
          loading:true
        }
      },
  
    methods: {
      async getConfig(){
        var appList = await getActivities();
          var filArrs = appList.data;
          var today_cer_count = appList.today_cer_count;

          document.getElementById("app-title").innerHTML= "今日购买证书：" + today_cer_count + " 本";
          var newData = new Array();
          for (var i=0; i<filArrs.length; i++){
          var fil = filArrs[i];
            var newDic2 = {};
            newDic2["id"] = fil.id;
            newDic2["date"] = fil.date;
            newDic2["description"] =fil.description;
            newDic2["is_condition_met"] = fil.is_condition_met;
            newDic2["is_reward_collected"] = fil.is_reward_collected;
            newData.push(newDic2); 
          }
          this.tableData = newData;
          this.loading = false;
      },



      async collectReward(modelId) {
        var appList = await collect(modelId);
        window.location.reload();

      },

      async onBack() {
        this.$router.push({
          path: `/`,
      })
    },
 
    
    },
  
    mounted() {
      this.getConfig();
    },
  
  }
  </script>
  
<template>

<div class="background">
 <div class="flex algin">
    <div class="app-title">返利明细
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>
<div class="center-containerDown_dumplist">
<div class="table_content_dumplist">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      label="序号"
      type="index"
      width="50">
    </el-table-column>

     <el-table-column
     prop="user"
      label="邀请人"
      align="center">
    </el-table-column>
    <el-table-column
     prop="coin"
      label="充值D币"
      align="center">
           <template slot-scope="scope">
          <div :style="{ color: scope.row.isOverText ? 'red' : 'red' }">
            {{ scope.row.coin }}
          </div>
        </template>
    </el-table-column>
    <el-table-column
     prop="coinadd"
      label="返利D币"
      align="center"
      >
       <template slot-scope="scope">
          <div :style="{ color: scope.row.isOverText ? 'red' : 'red' }">
            {{ scope.row.coinadd }}
          </div>
        </template>
    </el-table-column>
     <el-table-column
     prop="time"
      label="时间"
      align="center">
    </el-table-column>
  </el-table>
</div>
</div>
</div>

</template>

<style>
  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_dumplist{
    position: relative;
    height: 450px;
  }
  
  .center-containerDown_dumplist{
    background-color: white;
    width:50%;
    min-width: 300px;
    padding-left: 50px;
  }

</style>

<script>
import {getInvite} from '@/api/member'
import {download_history} from '@/api/download'
import {getSelf} from '@/api/member'

export default {
  data() {
      return {
        email:"",
        tableData: null,
        reportBtn:'none',
        searchName:'',
        newVersion:'',
        url:'',
        loading : true,
        arrayIPA : [],
        showNormal :true,
        showDoc: false,
      }
    },

  methods: {

   async searchHistory(){
     var appList = await getInvite();
      var filArrs = appList.data;
      var newData = new Array();

      for (var i=0; i<filArrs.length; i++){
       var fil = filArrs[i];

        var ipaData = fil.member;
        var newDic2 = {};
        newDic2["coin"] = fil.order_count + ".00";
        newDic2["coinadd"] ="+" + fil.rebate_count + ".00";
        newDic2["user"] = ipaData.email;
        var tt = ipaData.updated_at;
        newDic2["time"] =  this.formatDate(tt);
        newData.push(newDic2); 
      }
      this.tableData = newData;
      this.loading = false;
   },

     formatDate(timestamp) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
       var Y = date.getFullYear() + '.';
       var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth() + 1) : date.getMonth()+1) + '.';
       var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
       return Y + M + D;
    },
    
    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },



  
  },

  mounted() {
     this.searchHistory();
  },

}
</script>

import axios from 'axios';
import { Loading, Message } from "element-ui";
const apiClient = axios.create({
  baseURL: 'https://open-api.123pan.com', // 基础URL，替换为实际的API端点
  headers: {
    'Content-Type': 'application/json',
    'platform':'open_platform'
  },
});

let loading;

// 设置请求拦截器，如果需要添加认证信息或其他全局头部信息，可以在这里设置
apiClient.interceptors.request.use(
  (config) => {
    loading = Loading.service({
        body: true,
        lock: true,
        text: "拼命加载中......",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



// response interceptor
apiClient.interceptors.response.use(
    (response) => {
      loading.close();
      if (typeof response.data === "string") {
        console.log(response.headers);
        Message({
          message: "success",
          type: "success",
          duration: 1.5 * 1000,
          offset: document.body.clientHeight * 0.5 - 100,
        });
      }
      return response.data;
    },
  
    (error) => {
      loading.close();
      let message;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            console.log("error object1");
            if (typeof error.response.data === "string") {
              message = error.response.data;
            } else if (typeof error.response.data === "object") {
              message =
                error.response.data.message || error.response.data.error.message;
            }
            Message({
              message: message,
              type: "error",
              duration: 1.5 * 1000,
              offset: document.body.clientHeight * 0.5 - 100,
            });
            return;
          case 403:
            console.log("error object2");
  
            console.log("error object3");
  
            if (typeof error.response.data === "string") {
              message = error.response.data;
            } else if (typeof error.response.data === "object") {
              message =
                error.response.data.message || error.response.data.error.message;
            }
  
            message = message || "服务器端错误";
            Message({
              message: message,
              type: "error",
              duration: 1.5 * 1000,
              offset: document.body.clientHeight * 0.5 - 100,
            });
            return error.response.data;
        }
        console.log("error object3");
        if (typeof error.response.data === "string") {
          message = error.response.data;
        } else if (typeof error.response.data === "object") {
          message =
            error.response.data.message || error.response.data.error.message;
        }
      } else {
        message = error.message;
      }
      message = message || "服务器端错误";
      Message({
        message: message,
        type: "error",
        duration: 1.5 * 1000,
        offset: document.body.clientHeight * 0.5 - 100,
      });
  
      return Promise.reject(error);
    }
  );

// export function postData(endpoint, data) {
//   return apiClient.post(endpoint, data)
//     .then(response => response.data)
//     .catch(error => {
//       console.error('API post request error:', error);
//       throw error;
//     });
// }

export default apiClient;
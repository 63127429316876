<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">获取手机设备UDID
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn">返回购买</el-button>
  </div>

<div class="center-container-cer">
<el-form>
  <div class="container">
      <input id="showText" class="showText" name="" placeholder="" v-model="udid"/>
      <div class="login-button">
        <el-button type="danger" size="medium" v-show="isShow" @click="onGetUdid()">立即获取 UDID
        </el-button>
        <el-button type="danger" size="medium" v-show="copyBtnShow" @click="copyUdid()">复制 UDID
        </el-button>
      </div>
  </div>
</el-form>
</div>
</div>
</template>

<style>
  .select-cer{
    width:60%;
  }
  .showText{
    font-size: 18px;
    width: 100%;
    padding:0;
    height:40px;
  }
  
  .login-button{
    height: 75px;
    line-height: 75px;
  }

</style>

<script>

import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import {Loading, Message} from 'element-ui'
import {getSelf} from '@/api/member'
import {getQrCode} from '@/api/download'
import {certificate} from '@/api/download'

export default {
  data() {
    return {
      udid: '点击按钮，获取 UDID',
      password: '',
       dialogVisible: false,
       fits: ['fill'],
       url: '',
       options: [],
       value: '',
       loading :null,
       loading_edit: true,
       userData : null,
       code:"",
       isShow : true,
       copyBtnShow:false
    }
  },

  methods: {

    copyUdid(){

      this.$copyText(this.udid).then(
      (res) => {
      Message({
        message:"UDID 已获取，请去填写UDID",
            type: 'success',
            duration: 1 * 1000,
            offset:document.body.clientHeight * 0.5 - 100
          })
      },
      (err) => {
      }
  );
    },
    async onGetUdid(){
        if (navigator.userAgent.indexOf("MicroMessenger") !== -1) {
            alert("请点击右上角按钮，在弹出的菜单中，选择 在safari(默认浏览器)中打开!");
            return false;
        }

        window.location.href = 'https://www.dumpapp.com/api/device/config/file?code=' + this.code 
        const iOSVersion = getiOSVersion();
        if (iOSVersion) {
          // 执行针对iOS 17及以上版本的操作
         setTimeout(function() {
          alert('请手动进入手机，设置 -- 通用 -- VPN 与设备管理 --- 信任证书');
         }, 2300);

        } else {
          setTimeout(function() {
            window.location.href = 'https://www.dumpapp.com/TZ.mobileprovision' 
          }, 2300);
        }
    },

  getiOSVersion() {
    var userAgent = navigator.userAgent;
    return userAgent.includes('Version/17.');
  },

  getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },

    onBack(){
         this.$router.push({
            path: '/cerBuy'
      })
    },
  },

  mounted() {
    var udidStr = this.getQueryString("udid");
    var exist =  this.getQueryString("exist_email"); 
    this.code = this.getQueryString("code");
    if (udidStr != null){
      this.udid = udidStr;
       alert('UDID 已获取，请复制并填写UDID。');
       this.isShow = false;
       this.copyBtnShow = true;
    }
  },

  watch: {
  },
  name: 'Login'
}
</script>

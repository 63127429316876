<template>

<div class="background">
  <div class="flex algin">
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回上一页</el-button>
  </div>
<div class="center-containerDown_dumplist">
    <el-button type="primary" @click="cleanOldIpa()">清空老IPA版本</el-button>

    <el-button type="success" @click="changeType()">切换IPA类型</el-button>
<div class="table_content_dumplist">
    <el-button id="new_version" :style="{ display: reportBtn}" type="danger" size="medium" @click="newVersionClick">没有找到最新版本</el-button>
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      label="序号"
      type="index"
      width="50">
    </el-table-column>
    <el-table-column
     prop="name"
      label="名称"
      align="center">
    </el-table-column>
    <el-table-column
    v-if="showDoc"
     prop="doc"
      label="教程"
      align="center">
      <template slot-scope="scope">
       <el-button @click="invoicesLinkDoc(scope.row.doc)" type="primary" icon="el-icon-video-camera-solid" circle></el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="trackId"
      label="删除"
      align="center">
     <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.trackId, scope.row.name, scope.row.price)" type="danger" icon="el-icon-delete" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</div>
</div>

</template>

<style>
  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_dumplist{
    position: relative;
    height: 450px;
  }
  
  .center-containerDown_dumplist{
    background-color: white;
    width:50%;
    min-width: 300px;
    padding-left: 50px;
  }

</style>

<script>

import {getIpaTypeList} from '@/api/member'
import {deleteOldIPA} from '@/api/member'
import {deleteAPP} from '@/api/member'
import {getAllAppList} from '@/api/email'
import {getSelf} from '@/api/member'
import {
  postEmail,
  postApp
} from '@/api/email'

export default {
  data() {
      return {
        email:"",
        tableData: null,
        reportBtn:'none',
        searchName:'',
        newVersion:'',
        url:'',
        loading : true,
        arrayIPA : [],
        showNormal :true,
        showDoc: false,
      }
    },

  methods: {
   async getMemberSelf() {
      var data = await getSelf()
    },

    clickqq () {
      alert('请加 qq 群: 763789550')
    },

    setAdminBusy(){

    },

    async onBack() {
        this.$router.push({
          path: `/appmanager`,
        })
    },

     openMsg(data, vip_str) {
        var confirmStr = "确认";

         this.$confirm(data  + '<br>' + '<strong>' + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
            this.routerPay();
        }).catch(() => {        
        });
      },

   async invoicesLinkDoc(doc){
     console.log("doc : " + doc );
      window.open(doc);
   },

   async invoicesLink(trackID, name){
     console.log("showNormal :" + this.showNormal);
      var response = await deleteAPP(trackID, this.showNormal); 

      console.log(response)

       var tThis = this;
         this.tableData.forEach(function (itemI, indexI) {
            if (name === itemI.name) {
              tThis.tableData.splice(indexI, 1)
            }
          });

      console.log("当前 APP 数量 ：" + this.tableData.length);
    },

    newVersionClick(){
      this.openMsg("最新版本 IPA 请求已发送，稍后网站更新后，会通过邮件通知您。");
      this.postEmailFile(["dumpapp@126.com"]);
    },
    changeType(){
      this.showNormal = !this.showNormal;
      this.searchAllIpa();
    },

    cleanOldIpa() {
       var data = {
        ipa_ids: this.arrayIPA,
        ipa_type: "normal",
        is_retain_latest_version: true,
      }
       deleteOldIPA(data);
       alert('清空老版本成功');
    },

     postEmailFile(emails){
      var title = 'DumpApp - IPA 已经有最新版本，需要更新！'
      var data = {
        emails: emails,
        title: title,
        content: "应用名称: " + this.searchName + "<br>" + "应用版本: " + this.newVersion
      }
      postEmail(data);
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },
    
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },

  async searchAllIpa(){
    this.arrayIPA = []
    var showType = 'normal';
    if (!this.showNormal){
      showType = 'crack'
    }

    var appList = await getIpaTypeList(showType);
      var filArrs = appList.data;
      var newData = new Array();
      for (var i=0; i<filArrs.length; i++){
        var ipaData = filArrs[i];

        if (ipaData == null){
          console.log("错误数据 : + " + i +"   " +JSON.stringify(appList.data));
          continue;
        }

        var ipaName = ipaData.name;
        var trackId = ipaData.id;
        var counter = 0;
        var dateStr = "";

        if(ipaData.counter != null){
          counter = ipaData.counter.pay_count;
          dateStr = this.set_time(ipaData.counter.last_download_time);
        }

        var newDic = {};
        this.arrayIPA.push(trackId.toString());
        if ( ipaData.versions[0].describe){
          newDic["name"] = ipaData.versions[0].describe;
        }else{
          newDic["name"] = ipaName;
        }
        newDic["trackId"] = trackId;
        newDic["counter"] = counter;
        newDic["date_str"] = dateStr;
        newDic["doc"] = ipaData.versions[0].describe_url; 

        if (newDic["doc"] != undefined){
          this.showDoc = true;
        }else{
          this.showDoc =false;
        }
        
        if (ipaData.versions != null){
          newData.push(newDic); 
        }
      }

      this.tableData = newData;
      this.loading = false;
  },
  
    set_time(str){
      var n = parseInt(str)*1000;
      var D = new Date(n);
      var year = D.getFullYear();//四位数年份

      var month = D.getMonth()+1;//月份(0-11),0为一月份
      month = month<10?('0'+month):month;

      var day = D.getDate();//月的某一天(1-31)
      day = day<10?('0'+day):day;

      var hours = D.getHours();//小时(0-23)
      hours = hours<10?('0'+hours):hours;

      var minutes = D.getMinutes();//分钟(0-59)
      minutes = minutes<10?('0'+minutes):minutes;
      var now_time = year+'-'+month+'-'+day;
    return now_time;
  }

  },

  mounted() {
     this.searchAllIpa();
  },

}
</script>

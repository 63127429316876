import request from '@/utils/request'


export function GetAppVersionList(offset, limit) {
    return request({
        url: '/admin/app_versions?offset='+offset+'&limit='+limit,
        method: 'get'
    })
}

export function DeleteAppVersionApi(id) {
    return request({
        url: '/admin/app_version/' + id,
        method: 'delete',
    })
}

export function PostAppVersionApi(data) {
    return request({
        url: '/admin/app_version',
        method: 'post',
        data
    })
}


export function PostAppleCookie(cookie) {
    var data = {
        cookie: cookie
    }
    return request({
        url: '/admin/config/apple_cookie',
        method: 'post',
        data
    })
}

export function PostAppleDevice(udidsk) {
    var data = {
        udids: udidsk
    }
    return request({
        url: '/get_device',
        method: 'post',
        data
    })
}


<template>

<div class="background">
  <div class="flex algin">
    <div class="app-title">兑换码页面
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回兑换</el-button>
  </div>

<div class="center-container-install">
    <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
    max-height="430">
    <el-table-column
      fixed
      prop="outId"
      label="兑换码"
      align="center">
    </el-table-column>
     <el-table-column
     prop="outStatus"
      label="状态"
      align="center">
    </el-table-column>
    <el-table-column
     prop="createTime"
      label="创建时间"
      align="center">
    </el-table-column>
      <el-table-column
     prop="goset"
      label="去兑换"
      align="center">
       <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.outId)" type="success" icon="el-icon-s-home" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</div>
</template>

<style>

  .container{
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_down{
    top: 20px;
    position: relative;
  }
  .center-container-install{
    background-color: white;
    width:60%;
    min-width: 340px;
    height:230px;
  }


</style>

<script>
import {getInstallCode} from '@/api/install'

export default {
  data() {
      return {
        tableData: null,
        loading : true,
      }
    },
  methods: {
   async getMemberSelf() {
      var orderId = this.getQueryString("order_id");
      var userData = await getInstallCode(orderId);
      // 判断是否登录成功
      if (userData != null){
        
        var newData = new Array();

        for (var i=0; i<userData.data.length; i++){
          var data = userData.data[i];
          var newDic = {};
          
          var cerStatusStr = "已使用";
          if (data.status == "normal"){
            cerStatusStr = "未使用";
          }
          newDic["outId"] = data.out_id;
          newDic["createTime"] = this.formatDate(data.created_at);
          newDic["outStatus"] = cerStatusStr;
          newData.push(newDic); 
        }

      this.tableData = newData;
      this.loading = false;
      }else{
        alert("网络请求失败");
      }
    },

    onBuyCer(){
   
    },

    async onBack() {
       window.location.href = 'https://install.dumpapp.com/home'
    },
    
   async invoicesLink(outId){
      window.open("https://install.dumpapp.com/home?outId=" + outId);
   },


    formatDate(nS) {     
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
    },

    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },
 
  },

  mounted() {
    this.getMemberSelf();
  },

}
</script>
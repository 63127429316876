<!--
 * @Author: zhangdasen zhangdasen@conew.com
 * @Date: 2022-06-24 01:39:29
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @LastEditTime: 2022-07-10 21:13:18
 * @FilePath: /smash_web/src/views/term.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

<div>
  <div class="title">《Dumpapp服务及隐私协议》</div>
  <br>
  <div class="term_margin">
  <div class="sub-title">您好！欢迎您使用 DumpApp </div>
  <br>
  <div class="sub-title">1.特别提示</div>
  <div class="content">1.1 为了更好地为您提供服务，请您仔细阅读这份协议。本协议是您与本应用就您使用本应用平台等所涉及的全部行为所订立的权利义务规范。您在使用 Dumpapp 时，表明您已完全同意并接受本协议，愿意遵守本协议的各项规则、规范的全部内容，若不同意则可停止使用本应用平台。如您是未成年人，您还应要求您的监护人仔细阅读本协议，并取得他/他们的同意。</div>
  <div class="content">1.2 为提高用户的使用感受和满意度，用户同意本应用将基于用户的操作行为对用户数据进行调查研究和分析，从而进一步优化服务。</div>
  <br>
  <div class="sub-title">2.服务内容</div>
  <div class="content">2.1 Dumpapp 是一个提供纯净 ipa 资源包并且可对对文件签名的工具。</div>
  <div class="content">2.2 除非本注册及服务协议另有其它明示规定，本应用所推出的新产品、新功能、新服务，均受到本注册及注册协议规范。</div>
  <div class="content">2.3 本应用仅提供相关的网络服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由用户自行负担。</div>
  <br>
  <div class="sub-title">3.使用规则</div>
  <div class="content">3.1 您知晓并同意，您并不会将 Dumpapp 所提供的任何一项服务用于违反任何国家相关法律的用途，否则，由此而出现的所有法律后果，将由您自行全部承担；</div>
  <div class="content">3.2 您知晓并同意，您不会添加任何含有违法内容、侵犯版权等的软件源。添加软件源属于个人行为，与本App无关，否则，所产生的任何法律风险和后果将由您自行全部承担；</div>
  <div class="content">3.3 您知晓并同意，您不会签名任何含有违法内容、侵犯版权等的ipa包。签名ipa包属于个人行为，与本App无关，否则，所产生的任何法律风险和后果将由您自行全部承担；</div>
  <div class="content">3.4 您知晓并同意，您不会安装任何含有违法内容、侵犯版权等的ipa包。安装ipa包属于个人行为，与本App无关，否则，所产生的任何法律风险和后果将由您自行全部承担；</div>
  <div class="content">3.5 您知晓并同意，您不会下载任何含有违法内容、侵犯版权等的视频。下载视频属于个人行为，与本App无关，否则，所产生的任何法律风险和后果将由您自行全部承担；</div>
  <div class="content">3.6 您知晓并同意，您没有向 Dumpapp 提交任何的虚假信息或材料，也没有故意隐瞒您的App 中可能存在的违法内容。否则，由此出现的所有后果，将由您自行全部承担；</div>
  <div class="content">3.7 您知晓并同意，由于 Dumpapp 技术原因可能导致app闪退、不能正常使用，您将承担该风险以及该风险导致的后续一切损失；</div>
  <div class="content">3.8 您知晓并同意，您向我们提供的 App（手机应用程序）保证您拥有完整独立的知识产权，包括但不限于外观设计、著作权、文本，图形，徽标，按钮图标，图像，音频剪辑，且不会被用于未经许可的任何有关产品或服务；</div>
  <div class="content">3.9 您知晓并同意，我们在需要时通过技术手段合法收集和使用有关您及您提供的App（手机应用程序）所使用本服务的技术性或诊断性信息，收集到的信息将用于改进我们的服务内容，提升我们的服务品质。我们不会将您的信息和内容分享或出售给其他的组织；</div>

  <br>
  <div class="sub-title">4.责任声明</div>
  <div class="content">4.1 任何网站、单位或者个人如认为本应用或者本应用提供的相关内容涉嫌侵犯其合法权益，应及时向本应用提供书面权力通知，并提供身份证明、权属证明及详细侵权情况证明。本应用在收到上述法律文件后，将会尽快切断相关内容以保证相关网站、单位或者个人的合法权益得到保障。</div>
  <div class="content">4.2 用户明确同意其使用本应用网络服务所存在的风险及一切后果将完全由用户本人承担，本应用对此不承担任何责任。</div>
  <div class="content">4.3 本应用无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。</div>
  <div class="content">4.4 本应用不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本应用实际控制的任何网页上的内容，本应用不承担任何责任。</div>

  <br>
  <div class="sub-title">5.知识产权</div>
  <div class="content">5.1 本应用特有的标识、版面设计、编排方式等版权均属本应用享有，未经本应用许可授权，不得任意复制或转载。</div>
  <div class="content">5.2 用户从本应用的服务中获得的信息，未经本应用的许可，不得任意复制或转载。</div>
  <div class="content">5.3 本应用的所有内容，包括商品描述、图片等内容所有权归属于 Dumpapp 的用户，任何人不得转载。</div>
  <div class="content">5.4 本应用所有用户上传内容仅代表用户自己的立场和观点，与本应用无关，由作者本人承担一切法律责任。</div>
  <div class="content">5.5上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经本应用、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</div>

  <br>
  <div class="sub-title">6.隐私保护</div>
  <div class="content">6.1 本应用不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本社区的非公开内容，但下列情况除外：</div>
  <div class="content">(1)事先获得用户的明确授权；</div>
  <div class="content">(2)根据有关的法律法规要求；</div>
  <div class="content">(3)按照相关政府主管部门的要求；</div>
  <div class="content">(4)为维护社会公众的利益。</div>
  <div class="content">6.2 本应用可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本社区同等的保护用户隐私的责任，则本社区有权将用户的注册资料等信息提供给该第三方，并无须另行告知用户。</div>
  <div class="content">6.3 在不透露单个用户隐私资料的前提下，本应用有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</div>

  <br>
  <div class="sub-title">7.协议修改</div>
  <div class="content">7.1本应用有权随时修改本协议的任何条款，一旦本协议的内容发生变动，本应用将会在本应用上公布修改之后的协议内容，若用户不同意上述修改，则可以选择停止使用本应用。本应用也可选择通过其他适当方式（比如系统通知）向用户通知修改内容。</div>
  <div class="content">7.2如果不同意本应用对本协议相关条款所做的修改，用户有权停止使用本应用。如果用户继续使用本应用，则视为用户接受本应用对本协议相关条款所做的修改。</div>
  
  <br>
  <br>
  <br>
  <br>
  </div>
</div>

</template>

<style>
  .title {
    font-size: 24px;
    font-weight:bold;
  }
  .sub-title{
    font-size: 20px;
    text-align:left;
    font-weight:bold;
  }
  .content{
    font-size: 15px;
    text-align:left;
  }
  .term_margin{
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 35px;
  }
</style>

<script>

export default {
  data () {
    return {
    }
  },
  
  methods: {

  },
  name: 'AdminUpload'
}
</script>

import SparkMD5 from "spark-md5";

export function splitIpaName(text) {
  var sp1 = text.split("#")[0];
  return sp1;
}

export function splitVersion(text) {
  return text.split("#")[1];
}

export function splitBundleId(text) {
  var sp1 = text.split("#")[2];
  sp1 = sp1.substring(0, sp1.length - 4);
  return sp1;
}

export async function calculateFileMD5(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const spark = new SparkMD5.ArrayBuffer();

    fileReader.onload = function () {
      const arrayBuffer = fileReader.result;
      spark.append(arrayBuffer);
      resolve(spark.end());
    };

    fileReader.onerror = function (err) {
      reject(err);
    };

    fileReader.readAsArrayBuffer(file.raw);
  });
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../views/homepage.vue'
import Login from '../views/login.vue'
import Register from '../views/register.vue'
import AdminUpload from '../views/admin-upload.vue'
import Download from '../views/download.vue'


import PayApp from '../views/payApp.vue'
import Pay from '../views/pay.vue'
import PaySelect from '../views/paySelect.vue'
import User from '../views/user.vue'
import SearchAppstore from '../views/searchAppstore.vue'
import AppManager from '../views/appManager.vue'
import SearchRank from '../views/searchRank.vue'
import Cerbuy from '../views/cerBuy.vue'

import Cerbuym1 from '../views/cerBuym1.vue'
import CerPage from '../views/cerPage.vue'
import View_udid from '../views/view_udid.vue'
import Dumplist from '../views/dumplist.vue'

import Historypage from '../views/historypage.vue'

import Crackpage from '../views/crackpage.vue'
import Addaccount from '../views/addaccount.vue'

import SelectCer from '../views/selectCer.vue'
import SignIpaList from '../views/signIpaList.vue'
import InstallIpa from '../views/installIpa.vue'
import Downloadhistory from '../views/downloadhistory.vue'
import Coinuserpage from '../views/coinuserpage.vue'

import Term from '../views/term.vue'
import InstallBuy from '../views/installBuy.vue'
import App from '../views/app.vue'
import Find from '../views/find.vue'
import UserOrder from '../views/userOrder.vue'
import NewEmail from '../views/newEmail.vue'
import UserRecord from '../views/userRecord.vue'
import SearchCode from '../views/searchCode.vue'
import BuyBook from '../views/buyBook.vue'

import adminAppVersion from '../views/admin/app_version.vue'
import app from "@/views/app.vue";

import appPublish from '@/views/app_publish/app_publish.vue'
import publishlist from '@/views/app_publish/publishlist.vue'
import wxThemePage from '../views/wxtheme/wxThemePage.vue'
import WXThemeListPage from '../views/wxtheme/wxThemeList.vue'
import wxcodeListPage from '../views/wxtheme/wxcodeList.vue'
import wxUseCdkey from '../views/wxtheme/wxUseCdkey.vue'

import cerPrivate from '@/views/cerPrivate.vue'
import getUdid from '@/views/getudid.vue'
import udidSearch from '@/views/udidSearch.vue'
import activity from '@/views/activity.vue'
import activityList from '@/views/activityList.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/admin_upload',
    name: 'AdminUpload',
    component: AdminUpload
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay
  },  
  {
    path: '/payselect',
    name: 'PaySelect',
    component: PaySelect
  },
  {
    path: '/searchAppstore',
    name: 'SearchAppstore',
    component: SearchAppstore
  },{
    path: '/appManager',
    name: 'AppManager',
    component: AppManager
  },{
    path: '/searchRank',
    name: 'SearchRank',
    component: SearchRank
  },{
    path: '/cerbuy',
    name: 'Cerbuy',
    component: Cerbuy
  },{
    path: '/cerbuym1',
    name: 'Cerbuym1',
    component: Cerbuym1
  },{
    path: '/cerPage',
    name: 'CerPage',
    component: CerPage
  },{
    path: '/view_udid',
    name: 'View_udid',
    component: View_udid
  },{
    path: '/dumplist',
    name: 'Dumplist',
    component: Dumplist
  }, {
    path: '/historypage',
    name: 'Historypage',
    component: Historypage
  }, {
    path: '/crackpage',
    name: 'Crackpage',
    component: Crackpage
  },{
    path: '/addaccount',
    name: 'Addaccount',
    component: Addaccount
  },{
    path: '/selectCer',
    name: 'SelectCer',
    component: SelectCer
  },{
    path: '/signipalist',
    name: 'SignIpaList',
    component: SignIpaList
  },{
    path: '/installIpa',
    name: 'InstallIpa',
    component: InstallIpa
  },{
    path: '/downloadhistory',
    name: 'Downloadhistory',
    component: Downloadhistory
  },{
    path: '/coinuserpage',
    name: 'Coinuserpage',
    component: Coinuserpage
  },{
    path: '/term',
    name: 'Term',
    component: Term
  },
  {
    path: '/payApp',
    name: 'PayApp',
    component: PayApp
  },
  {
    path: '/installBuy',
    name: 'InstallBuy',
    component: InstallBuy
  },
  {
    path: '/app',
    name: 'App',
    component: App
  },
  // {
  //   path: '/fuli',
  //   name: 'Fuli',
  //   component: Fuli
  // },
  {
    path: '/find',
    name: 'Find',
    component: Find
  },{
    path: '/userOrder',
    name: 'UserOrder',
    component: UserOrder
  },{
    path: '/newEmail',
    name: 'NewEmail',
    component: NewEmail
  },{
    path: '/userRecord',
    name: 'UserRecord',
    component: UserRecord
  },{
    path: '/searchCode',
    name: 'SearchCode',
    component: SearchCode
  },{
    path: '/buyBook',
    name: 'BuyBook',
    component: BuyBook
  },

  //todo: 新版本路由都按照以下方式来写
  {
    path: '/admin/app_version',
    name: 'AdminAppVersion',
    component: adminAppVersion
  },

  // app 分发相关
  {
    path: '/app/publish',
    name: 'AppPublish',
    component: appPublish
  },
  {
    path: '/app/publishlist',
    name: 'Publishlist',
    component: publishlist
  },
  {
    path: '/cerPrivate',
    name: 'CerPrivate',
    component: cerPrivate
  },
  {
    path: '/getudid',
    name: 'getUdid',
    component: getUdid
  },
  {
    path: '/udidSearch',
    name: 'udidSearch',
    component: udidSearch
  },
  //主题相关页面
  {
    path: '/theme/upload',
    name: 'wxThemePage',
    component: wxThemePage
  },
  {
    path: '/theme/cdkey',
    name: 'wxcodeList',
    component: wxcodeListPage
  },
  {
    path: '/theme/list',
    name: 'wxThemeList',
    component: WXThemeListPage
  },
  {
    path: '/theme/cdkey/expense',
    name: 'wx_cdkey',
    component: wxUseCdkey
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  },
  {
    path: '/activityList',
    name: 'activityList',
    component: activityList
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>

<div class="background">
  <div class="flex algin">
    <div id="app-title" class="app-title">历史版本页面
    </div>
       <el-button id="app_back_btn" @click="onBack" class="app_back_btn" >返回首页</el-button>
  </div>
<div class="table_content_result">
<el-table
    :data="tableData"
    style="width: 100%"
    max-height="350">
    <el-table-column
      fixed
      prop="name"
      label="APP名称"
      align="center">
    </el-table-column>
    <el-table-column
     prop="version"
      label="版本号"
      align="center">
    </el-table-column>
    <el-table-column
      align="center"
      prop="url"
       label="获取IPA">
      <template slot-scope="scope">
       <el-button @click="invoicesLink(scope.row.version, scope.row.ipaID)" type="success" icon="el-icon-download" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</div>

</template>
<style>
  .container{
    /* display: flex; */
    flex-direction: row;
    padding: 5px;
  }

  .login-button{
    height: 75px;
    line-height: 75px;
  }

  .table_content_down{
    top: 20px;
    width:780px;
    position: relative;
  }

</style>

<script>
import {searchVersionWithAppid} from '@/api/download'
import {checkCanDownload} from '@/api/download' 
import {searchIpa} from '@/api/download'
import {searchWithBundleID} from '@/api/apple'
import {searchWithAppid} from '@/api/apple'
import {getSelf} from '@/api/member'
import {downloadIPA} from '@/api/download'
import {
  postEmail,
  postApp,
  postDumpOrder
} from '@/api/email'
import { version } from 'element-ui'

export default {
  data() {
      return {
        tableData: null,
        reportBtn:'none',
        searchName:'',
        downloadCount:0,
        emailStr : "",
        contryCode:""
      }
    },

  methods: {
    clickqq () {
      alert('请加 qq 群: 763789550')
    },

    async onBack() {
        this.$router.push({
          path: `/`,
        })
    },
     open() {
      const h = this.$createElement;
       var tThis = this;

        this.$msgbox({
          title: '提示',
          message: h('p', null, [
            h('i', { style: 'color: red' }, '获取旧 IPA 版本'), 
            h('span', null, '，属于 VIP '),
            h('span', null, '功能，升级 '),
            h('i', { style: 'color: red' }, 'VIP用户 '),
            h('span', null, '获取更多特权!')
          ]),
          showCancelButton: true,
          confirmButtonText: '了解 VIP 特权',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done();
              tThis.routerPay();
            } else {
              done();
            }
          }
        }).then(action => {
          this.$message({
            type: 'info',
            message: 'action: ' + action
          });
        });
      },

     invoicesLink(version, ipaID, ) {  
          this.urlClick(version, ipaID);
      },

   async urlClick(version, ipaID){
      var data = await checkCanDownload(ipaID, version);
      if (data){
        if (data["can_download"] == false){
            var userData = await getSelf();
            var downloadCount = userData.pay_count;
            if (downloadCount < 1){
              this.openPayMsg("当前拥有可D币个数为 0，请进行充值。"); 
            }else{
              this.postEmailFile(["dumpapp@126.com"], this.emailStr, version);
              this.openMsg('您的请求已提交，我们稍后会生成IPA，然后通过邮箱通知您! （1-3小时还没有收到 IPA 包的，可添加网站微信联系客服咨询进度）(节假日服务器维护，ipa 出包比较慢)');
            }
        }else{
        }
      }
    },

     postEmailFile(emails, address, version){
       var store_link =  "https://apps.apple.com/" + this.contryCode +"/app/" + encodeURIComponent(this.searchName) + "/id" + this.getQueryString('appid').toString();

      var title = 'DumpApp - IPA 旧版本申请'
      var data = {
        emails: emails,
        title: title,
        content:  "国家: " + this.getCountryStr(this.contryCode)  + "<br>" + "应用名称: " + this.searchName + "<br>" + "应用版本: " + version + "<br>" + " bundleID: " + this.bundle_id + "<br>" + "D币个数: " + this.downloadCount + "<br>" + "邮箱地址: " + address  + "<br>" + "App Store: " + store_link 
      }

      // postEmail(data);

      var data2 = {
        name: this.searchName,
        bundle_id:this.bundle_id,
        version:version,
        app_store_link: store_link,
        ipa_id: this.getQueryString('appid'),
        is_old:true,
      }

      postDumpOrder(data2);
    },

    routerPay(){
      this.$router.push({
            path: `/pay`,
      })
    },

      openMsg(data) {
          alert(data);
      },

      openPayMsg( vip_str) {
        var confirmStr = "确认";
         confirmStr =  '进行充值';
         this.$confirm( '<strong>'  + '<font color="#FF0000">' + vip_str + '</font> ' + '</strong>' , '提示', {
          confirmButtonText: confirmStr,
          cancelButtonText: '取消',
          dangerouslyUseHTMLString : true
        }).then(() => {
         this.routerPay(); 
        }).catch(() => {        
        });
      },

    // 搜索 apup
   async requestSearchApp (appid, contry, name, versions) {
    // return;
      var userData = await getSelf();
      this.downloadCount = userData.pay_count;
      this.emailStr = userData.email;
      this.searchName = name;
      
     document.getElementById("app-title").innerHTML = this.searchName + "-历史版本界面";
      this.contryCode = contry;
      var appstoreData = await searchWithAppid(appid, contry);
      this.bundle_id = appstoreData.results[0].bundleId;
      var searchData = await searchVersionWithAppid(contry, appid);
      if (!searchData){
        return;
      }
      if (!searchData.length){
        return;
      }

      var filArr = searchData;

      var versionData = versions.split(',');
      // 重组用于显示的数据
        var newData = new Array();
        var tempData1 = filArr;

          for (var j=0; j<tempData1.length; j++){
            var objc = tempData1[j];
            var newDic = {};
            newDic["ipaID"] = objc.trackId;
            newDic["name"] =  name;
            newDic["version"] = objc.versionName;
            let result = versionData.indexOf(objc.versionName);//3
            if (result <= -1) {
              newData.push(newDic);
            }
          }

        this.tableData = newData;
    },

     getCountryStr(codeStr){
        if (codeStr == "cn"){
          return "中国区";
        }
        if (codeStr == "us"){
          return "美国区"; 
        }
        if (codeStr == "hk"){
          return "香港区"; 
        }
        if (codeStr == "jp"){
          return "日本区"; 
        }
        if (codeStr == "kr"){
          return "韩国区"; 
        }
        return "中国区";
    },
  
    
 compareVersion(v1, v2) {
  v1 = v1.split('.')
  v2 = v2.split('.')
  const len = Math.max(v1.length, v2.length)

  while (v1.length < len) {
    v1.push('0')
  }

  while (v2.length < len) {
    v2.push('0')
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i])
    const num2 = parseInt(v2[i])

    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }

  return 0
},

    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null)return unescape(r[2]);
      return null;
    },
    async getSearch(requestData) {
      // 拿到请求数据 json
      var data = await searchIpa(requestData);
      return data;
     },
  },

  mounted() {
    var appid = this.getQueryString('appid');
    var name =  this.getQueryString('name');
    var versions = this.getQueryString('versions');

    var contry = this.getQueryString('contry');
    
    this.requestSearchApp(appid,contry, name,versions);

  },
}
</script>
